<form [formGroup]="formAgendamento" (submit)="onSubmit()">
  <breadcrumb
  [paginas]="[{route:'/home',name:'Home'},{route:'/filtro-visitas-psicologo',name:'Registro da Atividade do Psicólogo'},{route:'visita-psicologo-prevencao',name:'Visita de Prevenção Psicólogo'}]"
/>
  <section class="section-filtro">
    <div class="container">
      <div class="grupo-filter">
        <label for="select-categoria">Polo</label>
        <ng-select
          [items]="polos"
          bindLabel="descricaoPolo"
          bindValue="codigoPolo"
          placeholder="Selecione um Polo"
          name="codigoPolo"
          formControlName="codigoPolo"
          (change)="cambioPolo()"
          [ngClass]="{
            'is-invalid': submitted && form.codigoPolo.errors
          }"
          (change)="cambioPolo()"
        >
          <ng-template ng-optgroup-tmp let-item="item">
            {{ item.descricaoPolo || "Unnamed group" }}
          </ng-template>
        </ng-select>
        <div *ngIf="submitted && form.codigoPolo.errors" class="alert">
          <div *ngIf="form.codigoPolo.errors.required">
            * Polo é obrigatório
          </div>
        </div>
      </div>

      <div class="grupo-filter">
        <p><b>Diretoria</b></p>
        <ng-select
          [items]="diretorias"
          bindLabel="nomeDiretoria"
          bindValue="codigoDiretoria"
          placeholder="Selecione uma Diretoria"
          formControlName="codigoDiretoria"
          name="codigoDiretoria"
          (change)="trocaDiretoria()"
          [ngClass]="{ 'is-invalid': submitted && form.codigoDiretoria.errors }"
        >
        </ng-select>
        <div *ngIf="submitted && form.codigoDiretoria.errors" class="alert">
          <div *ngIf="form.codigoDiretoria.errors.required">
            * Diretoria é obrigatório
          </div>
        </div>
      </div>

      <div class="grupo-filter">
        <p><b>Escola</b></p>
        <ng-select
          [items]="escolas"
          bindLabel="nomeEscola"
          bindValue="codigoEscola"
          placeholder="Selecione uma Escola"
          formControlName="codigoEscola"
          name="codigoEscola"
          [ngClass]="{ 'is-invalid': submitted && form.codigoEscola.errors }"
        >
        </ng-select>
        <div *ngIf="submitted && form.codigoEscola.errors" class="alert">
          <div *ngIf="form.codigoEscola.errors.required">
            * Escola é obrigatório
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- TITULO -->
  <section class="section-saudacao">
    <div class="container">
      <h2 class="titulo-page">Agendamento de Visita Prevenção Psicologo</h2>
    </div>
  </section>

    <!-- Form -->
    <section class="container-conviva form-registro">
      <div class="container form-vista-psicologo info-psicologo">
        <div class="conteudo-select">
          <label for="select-gravidade">Psicólogo: </label>
          <ng-select
            [items]="lstPsicologos"
            bindLabel="nomePsicologo"
            bindValue="codigoPsicologo"
            placeholder="Selecione um Psicólogo"
            name="nomePsicologo"
            formControlName="codigoPsicologo"
            [ngClass]="{
              'is-invalid': submitted && form.codigoPsicologo.errors
            }"
            >
            <!-- (change)="setPsicologo()" -->
          </ng-select>
          <div *ngIf="submitted && form.codigoPsicologo.errors" class="alert">
            <div *ngIf="form.codigoPsicologo.errors.required">
              * Psicólogo obrigatório
            </div>
          </div>
        </div>



        <div class="conteudo-range-slider">
          <label>Quanto tempo durou a ação?</label>
          <input-range (change)="changeInputRange()"/>
        </div>

        <div class="grupo-filter-full">
          <label for="select-data-visita">Selecionar Data da Visita: </label>
            <!-- <input type="date" name="dataVisita" class="input-date" formControlName="dataVisita" />
            <div *ngIf="submitted && form.dataVisita.errors" class="alert">
              <div *ngIf="form.dataVisita.errors.required">* Data obrigatória</div>
            </div> -->
            <modal-calendario (valueChange)="OnChangeModalData($event)"
             [duracaoVisita]="this.duracaoVisita"
             [codigoEscola]="this.form.codigoEscola.value"
             [codigoDiretoria]="this.form.codigoDiretoria.value"
             ></modal-calendario>
        </div>

        <!-- <div class="conteudo-select">
          <label for="select-gravidade">Qual atividade foi realizada?</label>
          <ng-select
          [items]="lstAtividadeRealizada"
          bindLabel="name"
          bindValue="id"
          placeholder="Selecione o tipo de atividade"
          name="codigoAtividade"
          formControlName="codigoAtividade"
          (change)="mostrarEnvolvidos()"
          [ngClass]="{
            'is-invalid': submitted && form.codigoAtividade.errors
          }"
          >
          </ng-select>
          <div *ngIf="submitted && form.codigoAtividade.errors" class="alert">
            <div *ngIf="form.codigoAtividade.errors.required">
              * Atividade obrigatória
            </div>
          </div>
        </div> -->

        <!-- <div class="conteudo-envolvidos" *ngIf="mostrarAlunos">
          <h3>Estudantes</h3>
          <div class="content-person">
            <a href="javascript:void(0)" class="btn-add-person btn-gg" (click)="AbreModalAluno()">
              <img src="../../../../assets/img/icons/icon-add.svg" alt="">
            </a>
          </div>
          @for (aluno of alunosSelecionados; track $index;) {
            <div class="grupo-filter-full">
              <label for="select-gravidade">
                Aluno(a)
                <a href="javascript:void(0)" class="btn-close-convidados"
                  (click)="removeAluno(aluno.codigoAluno)"></a>
              </label>
              <input type="text" id="func-{{$index}}" name="funcionario" readonly
              [value]="aluno.nomeAluno" />
            </div>
            }@empty {
            Nenhum aluno adicionado.
            }
        </div>

        <div class="conteudo-envolvidos" *ngIf="mostrarProffisionais">
          <h3>Equipe Escolar</h3>

          <label for="select-gravidade">
            Equipe Escolar
          </label>

          @for (func of lstFuncionariosSelecionados; track $index;) {
            <div class="grupo-filter-full">
              <label for="select-gravidade">
                Profissional
                <a href="javascript:void(0)" class="btn-close-convidados"
                  (click)="removeFuncionario(func.codigoFuncionario)"
                  ></a>
              </label>
              <input type="text" id="func-{{$index}}" name="funcionario" readonly [value]="func.nome" />
            </div>
            }@empty {
            Nenhum profissional adicionado.
            }

            @for (outro of lstOutros; track $index;) {
            <div class="grupo-filter-full">
              <label for="select-gravidade">
                Outro
                <a href="javascript:void(0)" class="btn-close-convidados" (click)="removeOutro(outro)"></a>
              </label>
              <input type="text" id="func-{{$index}}" name="outro" readonly [value]="outro" />
            </div>
            }

            <div class="content-person">
              <a href="javascript:void(0)" class="btn-add-person btn-gg"
              (click)="AbreModalProfissionais()">
                <img src="../../../../assets/img/icons/icon-add.svg" alt="">
              </a>
            </div>
        </div> -->

        <div class="conteudo-envolvidos" *ngIf="mostrarGrupos">
          <label for="select-gravidade">Atividade em grupo:</label>
          <ng-select
            [items]="lstGrupos"
            bindLabel="name"
            bindValue="id"
            placeholder="Selecione o tipo de grupos"
            name="tipoGrupo"
            formControlName="tipoGrupo"
            (change)="onChangeTipoGrupo()"
            [ngClass]="{
              'is-invalid': submitted && form.tipoGrupo.errors
            }"
          >
          </ng-select>

          <div class="conteudo-envolvidos" *ngIf="mostrarDivTurmas">
            <section class="conteudo-principal">
              <div class="container">
                <div class="conteudo-perfil">
                <br/>
                  <label for="select-gravidade">Turmas</label>
                  <app-tabulator-table
                  [tableData]="lstTurmas"
                  [columnNames]="tabulatorColumns"
                  [tableTitle]="'teste'"
                  [tipoRelatorio]="1"
                  [orientacaoPortrait]="false"
                  ></app-tabulator-table>
                </div>
              </div>
            </section>
          </div>
        </div>

        <div class="conteudo-envolvidos" *ngIf="mostrarDivEquipeEscolar">
          <h3>Envolvidos</h3>

          <label for="select-gravidade">
            Equipe Escolar
          </label>
          <div class="grupo-filter-full" *ngFor="let envolvido of lstFuncionariosSelecionados; let i = index">
            <label for="select-gravidade">
              <a
                href="javascript:void(0)"
                class="btn-close-convidados"
                (click)="OnExcluirEquipeEscolar(envolvido)">
              </a>
            </label>
            <input [readOnly]="true"
              type="text"
              id="envolvido-{{i}}"
              name="tituloVisita"
              [value]="envolvido.nome"
            />
          </div>

          <div class="content-person">
            <a
              href="javascript:void(0)"
              class="btn-add-person btn-gg"
              (click)="AbrirModalEquipeEscolar()"
            >
              <img src="../../../../assets/img/icons/icon-add.svg" alt="">
            </a>
          </div>
        </div>

        <div class="conteudo-envolvidos" *ngIf="mostrarDivOutros">
          <h3>Envolvidos</h3>
          <label for="select-gravidade">
            Outros
          </label>
          <div class="grupo-filter-full" *ngFor="let outro of lstOutros; let i = index">
            <label for="select-gravidade">
              <a
                href="javascript:void(0)"
                class="btn-close-convidados"
                (click)="OnExcluirOutro(outro)"
                >
              </a>
            </label>
            <input [readOnly]="true"
              type="text"
              id="envolvido-{{i}}"
              name="text-titulo"
              [value]="outro"

            />
          </div>
          <div class="content-person">
            <a
              href="javascript:void(0)"
              class="btn-add-person btn-gg"
              (click)="AbrirModalOutros()"
              >
              <img src="../../../../assets/img/icons/icon-add.svg" alt="">
            </a>
          </div>
        </div>

        <div class="container-btns-visitas">
          <input
            type="reset"
            class="btn_outline"
            id="btnLimpar"
            name="b2"
            (click)="Voltar()"
            value="Voltar"
          />
          <input
            type="submit"
            class="btn_primary"
            value="Salvar"
          />
        </div>
      </div>

    </section>
  </form>

  <app-modal *ngIf="showModalEquipeEscolar">
    <form [formGroup]="formModalEquipeEscolar" (submit)="OnAdicionarEquipeEscolar()">
      <div class="container-conviva form-registro modal-envolvidos">
        <div>
          <h1 class="titulo-page">Dados do Equipe Escolar</h1>
        </div>
        <div class="time-line conteudo-registro modal-conteudo-busca-aluno">
          <div *ngIf="mostrarDivEquipeEscolar">
            <div class="modal-conteudo-busca-aluno">
              <div class="conteudo-search">
                <input
                  type="text"
                  placeholder="Digite o CPF"
                  id="js-input-search"
                  formControlName="cpfFuncionario"
                  name="cpfFuncionario"
                  mask="000.000.000-00"
                  [validation]="true"
                />
                <button type="button" id="btnBuscarFuncionario" (click)="GetFuncionariosPorCpf(1)">
                  <img src="../../../assets/img/icons/icon-search.svg" alt="" />
                </button>
              </div>
              <div
                *ngIf="submittedModal && formEquipeEscolar.cpfFuncionario.errors"
                class="alert"
              >
                <div *ngIf="formEquipeEscolar.cpfFuncionario.errors.required">
                  * CPF do funcionario é obrigatório.
                </div>
                <div
                  *ngIf="
                  formEquipeEscolar.cpfFuncionario.errors &&
                    this.formEquipeEscolar.cpfFuncionario.errors.message
                  ">
                  {{ this.formEquipeEscolar.cpfFuncionario.errors.message }}
                </div>
              </div>
            </div>
            <div *ngIf="this.lstFuncionarios.length > 0">
              <div class="grupo-filter">
                <p><b>Funcionario</b></p>
                <ng-select
                  [items]="lstFuncionarios"
                  bindLabel="nome"
                  bindValue="cpf"
                  placeholder="Selecione o funcionario"
                  formControlName="cpfFuncionarioModalDrop"
                  name="cpfFuncionarioModalDrop"
                >
                </ng-select>
                <div
              *ngIf="submittedModal && formEquipeEscolar.cpfFuncionarioModalDrop.errors"
              class="alert"
            >
              <div *ngIf="formEquipeEscolar.cpfFuncionarioModalDrop.errors.required">
                * Nenhum funcionario foi selecionado.
              </div>
              </div>
            </div>
            </div>
          </div>

          <div class="container-btns">
            <input
              type="button"
              (click)="FecharModalEquipeEscolar()"
              class="btn_outline"
              value="Fechar"
            />
            <input type="submit" class="btn_primary" value="Salvar" />
          </div>
        </div>
      </div>
    </form>
  </app-modal>

  <app-modal *ngIf="showModalOutros">
    <form [formGroup]="formModalOutros" (submit)="OnAdicionarOutros()">
      <div class="container-conviva form-registro modal-envolvidos">
        <div>
          <h1 class="titulo-page">Dados do Outros</h1>
        </div>
        <div class="time-line conteudo-registro modal-conteudo-busca-aluno">
          <div *ngIf="mostrarDivOutros" class="conteudo-textarea">
            <label for="text-registro">Descreva quem são os outros</label>
            <textarea
              id="text-registro"
              name="text-registro"
              rows="6"
              cols="50"
              formControlName="dsOutros"
              name="dsOutros"
            ></textarea>
            <div
              *ngIf="submittedModal && formOutros.dsOutros.errors"
              class="alert"
            >
              <div *ngIf="formOutros.dsOutros.errors.required">
                * Descricao de outros é obrigatório.
              </div>
            </div>
          </div>

          <div class="container-btns">
            <input
              type="button"
              (click)="FecharModalOutros()"
              class="btn_outline"
              value="Fechar"
            />
            <input type="submit" class="btn_primary" value="Salvar" />
          </div>
        </div>
      </div>
    </form>
  </app-modal>

  <!-- <app-modal *ngIf="showModalExcluirEnvolvido">
    <div class="container-conviva form-registro modal-envolvidos">
      <div>
        <h1 class="titulo-page">Envolvidos</h1>
      </div>
      <div class="descricao-modal-excluir">
        <span>{{this.descricaoExclusao}}</span>
      </div>
      <div class="container-btns">
        <input type="button" (click)="FecharModalExclusao()" class="btn_outline" value="Não"/>
        <input type="button" (click)="ExluirEnvolvido(this.envolvidoExclusao?.idTemporario)" class="btn_primary" value="Sim" />
      </div>
    </div>
  </app-modal> -->

  <app-modal *ngIf="showModal">
    <form [formGroup]="formModal" (submit)="OnAdicionarProfissionaisEOutros()">
      <div class="container-conviva form-registro modal-envolvidos">
        <div>
          <h1 class="titulo-page">Adicionar Pessoas</h1>
        </div>

        <div class="time-line conteudo-registro modal-conteudo-busca-aluno">

          <div>
            <div class="modal-conteudo-busca-aluno">

              <div>
                <div class="grupo-filter">
                  <p><b>Tipo Pessoa</b></p>
                  <ng-select [items]="tipoPessoaAdd" bindLabel="descricao" bindValue="id" formControlName="tipoPessoa"
                    (change)="changeTipoPessoaModal()" name="tipoPessoa" placeholder="Selecione um tipo de pessoa">
                  </ng-select>

                </div>
              </div>
              @if(this.formModalControls.tipoPessoa.value == 2){
              <div class="conteudo-search">
                <input type="text" placeholder="Digite o CPF" id="js-input-search" formControlName="cpfBusca"
                  name="cpfBusca" mask="000.000.000-00" [validation]="true" />
                <button type="button" id="btnBuscarFuncionario" (click)="GetFuncionariosPorCpf(2)">
                  <img src="../../../assets/img/icons/icon-search.svg" alt="" />
                </button>
              </div>
              }
            </div>
            <div *ngIf="this.formModalControls.tipoPessoa.value == 2 &&
                        this.formModalControls.cpfBusca.value &&
                        this.lstFuncionarios.length > 0">
              <div class="grupo-filter">
                <p><b>Funcionario</b></p>
                <ng-select [items]="lstFuncionarios" bindLabel="nome" bindValue="cpf"
                  placeholder="Selecione o funcionario" formControlName="cpfProfissionalSelecionado"
                  name="cpfProfissionalSelecionado">
                </ng-select>
                <div *ngIf="submittedModal && formModalControls.cpfProfissionalSelecionado.errors" class="alert">
                  <div *ngIf="formModalControls.cpfProfissionalSelecionado.errors.required">
                    * Nenhum funcionario foi selecionado.
                  </div>
                </div>
              </div>
            </div>

            @if(this.formModalControls.tipoPessoa.value == 1){
            <div class="grupo-filter-full">
              <label for="dsAvaliacao">Descreva quem são os outros</label>
              <textarea id="dsOutros" name="dsOutros" rows="6" cols="40" formControlName="dsOutros"
                name="dsOutros"></textarea>

            </div>
            }
          </div>

          <div class="container-btns">
            <input type="button" (click)="FecharModal()" class="btn_outline" value="Fechar" />
            <input type="submit" class="btn_primary" value="Adicionar" />
          </div>
        </div>
      </div>
    </form>
  </app-modal>



  <!-- <app-modal *ngIf="showModalAluno">
    <form [formGroup]="formModalAluno" (submit)="OnAdicionarEnvolvido()">
      <div class="container-conviva form-registro modal-envolvidos">
        <div>
          <h1 class="titulo-page">Busca Alunos</h1>
        </div>

        <div class="time-line conteudo-registro modal-conteudo-busca-aluno">
          <div >
            <div class="modal-conteudo-busca-aluno">
              <div class="grupo-filter-modal">
                <p><b>Diretoria</b></p>
                <ng-select
                  [items]="diretorias"
                  bindLabel="nomeDiretoria"
                  bindValue="codigoDiretoria"
                  placeholder="Selecione uma Diretoria"
                  formControlName="codigoDiretoriaModal"
                  name="codigoDiretoriaModal"
                  (change)="getEscolasModalEnvolvidos()"
                >
                </ng-select>
              </div>

              <div class="grupo-filter-modal">
                <p><b>Escola</b></p>
                <ng-select
                  [items]="escolasModalAlunos"
                  bindLabel="nomeEscola"
                  bindValue="codigoEscola"
                  placeholder="Selecione uma Escola"
                  formControlName="codigoEscolaModal"
                  name="codigoEscolaModal"
                >
                </ng-select>
              </div>

              <div class="conteudo-search">
                <input
                  type="text"
                  placeholder="Buscar por nome ou RA"
                  id="js-input-search"
                  formControlName="raNomeAluno"
                  name="raNomeAluno"
                />
                <button
                  type="button"
                  id="btnPesquisaAluno"
                  (click)="BuscarAlunoPorNomeRA()"
                >
                  <img src="../../../assets/img/icons/icon-search.svg" alt="" />
                </button>
              </div>
              @if(showDropAuxiliar){
                <div >
                  <div class="grupo-filter-modal">
                    <p><b>Estudantes</b></p>
                    <ng-select
                      [items]="alunos"
                      bindLabel="nomeAluno"
                      bindValue="ra"
                      placeholder="Selecione o Aluno"
                      formControlName="raAlunoModalDrop"
                      name="raAlunoModalDrop"
                    >
                    </ng-select>
                  </div>
                </div>
              }
            </div>
          </div>
          <div class="container-btns">
            <input
              type="button"
              (click)="FecharModalAluno()"
              class="btn_outline"
              value="Fechar"
            />
            <input type="submit" class="btn_primary" value="Salvar" />
          </div>
        </div>
      </div>
    </form>

  </app-modal> -->

  <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Carregando ...</p>
</ngx-spinner>
