export class ListarChamados {
  codigoDiretoria:number;
  nomeDiretoria: string;
  codigoEscola:number;
  nomeEscola: string;
  codigoTurno?:number;
  nomeTurno: string;
  codigoTurma?:number;
  descricaoTurma?:string;
  codigoCategoria:number;
  nomeCategoria: string;
  codigoSubCategoria?:number;
  nomeSubCategoria: string;
  codigoGravidade?: number;
  nomeGravidade: string;
  codigoChamado:number;
  codigoEnvolvido:number;
  status:number;
  dsStatus:string;
  descricaoChamado: string;
  dataChamado: Date;
  dataInclusao: Date;
  horasAtras: string;
  imagemChamado: string[] = [];
  tipoEnvolvido:number;
  dsEnvolvido: string;
  tipoPessoa: number;
  dsPessoa: string;
  descricaoOutros?:string;
  iniciaisNome?:string;
  foto?: string;
  dsGravidade?: string;
  temFoto: boolean;
  equipeEscolar?: FuncionarioEnvolvido;
  aluno?: AlunoEnvolvido;
  ordenacaoCrescente: boolean;
  filtroStatusEnvolvido?: number;
  dataInicio?: Date;
  dataFim?: Date;
  getFoto?: boolean;
  dsTipoAcao?:string;
  dsTipoNotificacao?:string;
  codigoPerfil?:number;
  numeroBOSSP?: number;
  semEnvolvido:boolean;
  tpPessoa: number[] = [];
  anoAtual?:number;
}

export class AlunoEnvolvido {
  codigoAluno:number;
  nomeAluno?: string;
  ra?: string;
  digitoRa?: string;
  ufRa?: string;
  dsStatusEnvolvido?: string;
  descricaoTurma?: string;
  statusEnvolvido: number;
}

export class FuncionarioEnvolvido {
  idUser:number;
  nome?: string;
  cpf?: string;
  email?: string;
}
