import { Component, Input } from '@angular/core';
import { Perfil } from 'src/app/shared/enums/Perfis/Perfis';
import { ListarChamados } from 'src/app/shared/models/chamados/listar-chamados';

@Component({
  selector: 'app-modal-notificacoes-alert',
  templateUrl: './modal-notificacoes-alert.component.html',
  styleUrls: ['./modal-notificacoes-alert.component.scss']
})
export class ModalNotificacoesAlertComponent {
  @Input() chamadosAtrasados: ListarChamados[] = [];
  @Input() showModalNotificacao = false;
  @Input() perfil: number = 0;

  private perfisQuePodemVisualizarChamadosPendentes: Perfil[] = [Perfil.GestorConviva,
    Perfil.PEC,Perfil.POC,
    Perfil.DiretorEscolar,Perfil.ViceDiretor,Perfil.Dirigente,
    Perfil.SupervisorEducacional];

  private perfisQuePodemVisualizarChamadosComAgendamentosAtrasados: Perfil[] = [Perfil.GestorConviva,Perfil.PEC,
    Perfil.Dirigente,Perfil.Psicólogo,Perfil.CoordenadorPsicologo,Perfil.FiscalPsicologo];

  private perfisQuePodemVisualizarChamadosAguardandoAtendimento: Perfil[] = [Perfil.GestorConviva,Perfil.PEC,
      Perfil.POC,Perfil.DiretorEscolar,Perfil.ViceDiretor,Perfil.Dirigente,Perfil.Psicólogo,
      Perfil.CoordenadorPsicologo,Perfil.FiscalPsicologo];

  private perfisQuePodemVisualizarChamadosAguardandoEncerramento: Perfil[] = [Perfil.GestorConviva,Perfil.PEC,Perfil.POC,
    Perfil.DiretorEscolar,Perfil.ViceDiretor,Perfil.Dirigente,Perfil.SupervisorEducacional,
    Perfil.FiscalPsicologo ];

  public perfisQuePodemVerOModal:Perfil[] = [...this.perfisQuePodemVisualizarChamadosPendentes,
    ...this.perfisQuePodemVisualizarChamadosComAgendamentosAtrasados,
    ...this.perfisQuePodemVisualizarChamadosAguardandoAtendimento,
    ...this.perfisQuePodemVisualizarChamadosAguardandoEncerramento];



  isShaking = false;
  totalChamadosAtrasados = 0;


  public get podeVisualizarCountChamadosPendentes(): boolean {
    return this.countChamadoPorStatus('Pendente análise do chamado') > 0 &&
     this.validPermissionToView(this.perfisQuePodemVisualizarChamadosPendentes);
  }

  public get podeVisualizarCountChamadosAtrasadoAgendamento(): boolean {
     return this.countChamadoPorStatus('Atrasado Agendamento do Psicólogo') > 0 &&
      this.validPermissionToView(this.perfisQuePodemVisualizarChamadosComAgendamentosAtrasados);
  }

  public get podeVisualizarCountChamadosAtrasadoAtendimento(): boolean {
    return this.countChamadoPorStatus('Aguardando Atendimento do Psicólogo') > 0 &&
    this.validPermissionToView(this.perfisQuePodemVisualizarChamadosAguardandoAtendimento);
  }

  public get podeVisualizarCountChamadosAguardandoEncerramento(): boolean {
    return this.countChamadoPorStatus('Aguardando Encerramento') > 0 &&
    this.validPermissionToView(this.perfisQuePodemVisualizarChamadosAguardandoEncerramento);
  }


  public GetTotalChamadosAtrasados(){
    let totalDeChamados = 0;
    if(this.podeVisualizarCountChamadosPendentes){
      totalDeChamados += this.countChamadoPorStatus('Pendente análise do chamado');
    }


    if(this.podeVisualizarCountChamadosAtrasadoAgendamento){
      totalDeChamados += this.countChamadoPorStatus('Atrasado Agendamento do Psicólogo');
    }
    if(this.podeVisualizarCountChamadosAtrasadoAtendimento){
      totalDeChamados += this.countChamadoPorStatus('Aguardando Atendimento do Psicólogo');
    }
    if(this.podeVisualizarCountChamadosAguardandoEncerramento){
      totalDeChamados += this.countChamadoPorStatus('Aguardando Encerramento');
    }


    return totalDeChamados;


  }




  countChamadoPorStatus(sla: string) {
    return this.chamadosAtrasados.filter(x => x.aluno?.dsStatusEnvolvido == sla).length;
  }




  public triggerShake() {
    this.isShaking = true;
    // Remove a classe após a duração da animação para reutilização
    setTimeout(() => {
      this.isShaking = false;
    }, 1500);
  }
  public openModalNotificacao() {
    this.showModalNotificacao = true;
    this.triggerShake();
  }

  public closeModalNotificacao() {
    this.showModalNotificacao = false;
    this.totalChamadosAtrasados = 0;
  }



  public validPermissionToView(roles: number[]): boolean {
    return roles.includes(this.perfil);
  }



}
