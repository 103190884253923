<button type="button" class="btn_segundario" (click)="abrirModalComponentCalendario()">
  {{this.textoBotao}}
</button>

<app-modal class="modal-overlay" *ngIf="isOpen">
  <div class="modal-container">
    <!-- Header do Modal -->
    <div class="modal-header">
      <div class="titulo-modal">
        <h2>{{ titulo }}</h2>
        <p>{{ subtitulo }}</p>
      </div>
      <button class="btn-fechar-modal" (click)="closeModal()">✖</button>
    </div>

    <!-- Corpo do Modal -->
    <div class="modal-body">
      <!-- Calendário -->
      <div class="calendar">
        <div class="calendar-header">
          <button type="button" class="btn-nav icone voltar-mes" (click)="previousMonth()">&#9664;</button>
          <!-- <span>{{ dataAtual | date: 'MMMM yyyy':" ":"pt" }}</span> --><!-- Nome do mês atualizado aqui -->
          <span>{{ dataAtualSignal() | date: 'MMMM yyyy':" ":"pt" }}</span> <!-- Nome do mês atualizado aqui -->
          <button type="button" class="btn-nav icone avancar-mes" (click)="nextMonth()">&#9654;</button>
        </div>
        <div class="calendar-grid">
          <!-- Dias da semana no cabeçalho -->
          <div class="day-header" *ngFor="let day of ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']">
            {{ day }}
          </div>

          <!-- Dias do mês -->
          <div *ngFor="let day of diasDoMes">
            <div class="day"
                *ngIf="day.dia !== null"
                [ngClass]="day.classes"
                [class.selected]="day.dia === diaSelecionado"
                (click)="selectDate(day.dia)">
              {{ day.dia }}
            </div>
          </div>
        </div>
        <div class="legenda-calendario">
          <p><b>Legenda: </b> Calendário</p>
          <div class="conteudo-legenda">

            <div class="box-legenda dia-atual">
              <i class="icone-dot"></i> <p>Dia Atual</p>
            </div>

            <div class="box-legenda indisponivel">
              <i class="icone-dot"></i> <p>indisponível</p>
            </div>

            <div class="box-legenda agendado">
              <i class="icone-dot"></i> <p>Já Agendado</p>
            </div>

          </div>
        </div>
      </div>


      <!-- Lista de Horários -->
      <div class="time-slots" *ngIf="diaSelecionado">
        <h3>Horários para o dia <span>{{ diaSelecionado }}</span>.</h3>
        <ul>
          <li *ngFor="let time of horariosDisponiveis"
              [class.selected]="time.horario === horarioSelecionado"
              [class.indisponivel]="!time.disponivel"
              (click)="selectTime(time.horario)">
            {{ time.horario }}
          </li>
        </ul>
      </div>
    </div>

    <!-- Footer do Modal -->
    <div class="modal-footer">
      <button class="btn_primeiro" (click)="closeModal()">Fechar</button>
      <button class="btn_segundario" [disabled]="!diaSelecionado || !horarioSelecionado"
              (click)="agendar()">Agendar</button>
    </div>
  </div>
</app-modal>
