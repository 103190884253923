<app-modal
*ngIf="showModalNotificacao && validPermissionToView(perfisQuePodemVerOModal)"
class="modal-notificacao-alert">
<div class="modal" [class.shake]="isShaking">
  <div class="modal-header">
    <h2>⚠️ Aviso!</h2>
    <button class="btn-close-modal" (click)="closeModalNotificacao()">Fechar</button>
  </div>
  <div class="modal-conteudo">
      <p>
        Você tem <b>{{GetTotalChamadosAtrasados()}}</b> ocorrências aguardando sua análise (SLA).
      </p>
      <ul>
        <li *ngIf="podeVisualizarCountChamadosPendentes"><b> {{countChamadoPorStatus('Pendente análise do chamado')}} </b> Pendente análise do chamado</li>
        <li *ngIf="podeVisualizarCountChamadosAtrasadoAgendamento"><b> {{countChamadoPorStatus('Atrasado Agendamento do Psicólogo')}} </b> Atrasado Agendamento do Psicólogo</li>
        <!-- <li *ngIf="countChamadoPorStatus('Atrasado Atendimento da Rede Protetiva') > 0 "><b> {{countChamadoPorStatus('Atrasado Atendimento da Rede Protetiva')}} </b> Atrasado Atendimento da Rede Protetiva</li> -->
        <!-- <li *ngIf="countChamadoPorStatus('Atrasado Atendimento da Saúde') > 0"><b> {{countChamadoPorStatus('Atrasado Atendimento da Saúde')}} </b> Atrasado Atendimento da Saúde</li> -->
        <li *ngIf="podeVisualizarCountChamadosAtrasadoAtendimento"><b> {{countChamadoPorStatus('Aguardando Atendimento do Psicólogo')}} </b> Aguardando Atendimento do Psicólogo</li>
        <li *ngIf="podeVisualizarCountChamadosAguardandoEncerramento"><b> {{countChamadoPorStatus('Aguardando Encerramento')}} </b> Aguardando Encerramento</li>
        <!-- <li><b> {{countChamadoPorStatus('Encerrado')}} </b> Encerrado</li> -->
      </ul>
      <a class="btn_outline"
        href="javascript:void(0)"
        routerLink="/listar-chamados">
        Verificar
      </a>
  </div>
</div>
</app-modal>

<!-- <button (click)="openModalNotificacao()">Abrir Modal</button> -->
