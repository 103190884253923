//Principais
import { formatDate } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from 'components/base/base.component';
import { lastValueFrom } from 'rxjs';
import { Diretoria } from 'src/app/shared/models/diretoria/Diretoria';
import { EmailLog } from 'src/app/shared/models/email/EmailLog';
import { Escola } from 'src/app/shared/models/escola/Escola';
import { FiltroPadrao } from 'src/app/shared/models/filtro-padrao/filtroPadrao';
import { ConvivaErrorHandlingService } from 'src/app/shared/services/convivaErrorHandlingService';
import { EmailService } from 'src/app/shared/services/email.service';

//Métodos

//Serviços


@Component({
  templateUrl: './gerenciamento-email.component.html',
  styleUrls: ['./gerenciamento-email.component.scss']
})

export class PageGerenciamentoEmailComponent extends BaseComponent implements OnInit {
  async ngOnInit(): Promise<void> {

    if (this.userLogado?.flPsicologo) {
      await this.getPoloDiretoriaPsicologo();
    }
    else {
      if (this.atribuicoesLogin.NumeroComportamento == 1) {
        await this.getPolosAdmin();
      }

      if (this.atribuicoesLogin.NumeroComportamento == 2) {
        await this.getPolosPerfilDiretor();
      }

      if (this.atribuicoesLogin.NumeroComportamento == 3 || this.atribuicoesLogin.NumeroComportamento == 4) {
        await this.getPolosPerfilProfessor();
      }
    }

    await this.OnSubmit();

  }
  //Injeções
  ConvivaErrors = inject(ConvivaErrorHandlingService);
  EmailService = inject(EmailService);
  formBuilder = inject(FormBuilder);


  //Variaveis
  formPesquisarEmails: FormGroup = this.formBuilder.group({
    codigoPolo: new FormControl(null, [Validators.required]),
    codigoDiretoria: new FormControl(null, [Validators.required]),
    codigoEscola: new FormControl(null, [Validators.required]),
    dataInicio: new FormControl(''),
    dataFim: new FormControl(''),
    tabAtiva: new FormControl('1')
  });

  submitted: boolean = false;
  emails: EmailLog[] = [];
  selectedEmail: EmailLog;
  colunasGrid = [
    { title: 'Id', field: 'codigoLog', width: 80, hozAlign: "center", headerHozAlign: "center" },
    { title: 'Data Envio', field: 'dataInclusaoFormatada', width: 150, hozAlign: "center", headerHozAlign: "center" },
    { title: 'Codigo Diretoria', field: 'codigoDiretoria', visible: false },
    { title: 'Codigo Escola', field: 'codigoEscola', visible: false },
    { title: 'Numero dos Chamados', field: 'codigosChamados', width: 140, hozAlign: "center", headerHozAlign: "center", formatter: "textarea" },
    { title: 'Email Destinatario', field: 'emailDestinatario', width: 300, hozAlign: "center", headerHozAlign: "center" },
    { title: 'Horas de Atraso', field: 'horaAtraso', width: 160, hozAlign: "center", headerHozAlign: "center", formatter: "textarea" },
    { title: 'Codigo Perfil', field: 'codigoPerfil', width: 140, hozAlign: "center", headerHozAlign: "center", formatter: "textarea" },
    {
      title: 'Ações', field: 'visualizar', hozAlign: "center", width: 45, formatter: function (cell: any, formatterParams: any, onRendered: any) {
        return `<div style='display:inline-flex;gap:0.4em;'> <i class='fa fa-eye'  aria-hidden='true'></i>`;
      }, headerHozAlign: "center", cellClick: (e: any, cell: any) => this.OnClickAcaoVisualizar(e, cell)
    },


  ];

  showModalInfoEmail: boolean = false;

  //Gets
  public get form() {
    return this.formPesquisarEmails.controls;
  }


  openModalInfoEmail() {
    this.showModalInfoEmail = true;
  }

  closeModalInfoEmail() {
    this.showModalInfoEmail = false;
  }

  async GetEmailsChamadosAtrasados(filters: FiltroPadrao) {
    const response = await lastValueFrom(this.EmailService.getEmailsChamadosAtrasados(filters))
      .catch(err => this.ConvivaErrors.handleResultError(err));
    if (!response.isSucess) {
      this.spinnerService.hide();
      this.toastr.error(response.message);
      return;
    }
    this.emails = response.data;
  }

  async GetEmailsAgendamentosAtrasados(filters: FiltroPadrao) {
    const response = await lastValueFrom(this.EmailService.getEmailsAgendamentosAtrasados(filters))
      .catch(err => this.ConvivaErrors.handleResultError(err));
    if (!response.isSucess) {
      this.spinnerService.hide();
      this.toastr.error(response.message);
      return;
    }
    this.emails = response.data;
  }

  async GetEmailsAtendimentosAtrasados(filters: FiltroPadrao) {
    const response = await lastValueFrom(this.EmailService.getEmailsAtendimentosAtrasados(filters))
      .catch(err => this.ConvivaErrors.handleResultError(err));
    if (!response.isSucess) {
      this.spinnerService.hide();
      this.toastr.error(response.message);
      return;
    }
    this.emails = response.data;


  }

  async OnSubmit() {
    this.spinnerService.show();
    this.submitted = true;
    if (this.formPesquisarEmails.invalid) {
      this.spinnerService.hide();
      this.toastr.error('Preencha todos os campos obrigatórios');
      return;

    }

    const filters = this.setFilters();

    if (this.form.tabAtiva.value === '1') {
      await this.GetEmailsChamadosAtrasados(filters);
    }

    if (this.form.tabAtiva.value === '2') {
      await this.GetEmailsAtendimentosAtrasados(filters);
    }

    if (this.form.tabAtiva.value === '3') {
      await this.GetEmailsAgendamentosAtrasados(filters);

    }

    this.spinnerService.hide();
  }

  setFilters() {
    const filters = new FiltroPadrao();
    filters.codigoPolo = this.form.codigoPolo.value;
    filters.codigoDiretoria = this.form.codigoDiretoria.value;
    filters.codigoEscola = this.form.codigoEscola.value;
    filters.codigoTurno = 0;
    filters.codigoTurma = 0;
    filters.codigoGravidade = 0;
    return filters;
  }

  private OnClickAcaoVisualizar(e: any, cell: any) {
    this.openModalInfoEmail();
    var row = cell.getRow().getData();
    let codigoLog = row.codigoLog;
    this.selectedEmail = this.emails.find(e => e.codigoLog == codigoLog)!;
  }

  formataDataPTBR(date:Date){
    return formatDate(date, 'dd/MM/yyyy', 'pt_BR');
  }

  onClickChangeTab(tabNumber:string){
    this.form.tabAtiva.setValue(tabNumber);
  }

  //Funções do filtro
  public async trocaDiretoria() {
    const codigoDiretoria = this.formPesquisarEmails.get('codigoDiretoria')?.value;
    if (this.userLogado !== null && codigoDiretoria != null) {
      this.userLogado.codigoDiretoria = codigoDiretoria == null ? 0 : codigoDiretoria;
      this.authService.setUser(this.userLogado);
    }
    if (codigoDiretoria != null)
      await this.getEscolasPorDiretoria();
    else
      this.escolas = [];
    this.form.codigoEscola.setValue(null);
  }

  public async getEscolasPorDiretoria() {
    if (this.userLogado !== null) {
      this.userLogado.codigoEscola = this.form.codigoEscola.value == null ? 0 : this.userLogado.codigoEscola;
      this.authService.setUser(this.userLogado);
    }

    if (this.form.codigoDiretoria.value) {
      this.spinnerService.show();
      this.zerarEscola();
      var response = await lastValueFrom(this.escolaService.getEscolasPorCodigoDiretoria(this.form.codigoDiretoria.value))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });
      if (!response.isSucess) {
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      this.escolas = response.data;
      this.ativaEscola();
      // await this.PesquisarChamados();
      this.spinnerService.hide();
    }

  }

  public zerarEscola() {
    this.escolas = [];
    this.formPesquisarEmails.get('codigoEscola')?.patchValue(null);
  }
  public zerarDiretoria() {
    this.diretorias = [];
    this.formPesquisarEmails.get('codigoDiretoria')?.patchValue(null);
  }


  public ativaEscola() {

    if (this.escolas != undefined && this.userLogado?.codigoEscola != 0) {
      const escola = this.escolas.find(s => s.codigoEscola == this.userLogado?.codigoEscola)
      if (escola != undefined) {
        this.formPesquisarEmails.get('codigoEscola')?.setValue(this.userLogado?.codigoEscola);
      }

    }
  }
  public ativaDiretoria() {
    if (this.diretorias != undefined && this.userLogado?.codigoDiretoria != 0) {
      const diretoria = this.diretorias.find(s => s.codigoDiretoria == this.userLogado?.codigoDiretoria)
      if (this.userLogado?.codigoDiretoria != undefined && diretoria != undefined) {
        this.formPesquisarEmails.get('codigoDiretoria')?.setValue(this.userLogado?.codigoDiretoria);
      }

    }
    if (this.userLogado !== null)
      this.authService.setUser(this.userLogado);
  }

  public async OnChangePolo() {

    const codigoPolo = this.formPesquisarEmails.get('codigoPolo')?.value;
    if (this.userLogado !== null) {
      this.userLogado.codigoPolo = codigoPolo == null ? 0 : codigoPolo;
      this.authService.setUser(this.userLogado);
    }

    if (codigoPolo != null) {
      await this.getDiretoriasPorPolo();
    } else {
      this.zerarDiretoria();
    }
    // this.showPesquisa = false;
    // this.lstChamados = [];
    this.zerarEscola();
  }

  public async getDiretoriasPorPolo(diretoriaLogada: boolean = false) {
    if (this.userLogado?.codigoPolo) {
      this.zerarDiretoria();
      this.spinnerService.show();
      var response = await lastValueFrom(this.psicologoService.getDiretoriaPorPolo(this.userLogado!.codigoPolo))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });
      if (!response.isSucess) {
        // this.showPesquisa = false;
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      this.diretorias = response.data;
      this.form.codigoDiretoria.enable();
      this.ativaDiretoria();
      await this.getEscolasPorDiretoria();
      this.spinnerService.hide();
    }

    if (this.userLogado !== null) {
      this.userLogado.codigoDiretoria = this.userLogado.codigoDiretoria == null ? 0 : this.userLogado.codigoDiretoria;
      this.authService.setUser(this.userLogado);
    }
  }

  public onChangeEscola() {
    const codigoEscola = this.formPesquisarEmails.get('codigoEscola')?.value;
    if (this.userLogado !== null) {
      this.userLogado.codigoEscola = codigoEscola == null ? 0 : codigoEscola;
      this.authService.setUser(this.userLogado);
    }
    // this.PesquisarChamados();
  }

  public async getPoloDiretoriaPsicologo() {

    var response = await lastValueFrom(this.psicologoService.getPoloPsicologo(this.userLogado!.cpf)).catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });

    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.polos = [response.data];
    this.form.codigoPolo.setValue(this.polos[0].codigoPolo);
    this.form.codigoPolo.disable();

    await this.getDiretoriasPorPolo();
  }


  public async getPolosAdmin() {
    this.spinnerService.show();
    var response = await lastValueFrom(this.psicologoService.getPolos()).catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });

    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.polos = response.data;
    this.spinnerService.hide();
    if (this.userLogado?.codigoPolo != 0) {
      this.ativaPolo();
    }
    this.spinnerService.hide();
  }

  public async getPolosPerfilDiretor() {

    this.spinnerService.show();
    var response = await lastValueFrom(this.psicologoService.getPolos(this.userLogado?.DadosUsuario[0].CodigoDiretoria)).catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });

    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.polos = response.data;
    this.form.codigoPolo.setValue(this.polos[0].codigoPolo);
    this.form.codigoPolo.disable();

    if (this.userLogado?.DadosUsuario[0].CodigoDiretoria != 0) {
      var todasDiretoriasDoUsuario: Diretoria[] = [];
      this.userLogado?.DadosUsuario.forEach(item => {
        var diretoria = new Diretoria();
        diretoria.codigoDiretoria = item.CodigoDiretoria;
        diretoria.nomeDiretoria = item.NomeDiretoria;
        todasDiretoriasDoUsuario.push(diretoria);
      });
      this.diretorias = todasDiretoriasDoUsuario;

      if (this.diretorias.length == 1) {
        this.form.codigoDiretoria.setValue(this.userLogado?.DadosUsuario[0].CodigoDiretoria);
      } else {

        if (this.userLogado?.codigoDiretoria && this.userLogado.codigoDiretoria > 0) {
          this.form.codigoDiretoria.setValue(this.userLogado?.codigoDiretoria);
        }

        this.form.codigoDiretoria.enable();
      }
      this.form.codigoEscola.enable();
      await this.getEscolasPorDiretoria();
    }

    this.spinnerService.hide();
  }

  public async getPolosPerfilProfessor() {
    this.spinnerService.show();
    var response = await lastValueFrom(this.psicologoService.getPolos(this.userLogado?.DadosUsuario[0].CodigoDiretoria)).catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });

    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.polos = response.data;
    this.form.codigoPolo.setValue(this.polos[0].codigoPolo);
    this.form.codigoPolo.disable();

    var dadosUsuario = this.userLogado?.DadosUsuario[0];
    if (dadosUsuario?.CodigoDiretoria != 0 && dadosUsuario?.CodigoEscola != 0) {
      this.userLogado?.DadosUsuario.forEach(item => {
        var diretoria = new Diretoria();
        diretoria.codigoDiretoria = item.CodigoDiretoria;
        diretoria.nomeDiretoria = item.NomeDiretoria;
        this.diretorias = [diretoria];

        var escola = new Escola();
        escola.codigoEscola = item.CodigoEscola;
        escola.nomeEscola = item.NomeEscola;
        this.escolas = [escola];
      });

      this.form.codigoDiretoria.setValue(this.userLogado?.DadosUsuario[0].CodigoDiretoria);
      this.form.codigoEscola.setValue(this.userLogado?.DadosUsuario[0].CodigoEscola);
    }
    this.spinnerService.hide();
  }

  public async ativaPolo() {
    if (this.userLogado?.codigoPolo)
      this.formPesquisarEmails.get('codigoPolo')?.setValue(this.userLogado?.codigoPolo);
    await this.getDiretoriasPorPolo();

    if (this.userLogado?.codigoDiretoria) {
      this.ativaDiretoria();
    }
    if (this.userLogado !== null)
      this.authService.setUser(this.userLogado);
  }
}
