import { Location, formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from "angular-datatables";
import { ADTSettings } from "angular-datatables/src/models/settings";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subject, lastValueFrom } from "rxjs";
import { AuthService } from 'src/app/shared/auth/services/auth.service';
import { Perfil } from 'src/app/shared/enums/Perfis/Perfis';
import { Envolvido } from 'src/app/shared/models/chamados/envolvido';
import { HistoricoChamado } from 'src/app/shared/models/chamados/historico';
import { TurmasPOC } from 'src/app/shared/models/professor/TurmasAprovacaoPOC';
import { AprovacaoPsicologoFiscal } from 'src/app/shared/models/psicologo/AprovacaoPsicologoFiscal';
import { PsicologoAtividade } from 'src/app/shared/models/psicologo/ValidarAtividadePsicologo';
import { AtribuicaoLoginSed, UserLoginSed } from 'src/app/shared/models/sed/UserLogin';
import { ConcluirService } from 'src/app/shared/services/concluir.service';
import { ConvivaErrorHandlingService } from 'src/app/shared/services/convivaErrorHandlingService';
import { DetalheService } from 'src/app/shared/services/detalhe.service';
import { EnvolvidoService } from 'src/app/shared/services/envolvido.service';
import { PsicologoService } from 'src/app/shared/services/psicologo.service';

@Component({
  templateUrl: './aprovacao-visitas.component.html',
  styleUrls: ['./aprovacao-visitas.component.scss']
})

export class PageAprocacaoVisitasComponent implements OnInit {
  public userLogado: UserLoginSed | null;
  public atribuicoesLogin: AtribuicaoLoginSed;
  isChecked = false;
  public dtOptions: any = {};
  public dtTrigger: Subject<ADTSettings> = new Subject<ADTSettings>();
  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  public hideAfterLoadTable: boolean;
  public hideAfterLoadTableAlunos: boolean;
  public isDtInitialized: boolean = false;
  public parametroId: any;
  public chamadoEncerrar: number | null = null;

  public disableInputsFiscal: boolean = false;
  public textAreaFiscalCount: number = 0;
  public checkboxNumberFiscal: number = 0;
  public ratingFiscal: number = 0;

  // public disableInputsPEC: boolean = false;
  // public textAreaPECCount: number = 0;
  // public checkboxNumberPEC: number = 0;
  // public ratingPEC: number = 0;

  public tpAcaoAtividade: string = "";
  public tipoAprovacao: boolean = true;

  constructor(private spinnerService: NgxSpinnerService,
    private detalheService: DetalheService,
    private ConvivaErrors: ConvivaErrorHandlingService,
    private toastr: ToastrService,
    @Inject(LOCALE_ID) private locale: string,
    private envolvidoService: EnvolvidoService,
    private concluirService: ConcluirService,
    private psicologoService: PsicologoService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {
    this.userLogado = this.authService.getUser();

    if (this.userLogado != null) {
      this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];

      this.route.paramMap.subscribe((params) => {
        this.parametroId = Number(params.get('id')) ?? 0;
      });


    }
  }
  //Forms da tela
  public isButtonVisible: boolean = true;
  public submitted: boolean;

  //Modal
  public showModalConfirmacao: boolean;

  //Listas da tela
  public atividade: PsicologoAtividade = new PsicologoAtividade();
  public lstEnvolvidos: Envolvido[] = [];
  public lstTurmas: TurmasPOC[] = [];

  // FORM CADASTRAR
  public formCadastrar!: FormGroup;


  //BTN VOLTAR
  goBack(): void {
    this.location.back();
  }

  public get form() {
    return this.formCadastrar.controls;
  }

  public async ngOnInit() {

    this.dtOptions = {
      deferRender: true,
      destroy: true,
      processing: true,
      paging: true,
      dom: 'Bfrtip',
      order: [
        [0, 'asc'],
        [6, 'asc']
      ],
      language: {
        url: 'https://cdn.datatables.net/plug-ins/1.11.5/i18n/pt-BR.json'
      },
      lengthMenu: [
        [10, 25, 50, -1],
        ['10 linhas', '25 linhas', '50 linhas', 'Tudo']
      ],
      buttons: [
        'pageLength',
        {
          extend: 'print',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6]
          }
        },
        {
          extend: 'excel',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6]
          }
        },
        {
          extend: 'pdf',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6]
          }
        }
      ],
    };
    this.formCadastrar = new FormGroup({
      dsAprovar: new FormControl(''),
      dsAprovarFiscal: new FormControl(''),
      opcaoAcao: new FormControl(''),
      opcaoAcaoFiscal: new FormControl(''),
    });
    await this.PopularDados();
    this.setDadosAprovacao();
    this.disableInputsPerfis();

  }


  public async PopularDados() {
    this.spinnerService.show();
    const result = await lastValueFrom(await this.detalheService.getAtividadeDetalhePsicologoPorId(this.parametroId))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
    if (!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.atividade = result.data;

    if (this.atividade.tP_ACAO_ATIVIDADE == 1 || this.atividade.tP_ACAO_ATIVIDADE == 3) {
      this.tpAcaoAtividade = " Prevenção";
    } else
      this.tpAcaoAtividade = " Acolhimento";

    if (this.atividade.tP_ACAO_ATIVIDADE == 5) {
        this.tpAcaoAtividade = "Outros";
    }
    if (this.atividade.cD_ENVOLVIDO != null) {
      var envolvido = await lastValueFrom(
        this.envolvidoService.GetEnvolvidosPorAtividadePsicologo(this.atividade.cD_ATIVIDADE_PSICOLOGO));
      this.lstEnvolvidos = envolvido.data;
    }
    if (this.atividade.tP_ATIVIDADE_REALIZADA == 2) {
      var turmas = await lastValueFrom(this.psicologoService.getTurmasAprovacaoPsicologo(this.atividade.cD_ATIVIDADE_PSICOLOGO));
      this.lstTurmas = turmas.data;
    }

    setTimeout(() => {
      this.spinnerService.hide();
    }, 3000);
  }
  public getDate(data?: Date) {
    if (data == undefined) return;
    return formatDate(data, 'dd/MM/yyyy', this.locale);
  }
  public getTempoDeslocamento(saida?: string, chegada?: string): string {
    if (!saida || !chegada) return '';

    const [horaSaida, minutoSaida] = saida.split(':').map(Number);
    const [horaChegada, minutoChegada] = chegada.split(':').map(Number);

    const dataSaida = new Date();
    dataSaida.setHours(horaSaida, minutoSaida);

    const dataChegada = new Date();
    dataChegada.setHours(horaChegada, minutoChegada);

    const diferencaMs = dataChegada.getTime() - dataSaida.getTime();
    const diferencaMin = Math.floor(diferencaMs / 60000);
    const horas = Math.floor(diferencaMin / 60);
    const minutos = diferencaMin % 60;

    return `${this.pad(horas)}:${this.pad(minutos)}`;
  }

  pad(num: number): string {
    return num.toString().padStart(2, '0');
  }

  public abrirEmNovaAba(caminho: string): void {
    if (this.atividade && caminho) {
      window.open(caminho, '_blank');
    }
  }
  public getArquivoNomeCompleto(): string {
    if (!this.atividade || !this.atividade.nM_ARQUIVO) {
      return '';
    }
    return this.atividade.nM_ARQUIVO.slice(37);
  }


  public AbreFechaModalConfirmacao(codigoChamado?: number) {
    if (codigoChamado !== undefined) {
      this.chamadoEncerrar = this.atividade.cD_CHAMADO!;
    }
    this.showModalConfirmacao = !this.showModalConfirmacao;
  }

  // public async AprovarAtividadePEC() {

  //   this.spinnerService.show();
  //   this.submitted = true;

  //   // if (this.atividade.sT_APROVACAO_PEC == 1 || this.atividade.sT_APROVACAO_PEC == 2) {
  //   //   this.toastr.error(`A atividade código ${this.atividade.cD_ATIVIDADE_PSICOLOGO} já foi aprovada`);
  //   //   this.spinnerService.hide();
  //   //   return;
  //   // }

  //   if(!this.form.opcaoAcao.value){
  //     this.toastr.error('Por favor, preencha alguma das opções avaliando a atividade do Psicologo');
  //     this.spinnerService.hide();
  //     return;
  //   }

  //   if (this.form.dsAprovar.value == "") {
  //     this.toastr.error('Por favor, responda a avaliação sobre a atividade do Psicologo');
  //     this.spinnerService.hide();
  //     return;

  //   }

  //   if(this.ratingPEC == 0){
  //     this.toastr.warning('Por favor, avalie a atividade do Psicologo');
  //     this.spinnerService.hide();
  //     return;
  //   }

  //   if(this.form.dsAprovar.value.length < 150 || this.form.dsAprovar.value.length > 500){
  //     this.toastr.error('A descrição deve conter no minimo 150 caracteres e no máximo 500');
  //     this.spinnerService.hide();
  //     return;
  //   }

  //   const objetoAprovacao: AprovacaoPsicologoPEC = {
  //     cD_USUARIO_APROVACAO_PEC: this.atribuicoesLogin.CodigoUsuario,
  //     cD_PERFIL_APROVACAO_PEC: this.atribuicoesLogin.CodigoPerfil,
  //     nM_PERFIL_APROVACAO_PEC: this.atribuicoesLogin.NomePerfil,
  //     oB_APROVACAO_PEC: this.form.dsAprovar.value,
  //     sT_APROVACAO_PEC: this.checkboxNumberPEC,
  //     cD_ATIVIDADE_PSICOLOGO: this.atividade.cD_ATIVIDADE_PSICOLOGO,
  //     eSTRELAS_APROVACAO_PEC : this.ratingPEC
  //   };

  //   var response = await lastValueFrom(this.psicologoService.aprovacaoAtividadePsicologoPEC(objetoAprovacao))
  //     .catch((err) => {
  //       return this.ConvivaErrors.handleResultError(err);
  //     });

  //   if (!response.isSucess) {
  //     this.toastr.error(response.message);
  //     this.spinnerService.hide();
  //     return;
  //   }

  //   if( this.atividade.cD_CHAMADO != null ){
  //     await this.ConcluirChamadoAtividade();
  //   }

  //   this.toastr.success("Atividade aprovada com sucesso!");
  //   this.spinnerService.hide();
  //   this.router.navigate(['/dashboard-visitas-psicologo/']);
  // }

  public async AprovarAtividadeFiscal() {

    this.spinnerService.show();
    this.submitted = true;
    if(this.form.opcaoAcaoFical){
      this.toastr.error('Por favor, preencha alguma das opções avaliando a atividade do Psicologo');
      this.spinnerService.hide();
      return;
    }

    if (this.atividade.sT_APROVACAO_FISCAL == 1 || this.atividade.sT_APROVACAO_FISCAL == 2) {
      this.toastr.error(`A atividade código ${this.atividade.cD_ATIVIDADE_PSICOLOGO} já foi aprovada`);
      this.spinnerService.hide();
      return;
    }

    if (this.form.dsAprovarFiscal.value == "") {
      this.toastr.warning('Por favor, responda a avaliação sobre a atividade do Psicologo');
      this.spinnerService.hide();
      return;

    }

    if(this.ratingFiscal == 0){
      this.toastr.warning('Por favor, avalie a atividade do Psicologo');
      this.spinnerService.hide();
      return;
    }

    if(this.form.dsAprovarFiscal.value.length < 150 || this.form.dsAprovarFiscal.value.length > 500){
      this.toastr.warning('A descrição deve conter no minimo 150 caracteres e no máximo 500');
      this.spinnerService.hide();
      return;
    }

    const objetoAprovacao: AprovacaoPsicologoFiscal = {
      cD_USUARIO_APROVACAO_FISCAL: this.atribuicoesLogin.CodigoUsuario,
      cD_PERFIL_APROVACAO_FISCAL: this.atribuicoesLogin.CodigoPerfil,
      nM_PERFIL_APROVACAO_FISCAL: this.atribuicoesLogin.NomePerfil,
      oB_APROVACAO_FISCAL: this.form.dsAprovarFiscal.value,
      sT_APROVACAO_FISCAL: this.checkboxNumberFiscal,
      cD_ATIVIDADE_PSICOLOGO: this.atividade.cD_ATIVIDADE_PSICOLOGO,
      eSTRELAS_APROVACAO_FISCAL:this.ratingFiscal
    };

    var response = await lastValueFrom(this.psicologoService.aprovacaoAtividadePsicologoFiscal(objetoAprovacao))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });

    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }

      if( this.atividade.cD_CHAMADO != null ){
      await this.ConcluirChamadoAtividade();
    }

    this.toastr.success("Atividade aprovada com sucesso!");
    this.spinnerService.hide();
    this.router.navigate(['/dashboard-visitas-psicologo/']);
  }


  // public async ConcluirChamado() {

  //   this.spinnerService.show();
  //   if (this.atividade.status == 4) {
  //     this.toastr.error("Chamado já finalizado");
  //     this.spinnerService.hide();
  //     return;
  //   }

  //   const objetoHistorico: HistoricoChamado = {
  //     codigoChamado: this.atividade.cD_CHAMADO!,
  //     codigoEnvolvido: 0,
  //     descricaoHistorico: 'Chamado deste envolvido foi encerrado pela atividade exercida pelo psicólogo.',
  //     statusChamado: 6,
  //     nomeArquivo: "",
  //     caminhoArquivo: "",
  //     conteudo: "",
  //     codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
  //     nomePerfil: this.atribuicoesLogin.NomePerfil
  //   };

  //   this.AbreFechaModalConfirmacao();

  //   this.spinnerService.show();

  //   const result = await lastValueFrom(await this.concluirService.setCadastrarConcluirChamadoPorAtividade(objetoHistorico))
  //     .catch((err) => {
  //       return this.ConvivaErrors.handleResultError(err);
  //     });

  //   if (!result.isSucess) {
  //     this.toastr.error(result.message, result.title);
  //     this.spinnerService.hide();
  //     return;
  //   }
  //   this.isButtonVisible = true;
  //   this.toastr.success('Chamado deste envolvido encerrado com sucesso.');
  //   this.spinnerService.hide();

  // }

  public async ConcluirChamadoAtividade() {

    const objetoHistorico: HistoricoChamado = {
      codigoChamado: this.atividade.cD_CHAMADO!,
      CD_ATIVIDADE_PSICOLOGO: this.atividade.cD_ATIVIDADE_PSICOLOGO,
      codigoEnvolvido: 0,
      descricaoHistorico: 'Chamado deste envolvido foi encerrado pela atividade exercida pelo psicólogo.',
      statusChamado: 6,
      nomeArquivo: "",
      caminhoArquivo: "",
      conteudo: "",
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil,
      envolvidosId: this.lstEnvolvidos.map(item => item.codigoEnvolvido! ?? item.cdEnvolvido)
    };

    const result = await lastValueFrom(await this.concluirService.setCadastrarConcluirChamadoPorAtividade(objetoHistorico))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });

    if (!result.isSucess) {
      this.toastr.warning(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.isButtonVisible = true;
    this.toastr.success('Chamado deste envolvido encerrado com sucesso.');
  }

  getShortenedName(name: string): string {
    if (!name || name.length <= 19) {
      return name;
    }

    const firstPart = name.slice(0, 15);
    const lastPart = name.slice(-4);
    return `${firstPart}${lastPart}`;
  }

  onChangeTextAreaFiscal(){
    this.textAreaFiscalCount =  this.form.dsAprovarFiscal.value.length;
  }
  // onChangeTextAreaPEC(){
  //   this.textAreaPECCount =  this.form.dsAprovar.value.length;
  // }
  getRatingFiscal(rating:number){
    this.ratingFiscal = rating;
  }
  // getRatingPEC(rating:number){
  //   this.ratingPEC = rating;
  // }
  onChangeFiscal(event:any){
    this.checkboxNumberFiscal = Number(event.target.value);
    this.ratingFiscal = 0;
  }

  // onChangePEC(event:any){
  //   this.checkboxNumberPEC = Number(event.target.value);
  //   this.ratingPEC = 0;
  // }

  setDadosAprovacao() {

    // if (this.atividade.sT_APROVACAO_PEC) {
    //   const statusAprovacao = this.atividade.sT_APROVACAO_PEC;
    //   if (statusAprovacao == 1) {
    //     this.form.opcaoAcao.setValue('1');
    //   } else if (statusAprovacao == 2) {
    //     this.form.opcaoAcao.setValue('2');
    //   } else {
    //     this.form.opcaoAcao.setValue('3');
    //   }

    //   this.form.dsAprovar.setValue(this.atividade.oB_APROVACAO_PEC);

    //   this.ratingPEC = this.atividade.estrelaS_AVALIACAO_PEC!;
    //   this.onDisableInputsPEC();
    // }

    if (this.atividade.sT_APROVACAO_FISCAL) {

      const statusAprovacao = this.atividade.sT_APROVACAO_FISCAL;
      if (statusAprovacao == 1) {
        this.form.opcaoAcaoFiscal.setValue('1');
      } else if (statusAprovacao == 2) {
        this.form.opcaoAcaoFiscal.setValue('2');
      } else {
        this.form.opcaoAcaoFiscal.setValue('3');
      }

      this.form.dsAprovarFiscal.setValue(this.atividade.oB_APROVACAO_FISCAL);
      this.ratingFiscal = this.atividade.estrelaS_AVALIACAO_FISCAL!;
      this.onDisableInputsFiscal();
    }

  }
  // // this.onDisableInputsDiretor();
  // onDisableInputsPEC(){
  //   this.disableInputsPEC = true;
  //   this.form.dsAprovar.disable();
  //   this.form.opcaoAcao.disable();
  // }
  onDisableInputsFiscal(){
    this.disableInputsFiscal = true;
    this.form.dsAprovarFiscal.disable();
    this.form.opcaoAcaoFiscal.disable();
  }

  disableInputsPerfis() {
    const perfilAtual = this.userLogado?.codigoPerfil;
    if ( perfilAtual != Perfil.GestorConviva) {

      if (perfilAtual != Perfil.FiscalPsicologo) {
        this.onDisableInputsFiscal();
      }

      // if(perfilAtual != Perfil.PEC){
      //   this.onDisableInputsPEC();
      // }
    }

  }
}
