import { formatDate, registerLocaleData } from '@angular/common';
import myLocalePt from '@angular/common/locales/pt';
import { Component, effect, inject } from "@angular/core";
import { FormControl, FormGroup } from '@angular/forms';
import { BaseComponent } from 'components/base/base.component';
import { lastValueFrom } from 'rxjs';
import { CalendarioEscolar } from 'src/app/shared/models/calendario/CalendarioEscolar';
import { Diretoria } from 'src/app/shared/models/diretoria/Diretoria';
import { Escola } from 'src/app/shared/models/escola/Escola';
import { NaoOcorrencia } from 'src/app/shared/models/nao-ocorrencia/NaoOcorrencia';
import { Consolidado } from 'src/app/shared/models/relatorio/Consolidado';
import { ListarCalendario } from "src/app/shared/models/relatorio/listar-calendario";
import { CalendarioService } from 'src/app/shared/services/calendario.service';
import { FiltroService } from 'src/app/shared/services/filtro.service';
import { NaoOcorrenciaService } from 'src/app/shared/services/nao-ocorrencia.service';
import { RelatorioService } from 'src/app/shared/services/relatorio.service';

@Component({
  selector: 'calendario-component',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.scss']
})
export class CalendarioComponent extends BaseComponent {
  isLoading = false;

  formHomeNaoOcorrencia:FormGroup;
  public get formNaoOco() { return this.formHomeNaoOcorrencia.controls; }

  qtdeCaracteresDescricao: number = 0;
  submitted = false;

  public lstDiretorias: Diretoria[] = [];
  public lstEscolas: Escola[] = [];
  async ngOnInit() {
    this.formHomeNaoOcorrencia = new FormGroup({
      dsChamado: new FormControl(null)
    });

  }


  constructor() {
    super();
    var DateObj = new Date();
    this.anoCalendario = DateObj.getFullYear();
    this.mesCalendario = DateObj.getMonth();
    this.getCurrentMonth();
    effect(async () => {
      this.isLoading = true;
      // await this.getCurrentMonth();
      await this.generateDaysInMonth();
      this.isLoading = false;
    })
  }

  naoOcorrenciaService = inject(NaoOcorrenciaService);
  // spinnerService = inject(NgxSpinnerService);
  relatorioService = inject(RelatorioService)
  // convivaErrors = inject(ConvivaErrorHandlingService)
  calendarioService = inject(CalendarioService)
  filtroService = inject(FiltroService)
  // router = inject(Router)
  // escolaService = inject(EscolaService)





  showModalCadastraNaoOcorrencia = false;
  showModalCalendarioOcorrencia = false;
  filtroSignal = this.filtroService.filtro;
  descricaoNaoOcorrencia = '';
  listaDias: ListarCalendario[] = [];
  dataOcorrenciaNaoOcorrencia = new Date();
  currentDate: string;
  mesCalendario: number;
  anoCalendario: number = new Date().getFullYear();
  daysInMonth: { date: Date, isWeekend: boolean, isHoliday: boolean }[] = [];
  diasNaoLetivos:CalendarioEscolar[] = [];


  public OnInserirOcorrenciaNaoOcorrencia(DataOcorrencia: Date, EstatusDia: Number) {
    var dataOco = formatDate(DataOcorrencia, 'yyyy-MM-dd', 'en_US');
    var hoje = formatDate(new Date, 'yyyy-MM-dd', 'en_US');


    if (dataOco > hoje || !(EstatusDia == 4 || EstatusDia == 1))
      this.toastr.warning("Data não valida para uma ação");
    else {
      this.showModalCalendarioOcorrencia = true;
      this.dataOcorrenciaNaoOcorrencia = DataOcorrencia;
    }
  }

  public async goToPreviousMonth() {
    const date = new Date(this.currentDate);
    date.setMonth(date.getMonth() - 1);

    if (this.mesCalendario == 0) {
      this.anoCalendario -= 1;
      this.mesCalendario = 11;
    } else
      this.mesCalendario -= 1;

    this.currentDate = `${this.anoCalendario}-${this.mesCalendario + 1}-01`;
    await this.generateDaysInMonth();
  }

  public async getCurrentMonth(){
    const date = new Date(this.currentDate);
    date.setMonth(date.getMonth() );
    this.currentDate = `${this.anoCalendario}-${this.mesCalendario + 1}-01`;
  }


  public async goToCurrentMonth() {
    const date = new Date(this.currentDate);
    date.setMonth(date.getMonth() + 1);

    if (this.mesCalendario == 11) {
      this.anoCalendario += 1;
      this.mesCalendario = 0;
    } else
      this.mesCalendario += 1;

    this.currentDate = `${this.anoCalendario}-${this.mesCalendario + 1}-01`;
    await this.generateDaysInMonth();
  }


  public isCurrentDay(day: Date): boolean {
    const today = new Date();
    return day.getFullYear() === today.getFullYear() &&
      day.getMonth() === today.getMonth() &&
      day.getDate() === today.getDate();
  }

  public isHoliday(day: Date): CalendarioEscolar | null {
    if(this.diasNaoLetivos.length === 0 )
      return null;

    const diaNaoLetivo = this.diasNaoLetivos.find(x=>formatDate(day, 'dd/MM/yyyy', 'pt_BR') == formatDate(x.dataInicio, 'dd/MM/yyyy', 'pt_BR'));
    if(diaNaoLetivo && !diaNaoLetivo.letivo)
        return diaNaoLetivo;

    return null;
  }

  public async onDateChange(event: any) {
    await this.generateDaysInMonth();
  }

  public formatarMes(date: string): string {
    const mes = new Date(date);
    return formatDate(mes, 'LLLL', 'pt');
  }

  public formatarDiaSemana(date: Date): string {
    return formatDate(date, 'EEEE', 'pt-br');
  }

  public async generateDaysInMonth() {

    this.isLoading = true;
    registerLocaleData(myLocalePt);
    // const daysInMonth = new Date(this.anoCalendario, this.mesCalendario + 1, 0).getDate();
    const parametros = this.SetParametrosCalendario(this.anoCalendario, this.mesCalendario + 1);
    if (parametros.codigoDiretoria == null || parametros.codigoEscola == null) {
      this.toastr.warning("Diretoria e Escola são obrigatórios");
      // this.spinnerService.hide();
      return;
    }
    await this.geraDiasNaoLetivos();


    var response = await lastValueFrom(this.relatorioService.getCalendarioOcorrencias(parametros))
      .catch((err) => {
        return this.convivaErrors.handleResultError(err);
      });

    if (!response.isSucess) {
      this.toastr.error(response.message);
      return;
    }

    let diasCalendario =  response.data;
    for(let item of diasCalendario){
      const isHoliday = this.isHoliday(item.data);
      item.cor = isHoliday ? 'cinza' : item.cor;
      item.estatusDia = isHoliday ? 5 : item.estatusDia;
      item.descricao = isHoliday ? isHoliday.descricao :
      (item.diaDaSemana == "SÁB" || item.diaDaSemana == "DOM" ? "Fim de Semana" : "Dia Letivo");


    }

    this.listaDias = diasCalendario;
    this.isLoading = false;
  }

  public SetParametrosCalendario(ano: number, mes: number): Consolidado {
    const filtroEmit = this.filtroSignal();
    const parametros = new Consolidado();
    parametros.codigoDiretoria = filtroEmit.codigoDiretoria;
    parametros.codigoEscola = filtroEmit.codigoEscola;
    parametros.ano = ano;
    parametros.mes = mes;
    return parametros;
  }

  async geraDiasNaoLetivos(){
    const filtro = this.filtroSignal();

    if(!filtro.codigoEscola || filtro.codigoEscola == 0){
      return;
    }

    var response = await lastValueFrom(
      this.calendarioService.getConsultarCalendario(filtro.codigoEscola,this.anoCalendario))
    .catch(err=> this.convivaErrors.handleResultError(err));

    if(!response.isSucess){
      this.toastr.error("Feriados não encontrados");
      return;
    }
    this.diasNaoLetivos = response.data;
    this.diasNaoLetivos = this.diasNaoLetivos.filter(x=>!x.letivo && new Date(x.dataInicio).getMonth() === this.mesCalendario);

  }

  public FecharModalCalendario() {
    this.showModalCalendarioOcorrencia = false;
    this.descricaoNaoOcorrencia = "";
  }

  public InserirOcorrencia() {
    var dt = this.dataOcorrenciaNaoOcorrencia;
    this.router.navigate(['/cadastrar-chamados/', dt]);
  }

  public InserirNaoOcorrencia() {
    this.showModalCadastraNaoOcorrencia = true;
    this.descricaoNaoOcorrencia = "";
  }
  public FecharModalCadastroNaoOcorrencia() {
    this.showModalCadastraNaoOcorrencia = false;
  }


  public OnChangeCountLetters() {
    const descricao = this.formHomeNaoOcorrencia.get('dsChamado')!.value;
    if (descricao != null)
      this.qtdeCaracteresDescricao = descricao.length;
  }

  public async InserirRegistroNaoOcorrencia() {

    var descricao = this.formHomeNaoOcorrencia.get('dsChamado')!.value;
    if (descricao == null)
      descricao = 'Nao houve chamados para o periodo selecionado';

    await this.getDiretorias();
    await this.getEscolas();
    const filtroEmit = this.filtroSignal();

    const dir = this.lstDiretorias.filter(r => r.codigoDiretoria == filtroEmit.codigoDiretoria)[0];
    const esc = this.lstEscolas.filter(r => r.codigoEscola == filtroEmit.codigoEscola)[0];



    const objetoChamado: NaoOcorrencia = {
      cdGravidade: 0,
      cdCategoria: 13,
      cdSubCategoria: 42,
      dtNaoOcorrencia: formatDate(this.dataOcorrenciaNaoOcorrencia, 'yyyy-MM-dd', 'en_US'),
      cdDiretoria: dir.codigoDiretoria,
      nmDiretoria: dir.nomeDiretoria,
      cdEscola: esc.codigoEscola,
      nmEscola: esc.nomeEscolaSemCodigo,
      dsNaoOcorrencia: descricao,
      cdTurno: 0,
      codigoUsuario: this.atribuicoesLogin.CodigoUsuario,
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil,
      cdNaoOcorrencia: 0
    };

    const result = await lastValueFrom(this.naoOcorrenciaService.setCadastrarNaoOcorrencia(objetoChamado))
      .catch((err) => {
        return this.convivaErrors.handleResultError(err);
      });

    if (!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }

    this.spinnerService.hide();
    this.toastr.success('Chamado cadastrado com sucesso.');
    this.formNaoOco.dsChamado.setValue(null);

    setTimeout(() => {
      this.submitted = false;
      document.getElementById("btnLimpar")?.click();
    }, 2000);

    this.showModalCadastraNaoOcorrencia = false;
    this.showModalCalendarioOcorrencia = false;
    await this.generateDaysInMonth();
  }

  public async getDiretorias() {
    this.spinnerService.show();

    const response = await lastValueFrom(this.escolaService.getDiretoriaPorTipoDiretoria())
      .catch((err) => {
        return this.convivaErrors.handleResultError(err);
      });

    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.lstDiretorias = response.data;
    this.spinnerService.hide();
  }
  public async getEscolas(diretoriaLogada: boolean = false) {

    const codigoDE = this.filtroSignal().codigoDiretoria;
    if (codigoDE != null) {
      this.spinnerService.show();
      var response = await lastValueFrom(this.escolaService.getEscolasPorCodigoDiretoria(codigoDE))
        .catch((err) => {
          return this.convivaErrors.handleResultError(err);
        });
      if (!response.isSucess) {
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      this.lstEscolas = response.data;
      this.spinnerService.hide();
    }
  }

}
