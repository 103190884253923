//Principais
import { Component, OnInit, ViewChild, TemplateRef, ComponentFactoryResolver, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgSelectConfig } from '@ng-select/ng-select';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { lastValueFrom } from "rxjs";
//Services
import { ArquivoService } from 'src/app/shared/services/arquivo.service';
import { CategoriaService } from 'src/app/shared/services/categoria.service';
import { EscolaService } from 'src/app/shared/services/escola.service';
import { AlunoService } from 'src/app/shared/services/alunos.service';
import { ConvivaErrorHandlingService } from 'src/app/shared/services/convivaErrorHandlingService';
import { GenericValidator } from 'src/app/shared/validations/genericValidators';
import { ChamadoService } from 'src/app/shared/services/chamado.service';
import { FuncionarioService } from 'src/app/shared/services/funcionarios.service';
//Models
import { DefaultResponse } from 'src/app/shared/models/DefaultResponse';
import { Diretoria } from 'src/app/shared/models/diretoria/Diretoria';
import { Escola } from 'src/app/shared/models/escola/Escola';
import { Categoria } from 'src/app/shared/models/categoria/Categoria';
import { Gravidade } from 'src/app/shared/models/categoria/Gravidade';
import { ArquivoAzure } from 'src/app/shared/models/arquivo/ArquivoAzure';
import { Envolvido } from 'src/app/shared/models/chamados/envolvido';
import { Chamado } from 'src/app/shared/models/chamados/chamado';
import { Aluno } from 'src/app/shared/models/envolvidos/aluno';
import { Turno } from 'src/app/shared/models/escola/Turno';
import { Funcionario } from 'src/app/shared/models/envolvidos/funcionario';
import { AtribuicaoLoginSed, UserLoginSed } from 'src/app/shared/models/sed/UserLogin';
import { AuthService } from 'src/app/shared/auth/services/auth.service';
import { DateTimeProvider } from 'angular-oauth2-oidc';
import { NaoOcorrencia } from 'src/app/shared/models/nao-ocorrencia/NaoOcorrencia';
import { NaoOcorrenciaService } from 'src/app/shared/services/nao-ocorrencia.service';
import { ListarNaoOcorrencias } from 'src/app/shared/models/nao-ocorrencia/ListarNaoOcorrencia';


@Component({
  selector: 'nao-ocorrencia-add',
  templateUrl: './nao-ocorrencia-add.component.html',
  styleUrls: ['./nao-ocorrencia-add.component.scss'],
})
export class AddNaoOcorrenciaComponent implements OnInit {
  public userLogado: UserLoginSed | null;
  public atribuicoesLogin: AtribuicaoLoginSed;
  public parametroRA: any;
  public parametroTE: any;
  public qtdeCaracteresDescricao:number = 0;
  public NaoOcorrenciaCadastradaHoje:boolean;

  constructor(
    private escolaService: EscolaService,
    private categoriaService: CategoriaService,
    private arquivoService: ArquivoService,
    private config: NgSelectConfig,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private ConvivaErrors: ConvivaErrorHandlingService,
    private alunoService: AlunoService,
    private genericValidate: GenericValidator,
    private chamadoService: ChamadoService,
    private funcionarioService: FuncionarioService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private naoOcorrenciaService:NaoOcorrenciaService
  ) {
    this.config.notFoundText = "Não encontrado";

    this.route.paramMap.subscribe(params => {
      this.parametroRA = params.get('ra') ?? '';
      this.parametroTE = params.get('te') ?? '';
    });

    this.userLogado = this.authService.getUser();

    if(this.userLogado != null){
      this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];
    }
  }

  @ViewChild('modalContent') modalContent!: TemplateRef<any>;

  public showModal = false;
  public showBuscaAluno = false;
  public showBuscaEquipe = false;
  public showOutros = false;
  public validaBuscaRA = false;
  public submitted = false;
  public submittedModal = false;
  public showDropAuxiliar = false;
  public showModalExcluirAluno = false;

  public tituloModal = '';
  public descricaoExclusao = '';
  public envolvidoExclusao?: Envolvido;

  public diretorias: Diretoria[] = [];
  public escolas: Escola[] = [];
  public turnos: Turno[] = [];
  public escolasModal: Escola[] = [];
  public categorias: Categoria[] = [{
    descricaoSubCategoria:"Não Ocorrência",
    codigoSubCategoria:42,
    codigoCategoria:13,
    descricaoCategoria:"Não Ocorrência"
  }];
  public gravidadesX: Gravidade[] = [];
  public files: ArquivoAzure[] = [];
  public envolvidos: Envolvido[] = [];
  public alunos: Aluno[] = [];
  public aluno: Aluno = <Aluno>{};
  public funcionarios: Funcionario[] = [];
  public permissaoParaCadastrarNaoOcorrencia:boolean ;

  public gravidades = [
    { id: 0, name: 'Não Ocorrência' }
  ];

  public TipoPessoas = [
    { id: 1, name: 'Aluno' },
    { id: 2, name: 'Equipe Escolar' },
    { id: 3, name: 'Outros' },
  ];

  public TipoEnvolvido = [
    { id: 1, name: 'Ofendido' },
    { id: 2, name: 'Ofensor' }
  ];

  public formCadastrar!: FormGroup;
  public formModal!: FormGroup;

  // FORM CADASTRAR
  public get form() {
    return this.formCadastrar.controls;
  }
  // FORM MODAL
  public get modalForm() {
    return this.formModal.controls;
  }

  // DADOS GERAIS
  public get codigoDiretoria() {
    return this.formCadastrar.get('codigoDiretoria')!;
  }
  public get codigoEscola() {
    return this.formCadastrar.get('codigoEscola')!;
  }
  public get codigoTurno() {
    return this.formCadastrar.get('codigoTurno')!;
  }
  public get codigoGravidade() {
    return this.formCadastrar.get('codigoGravidade')!;
  }
  public get dataChamado() {
    return this.formCadastrar.get('dataChamado')!;
  }
  public get codigoSubCategoria() {
    return this.formCadastrar.get('codigoSubCategoria')!;
  }
  public get dsChamado() {
    return this.formCadastrar.get('dsChamado')!;
  }
  public get arquivos() {
    return this.formCadastrar.get('arquivos')!;
  }
  public get codigoDiretoriaModal() {
    return this.formModal.get('codigoDiretoriaModal')!;
  }
  public get codigoEscolaModal() {
    return this.formModal.get('codigoEscolaModal')!;
  }

  public async ngOnInit() {
    this.formCadastrar = new FormGroup({
      codigoDiretoria: new FormControl(null, [Validators.required]),
      codigoEscola: new FormControl(null, [Validators.required]),
      dataChamado: new FormControl(null, [Validators.required]),
      codigoGravidade: new FormControl(null, [Validators.required]),
      codigoSubCategoria: new FormControl(null, [Validators.required]),
      dsChamado: new FormControl(null),
    });
    // await this.getPermissaoCadastrarOcorrencia();
    this.spinnerService.show();
    this.setDefaultValuesInCategoryAndGravity();

    if(this.atribuicoesLogin.NumeroComportamento == 1){
      this.getDiretorias();
    }

    if(this.atribuicoesLogin.NumeroComportamento == 2){
      this.getDiretoriasPerfilDiretoria();
    }

    if(this.atribuicoesLogin.NumeroComportamento == 3 || this.atribuicoesLogin.NumeroComportamento == 4){
      this.getDiretoriasPerfilEscola();
    }

    await this.buscaNaoOcorrenciaDiaria();
  }


  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.spinnerService.hide();
    }}

    removeUniqueId(fileName: string): string {
      const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}-/;
      return fileName.replace(regex, '');
    }



  public getDiretoriasPerfilDiretoria() {
    this.diretorias = [];
    this.escolas = [];
    this.userLogado?.DadosUsuario?.map((item: AtribuicaoLoginSed) => {
      let diretoria = new Diretoria();
      diretoria.codigoDiretoria = item.CodigoDiretoria;
      diretoria.nomeDiretoria = item.NomeDiretoria;
      this.diretorias.push(diretoria);
    });

    if(this.diretorias.length == 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();
      this.getEscolas(true);
    }else {
      this.ativaDiretoria();
      this.getEscolas(true);
    }
  }

  public getDiretoriasPerfilEscola() {

    this.diretorias = [];
    this.escolas = [];

    this.userLogado?.DadosUsuario?.map((item: AtribuicaoLoginSed) => {
      let diretoria = new Diretoria();
      diretoria.codigoDiretoria = item.CodigoDiretoria;
      diretoria.nomeDiretoria = item.NomeDiretoria;
      this.diretorias.push(diretoria);

      let escola = new Escola();
      escola.codigoEscola = item.CodigoEscola;
      escola.nomeEscola = item.NomeEscola;
      escola.codigoDiretoria = item.CodigoDiretoria;
      this.escolas.push(escola);
    });

    if(this.diretorias.length == 1 && this.escolas.length == 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();

      this.form.codigoEscola.setValue(this.escolas[0].codigoEscola);
      this.form.codigoEscola.disable();

      this.getTurnos();
    }

    if(this.diretorias.length == 1 && this.escolas.length > 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();
    }
    this.ativaDiretoria();
    this.ativaEscola();
  }

  public ativaDiretoria(){
    if(this.userLogado?.codigoDiretoria != undefined)
      this.formCadastrar.get('codigoDiretoria')?.setValue(this.userLogado?.codigoDiretoria);
    if(this.userLogado !== null)
      this.authService.setUser(this.userLogado);
  }

  public ativaEscola(){
    if(this.userLogado?.codigoEscola != undefined)
      this.formCadastrar.get('codigoEscola')?.setValue(this.userLogado?.codigoEscola);
    if(this.userLogado?.codigoDiretoria != undefined && this.userLogado?.codigoEscola != undefined)
      this.getTurnos();
    if(this.userLogado !== null)
      this.authService.setUser(this.userLogado);
  }

  public async getDiretorias() {
    this.spinnerService.show();
    const result = await lastValueFrom(await this.escolaService.getDiretoriaPorTipoDiretoria())
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.diretorias = result.data;
    this.spinnerService.hide();
    if(this.userLogado?.codigoDiretoria != undefined){
      this.ativaDiretoria();
      this.getEscolas(true);
    }
  }


  public async getEscolas(diretoriaLogada:boolean = false) {
    const codigoDE = this.userLogado?.codigoDiretoria != undefined && diretoriaLogada ? this.userLogado?.codigoDiretoria : this.formCadastrar.get('codigoDiretoria')?.value;
    if(this.atribuicoesLogin.NumeroComportamento == 1 || this.atribuicoesLogin.NumeroComportamento == 2){
      this.zerarEscola();
      if (codigoDE != null) {
        this.spinnerService.show();
        const result = await lastValueFrom(await this.escolaService.getEscolasPorCodigoDiretoria(codigoDE))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });
        if(!result.isSucess){
          this.toastr.error(result.message, result.title);
          this.spinnerService.hide();
          return;
        }
        this.escolas = result.data;
        this.spinnerService.hide();
        this.ativaEscola();
      }
    }
    if(this.userLogado !== null)
    {
      this.userLogado.codigoDiretoria = codigoDE == null ? 0 : codigoDE;
      this.authService.setUser(this.userLogado);
    }
  }

  public async getEscolasModal() {
    this.spinnerService.show();
    this.zerarEscolaModal();
    const codigoDE = this.formModal.get('codigoDiretoriaModal');
    if (codigoDE?.value != null) {
      const result = await lastValueFrom(await this.escolaService.getEscolasPorCodigoDiretoria(codigoDE?.value))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!result.isSucess){
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }
      this.escolasModal = result.data;
      this.spinnerService.hide();
    }else{
      this.escolasModal = [];
      this.spinnerService.hide();
    }
  }

  public zerarEscola() {
    this.escolas = [];
    this.formCadastrar.get('codigoEscola')?.patchValue(null);
    this.zerarTurnos();
  }

  public zerarEscolaModal() {
    this.escolasModal = [];
    this.formModal.get('codigoEscolaModal')?.patchValue(null);
  }

  public async getTurnos() {
    const codigoEE = this.formCadastrar.get('codigoEscola');
    this.zerarTurnos();

    if (codigoEE?.value != null) {
      this.spinnerService.show();
      const result = await lastValueFrom(await this.escolaService.getTurnosPorCodigoCie(codigoEE?.value))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
      if(!result.isSucess){
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }
      this.turnos = result.data;
      if (this.turnos == null)
        this.toastr.warning('Não foi encontrados turnos para essa escola !');
      this.spinnerService.hide();
    }
    if(this.userLogado !== null)
    {
      this.userLogado.codigoEscola = codigoEE?.value == null ? 0 : codigoEE?.value;
      this.authService.setUser(this.userLogado);
    }
  }

  public zerarTurnos() {
    this.turnos = [];
    this.formCadastrar.get('codigoTurno')?.patchValue(null);
  }






  public async OnTipoPessoaChange(event: any) {
    this.RemoverValidacoesModal();
    this.submittedModal = false;
    this.validaBuscaRA = false;
    this.showDropAuxiliar = false;
    if (event == undefined) {
      this.showBuscaAluno = false;
      this.showBuscaEquipe = false;
      this.showOutros = false;
      return;
    }
    const tipoPessoa = event.id;
    if (tipoPessoa == 1) {
      this.formModal.get('raNomeAluno')?.addValidators([Validators.required]);
      this.formModal.get('raAlunoModalDrop')?.addValidators([Validators.required]);
      this.showBuscaAluno = true;
    } else {
      this.showBuscaAluno = false;
    }
    if (tipoPessoa == 2) {
      this.formModal.get('cpfFuncionario')?.addValidators([Validators.required]);
      this.formModal.get('cpfFuncionarioModalDrop')?.addValidators([Validators.required]);
      this.showBuscaEquipe = true;
    } else {
      this.showBuscaEquipe = false;
    }
    if (tipoPessoa == 3) {
      this.formModal.get('dsOutros')?.addValidators([Validators.required]);
      this.showOutros = true;
    } else {
      this.showOutros = false;
    }
    this.formModal.get('raNomeAluno')?.updateValueAndValidity();
    this.formModal.get('raAlunoModalDrop')?.updateValueAndValidity();
    this.formModal.get('cpfFuncionario')?.updateValueAndValidity();
    this.formModal.get('cpfFuncionarioModalDrop')?.updateValueAndValidity();
    this.formModal.get('dsOutros')?.updateValueAndValidity();
  }

  public onLimpar() {
    this.form.dataChamado.setValue(null);
    this.form.dsChamado.setValue(null);
    this.qtdeCaracteresDescricao = 0;

  }

  public async OnSalvar() {
    this.submitted = true;
    if(this.NaoOcorrenciaCadastradaHoje){
      this.toastr.warning("Não Ocorrência já cadastrada hoje.");
      this.submitted = false;
      return
    }

    if(this.formCadastrar.get('dataChamado')?.value == null) {
      this.toastr.error('Informe uma data e hora.');
      return;
    }

    var descricao = this.formCadastrar.get('dsChamado')!.value;
    if (descricao == null){
      descricao = 'Nao houve chamados para o periodo selecionado';
    }
      

    if (this.formCadastrar.invalid) {
      return;
    }
  // if(!this.permissaoParaCadastrarNaoOcorrencia){
  //   this.toastr.error("Não foi possivel cadastrar essa não ocorrência. Entre em contato com o Admin.");
  //   return;
  // }


    this.spinnerService.show();
    const dir = this.diretorias.filter(r => r.codigoDiretoria == this.formCadastrar.get('codigoDiretoria')?.value)[0];
    const esc = this.escolas.filter(r => r.codigoEscola == this.formCadastrar.get('codigoEscola')?.value)[0];
    const objetoChamado: NaoOcorrencia = {
      cdGravidade: this.formCadastrar.get('codigoGravidade')?.value,
      cdCategoria: 13,
      cdSubCategoria: this.formCadastrar.get('codigoSubCategoria')?.value,
      dtNaoOcorrencia: this.formCadastrar.get('dataChamado')?.value,
      cdDiretoria: dir.codigoDiretoria,
      nmDiretoria: dir.nomeDiretoria,
      cdEscola: esc.codigoEscola,
      nmEscola: esc.nomeEscola,
      dsNaoOcorrencia: descricao,
      cdTurno: 0,
      codigoUsuario: this.atribuicoesLogin.CodigoUsuario,
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil,
      cdNaoOcorrencia: 0
    };

    var dataAtual: Date = new Date();
    var dataSelecionada: Date = new Date(objetoChamado.dtNaoOcorrencia);
    if(dataSelecionada > dataAtual)
    {
      this.toastr.error('A data da ocorrência não pode ser maior que a data de hoje.');
      this.spinnerService.hide();
      return;
    }

    // let date = new Date();
    // date.setDate(date.getDate() - 7);
    // if( dataSelecionada  < date)
    // {
    //   this.toastr.error('A data da ocorrência deve constar em até 7 dias depois do acontecimento.');
    //   this.spinnerService.hide();
    //   return;
    // }


    const result = await lastValueFrom(await this.naoOcorrenciaService.setCadastrarNaoOcorrencia(objetoChamado))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.spinnerService.hide();
    this.toastr.success('Não Ocorrência salva com sucesso');
    setTimeout(() => {
      this.submitted = false;
      document.getElementById("btnLimpar")?.click();
      window.location.reload();
    }, 2000);
  }

  public AbrirModal() {
    this.submittedModal = false;

    this.formModal = new FormGroup({
      tipoPessoa: new FormControl(null, [Validators.required]),
      tipoEnvolvido: new FormControl(null),
      raNomeAluno: new FormControl(null, [Validators.required]),
      cpfFuncionario: new FormControl(null, [
        Validators.required,
        this.genericValidate.ValidaCpf,
      ]),
      dsOutros: new FormControl(null, [Validators.required]),
      codigoDiretoriaModal: new FormControl(null, [Validators.required]),
      codigoEscolaModal: new FormControl(null, [Validators.required]),
      nomeEscola: new FormControl(null),
      raAlunoModalDrop: new FormControl(null, [Validators.required]),
      cpfFuncionarioModalDrop: new FormControl(null, [Validators.required]),
    });

    this.showModal = true;
    this.tituloModal = 'Dados do envolvido';
  }

  public FecharModal() {
    this.showModal = false;
    this.showBuscaAluno = false;
    this.showBuscaEquipe = false;
    this.showOutros = false;
  }




  public RemoverValidacoesModal() {
    this.validaBuscaRA = false;
    if (this.formModal != undefined)
    {
      this.formModal.get('codigoDiretoriaModal')?.removeValidators([Validators.required]);
      this.formModal.get('codigoEscolaModal')?.removeValidators([Validators.required]);
      this.formModal.get('raNomeAluno')?.removeValidators([Validators.required]);
      this.formModal.get('cpfFuncionario')?.removeValidators([Validators.required]);
      this.formModal.get('dsOutros')?.removeValidators([Validators.required]);
      this.formModal.get('raAlunoModalDrop')?.removeValidators([Validators.required]);
      this.formModal.get('cpfFuncionarioModalDrop')?.removeValidators([Validators.required]);

      this.formModal.get('codigoDiretoriaModal')?.updateValueAndValidity();
      this.formModal.get('codigoEscolaModal')?.updateValueAndValidity();
      this.formModal.get('raNomeAluno')?.updateValueAndValidity();
      this.formModal.get('cpfFuncionario')?.updateValueAndValidity();
      this.formModal.get('dsOutros')?.updateValueAndValidity();
      this.formModal.get('raAlunoModalDrop')?.updateValueAndValidity();
      this.formModal.get('cpfFuncionarioModalDrop')?.updateValueAndValidity();
    }
  }

  public AtualizarValidacoesAlunos() {
    this.formModal.get('codigoDiretoriaModal')?.updateValueAndValidity();
    this.formModal.get('codigoEscolaModal')?.updateValueAndValidity();
    this.formModal.get('raNomeAluno')?.updateValueAndValidity();
    this.formModal.get('raAlunoModalDrop')?.updateValueAndValidity();
  }

  public async GetFuncionariosPorCpf() {
    const cpf = this.formModal.get('cpfFuncionario')?.value;
    if(!this.validarCPF(cpf)){
      this.toastr.warning('CPF invalido.');
      this.spinnerService.hide();
      return;
    }

    this.showDropAuxiliar = false;
    this.spinnerService.show();
    const getCPF = this.formModal.get('cpfFuncionario');
    const result = await lastValueFrom(await this.funcionarioService.getFuncionariosPorCpf(getCPF?.value.replace(".","").replace("-","")))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    if (result.data != null) {
      this.funcionarios = result.data;
    }
    if (this.funcionarios.length == 0) {
      this.toastr.warning('Nenhum funcionario encontrado!');
    } else {
      if (this.funcionarios.length == 1) {
        this.formModal
          .get('cpfFuncionarioModalDrop')
          ?.patchValue(this.funcionarios[0].cpf);
      }
    }
    this.showDropAuxiliar = true;
    this.spinnerService.hide();
  }

  private RetornoBuscaAlunos(response: DefaultResponse) {
    if (response.data != null) {
      this.alunos = response.data;
    }
    if (this.alunos.length == 0) {
      this.toastr.warning('Nenhum aluno encontrado!');
    }
    if (this.alunos.length == 1) {
      this.formModal.get('raAlunoModalDrop')?.patchValue(this.alunos[0].ra);
    }
    if (this.alunos.length > 1) {
      this.toastr.success('Alunos carregados com sucesso. Por favor selecione um.');
    }
    this.showDropAuxiliar = true;
  }

  private RetornoBuscaAlunoPorRA(response: DefaultResponse) {
    if (response.data != null) {
      this.aluno = response.data;
      this.formModal.get('raAlunoModalDrop')?.patchValue(this.aluno.ra);
      this.alunos.push(this.aluno);
      this.showDropAuxiliar = true;
      this.toastr.success('Aluno carregado com sucesso.');
      return;
    }
    this.toastr.warning('Nenhum aluno encontrado!');
  }

  public ExluirEnvolvido(IdTemporario?: number) {
    const indiceParaRemover = this.envolvidos.findIndex(
      (envolvido) => envolvido.idTemporario === IdTemporario
    );
    this.envolvidos.splice(indiceParaRemover, 1);
    this.toastr.success(
      'Envolvido removido com sucesso!'
    );

    this.FecharModalExclusao();
  }

  public OnExcluirEnvolvido(IdTemporario: number) {
    this.showModalExcluirAluno = true;
    this.envolvidoExclusao = this.envolvidos.filter(
      (envolvido) => envolvido.idTemporario === IdTemporario
    )[0];
    this.descricaoExclusao = `Deseja remover o Envolvido ${this.envolvidoExclusao.nome == undefined  ? "Outros" : this.envolvidoExclusao.nome} ?`;
  }

  public FecharModalExclusao() {
    this.showModalExcluirAluno = false;
    this.descricaoExclusao = "";
    this.envolvidoExclusao = undefined;
  }
  validarCPF(cpf: string): boolean {
    cpf = cpf.replace(/[^\d]/g, '');

    if (cpf.length !== 11) {
      return false;
    }

    if (/^(\d)\1+$/.test(cpf)) {
      return false;
    }

    let sum = 0;
    let remainder;

    for (let i = 1; i <= 9; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }

    if (remainder !== parseInt(cpf.substring(9, 10))) {
      return false;
    }

    sum = 0;

    for (let i = 1; i <= 10; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }

    return remainder === parseInt(cpf.substring(10, 11));
  }
  OnChangeCountLetters(){
    const descricao = this.formCadastrar.get('dsChamado')!.value;
    if(descricao != null)
      this.qtdeCaracteresDescricao = descricao.length;
  }

  setDefaultValuesInCategoryAndGravity(){
    this.codigoGravidade.setValue(0);
    this.codigoSubCategoria.setValue(42);
    this.codigoGravidade.disable();
    this.codigoSubCategoria.disable();
  }
  private async getPermissaoCadastrarOcorrencia(){
    const objetoEnvio = {
      cdDiretoria:this.userLogado?.codigoDiretoria,
      cdEscola:this.userLogado?.codigoEscola
    };
    var response = await lastValueFrom(this.naoOcorrenciaService.getPermissaoGravarNaoOcorrencia(objetoEnvio));
    if(!response.isSucess){
      this.toastr.error("Houve um erro na solicitação.");
    }
    this.permissaoParaCadastrarNaoOcorrencia = response.data.sucesso;

  }

private async buscaNaoOcorrenciaDiaria(){
  var parametros = {
     codigoDiretoria: this.userLogado?.codigoDiretoria,
     codigoEscola:this.userLogado?.codigoEscola
  };
  var response = await lastValueFrom(this.naoOcorrenciaService.getExisteNaoOcorrenciaHoje(parametros)).catch((err) => {
    return this.ConvivaErrors.handleResultError(err);
  });
  if(!response.isSucess){
    this.toastr.error(response.message, response.title);
    this.spinnerService.hide();
    return;
  }
  this.NaoOcorrenciaCadastradaHoje = response.data.sucesso;

}

public async DesativaNaoOcorrencia(){
  this.spinnerService.show();
  var parametros = {
    codigoDiretoria: this.userLogado?.codigoDiretoria,
    codigoEscola:this.userLogado?.codigoEscola
 };
 var response = await lastValueFrom(this.naoOcorrenciaService.setDesativaNaoOcorrencia(parametros))
 .catch((err) => {
   return this.ConvivaErrors.handleResultError(err);
 });


 if(!response.isSucess){
   this.toastr.error(response.message, response.title);
   this.spinnerService.hide();
   return;
 }
 this.spinnerService.hide();
 if(response.data.sucesso){
   this.toastr.success("Cadastro de Não Ocorrência e Ocorrências liberado com sucesso.");
   setTimeout(() => {
     this.submitted = false;
     document.getElementById("btnLimpar")?.click();
     window.location.reload();
   }, 2000);

 }else{
  this.toastr.error(response.data.mensagem);
 }

}
}
