import { Component, EventEmitter, inject, Input, Output, signal } from '@angular/core'; // Adicione Signal e signal
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { PsicologoService } from 'src/app/shared/services/psicologo.service';


interface horasDoDia {
  horario:string,
  disponivel:boolean
}
@Component({
  selector: 'modal-calendario',
  templateUrl: './modal-calendario.component.html',
  styleUrls: ['./modal-calendario.component.scss']
})
export class ModalCalendarioComponent {
  @Output() valueChange = new EventEmitter<Date>();
  @Input() duracaoVisita = 15;
  @Input() codigoEscola = 0;
  @Input() codigoDiretoria = 0;

  toastr = inject(ToastrService);
  spinner = inject(NgxSpinnerService);
  psicologoService = inject(PsicologoService);

  isOpen = false; // Controla a visibilidade do modal
  titulo = 'Agendar horário';
  subtitulo = 'Selecione uma data e horário para o agendamento';

  dataAtualSignal = signal(new Date()); // Adicionamos essa Signal

  dataAtual = new Date(); // Data atual
  diasComAgendamento: Date[] = []; // Dias com agendamento
  diasDoMes: {
    dia: number | null;
    diaSemana?: string;
    isFimDeSemana?: boolean;
    isDiaAtual?: boolean;
    classes: { [key: string]: boolean };
    disponivelAgendamento: boolean;
  }[] = [];
  diaSelecionado: number | null = null; // Dia selecionado
  horariosDisponiveis: horasDoDia[] = []; // Lista de horários disponíveis
  horarioSelecionado: string | null = null; // Horário selecionado
  textoBotao: string = "Abrir Calendário"; // Horário selecionado

  syncDataAtualWithSignal(): void {
    this.dataAtualSignal.update(() => new Date(this.dataAtual));
  }

  // Abrir o modal
  async abrirModalComponentCalendario(): Promise<void> {
    this.isOpen = true;
    await this.atualizarDiasDoMes();
  }

  // Fechar o modal
  closeModal(): void {
    this.isOpen = false;
    this.resetarSelecoes();
  }

  // Resetar seleções ao fechar o modal
  private resetarSelecoes(): void {
    this.diaSelecionado = null;
    this.horarioSelecionado = null;
    this.horariosDisponiveis = [];
  }

  async atualizarDiasDoMes(): Promise<void> {

    const ano = this.dataAtual.getFullYear();
    const mes = this.dataAtual.getMonth();
    const ultimoDia = new Date(ano, mes + 1, 0).getDate();
    const primeiroDia = new Date(ano, mes, 1).getDay(); // Calcula o primeiro dia do mês
    await this.getAgendamentosExistentes();

    const diasSemana = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

    const hoje = new Date();

    // Preenche o calendário com os dias corretos
    this.diasDoMes = [];

    // Adiciona dias vazios para o primeiro dia do mês (ajustando o índice do primeiro dia)
    for (let i = 0; i < primeiroDia; i++) {
      this.diasDoMes.push({ dia: null, classes: {} ,disponivelAgendamento:false});
    }

    // Adiciona os dias do mês
    for (let i = 1; i <= ultimoDia; i++) {
      const data = new Date(ano, mes, i);
      const diaSemana = diasSemana[data.getDay()];
      const isFimDeSemana = diaSemana === 'Sáb' || diaSemana === 'Dom';
      const isDiaAtual = i === hoje.getDate() && mes === hoje.getMonth() && ano === hoje.getFullYear();

      this.diasDoMes.push({
        dia: i,
        diaSemana,
        isFimDeSemana,
        isDiaAtual,
        classes: {
          'dia-calendario': true,
          'dia-indisponivel': isFimDeSemana,
          'dia-atual': isDiaAtual,
        },
        disponivelAgendamento:false
      });
    }
  }



  // Avançar para o próximo mês
  // async nextMonth(): Promise<void> {
  //   this.dataAtual.setMonth(this.dataAtual.getMonth() + 1);  // Muda para o próximo mês
  //   await this.atualizarDiasDoMes();  // Atualiza os dias do mês após a mudança
  // }
  async nextMonth(): Promise<void> {
    this.dataAtual.setMonth(this.dataAtual.getMonth() + 1); // Modifica o Date original
    this.syncDataAtualWithSignal(); // Atualiza o Signal
    await this.atualizarDiasDoMes(); // Atualiza o calendário
  }


  // Voltar para o mês anterior
  // async previousMonth(): Promise<void> {
  //   this.dataAtual.setMonth(this.dataAtual.getMonth() - 1);  // Muda para o mês anterior
  //   await this.atualizarDiasDoMes();  // Atualiza os dias do mês após a mudança
  // }

  async previousMonth(): Promise<void> {
    this.dataAtual.setMonth(this.dataAtual.getMonth() - 1); // Modifica o Date original
    this.syncDataAtualWithSignal(); // Atualiza o Signal
    await this.atualizarDiasDoMes(); // Atualiza o calendário
  }


  // Selecionar um dia
  selectDate(day: number): void {
    this.diaSelecionado = day;
    this.gerarHorariosDisponiveis();
  }

  // Gerar horários disponíveis para o dia selecionado
  gerarHorariosDisponiveis(): void {
    const horariosAgendados = this.diasComAgendamento.filter(dia =>{
      return new Date(dia).getDate() === this.diaSelecionado
    });
    const horariosDisponiveis: horasDoDia[] = [];
    const horaInicio = 7 * 60; // 7:00 em minutos
    const horaFim = 18 * 60; // 18:00 em minutos
    const duracao = Number(15);
    for (let minutoAtual = horaInicio; minutoAtual <= horaFim; minutoAtual += duracao) {
      const horas = Math.floor(minutoAtual / 60);
      const minutos = minutoAtual % 60;
      const horarioString = `${horas.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}`;
      const horarioJaAgendado = horariosAgendados.some(agendado => {
        const dataAgendada = new Date(agendado);
        const horasAgendadas = dataAgendada.getHours();
        const minutosAgendados = dataAgendada.getMinutes();
        const horarioAgendadoString = `${horasAgendadas.toString().padStart(2, '0')}:${minutosAgendados.toString().padStart(2, '0')}`;

        return horarioAgendadoString === horarioString;
      });


      const horarioObjeto: horasDoDia = {
        horario:`${horas.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}`,
        disponivel:!horarioJaAgendado
      };

      horariosDisponiveis.push(horarioObjeto);
    }
    this.horariosDisponiveis = horariosDisponiveis;
    this.horarioSelecionado = null; // Resetar horário selecionado
  }

  // Selecionar um horário
  selectTime(time: string): void {
    this.horarioSelecionado = time;
  }

  // Agendar (executa a lógica de agendamento)
  agendar(): void {
    if (this.diaSelecionado && this.horarioSelecionado) {
      const splitHour = this.horarioSelecionado.split(':');
      const dataSelecionada = new Date(
        this.dataAtual.getFullYear(),
        this.dataAtual.getMonth(),
        this.diaSelecionado,
        Number(splitHour[0]),
        Number(splitHour[1])
      );
      this.toastr.success(`Data selecionada para ${dataSelecionada.toLocaleDateString()} às ${this.horarioSelecionado}`);

      this.valueChange.emit(dataSelecionada);
      this.textoBotao = dataSelecionada.toLocaleDateString() + ' ' + this.horarioSelecionado;
      this.closeModal(); // Fechar o modal após agendar
    }
  }

  async getAgendamentosExistentes() {
    this.spinner.show();
    var response = await lastValueFrom(this.psicologoService.getDatasAgendamentosPrevencao(
      this.codigoEscola,
      this.codigoDiretoria,
      this.dataAtual.getMonth() + 1,
      this.dataAtual.getFullYear())
    )

      console.log(response.data)
    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinner.hide();
      return;
    }
    this.diasComAgendamento = response.data.map((agendamento:any) => agendamento.dataAgendamento);
    this.spinner.hide();
  }

}
