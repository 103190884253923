<form [formGroup]="formPesquisarEmails" (submit)="OnSubmit()">
  <!-- [formGroup]="formDashboard" (submit)="PesquisarVisitas()" -->
  <!-- [formGroup]="formGerenciamentoEmail" (submit)="PesquisarGerenciamentoEmail()" -->
  <breadcrumb
    [paginas]="[
      { route: '/home', name: 'Home' },
      { route: '/gerenciamento-email', name: 'Gerenciamento de E-mails' }
    ]"
  />
  <!-- FILTRO ESCOLA -->
  <section class="section-filtro">
    <div class="container">
      <div class="grupo-filter">
        <p><b>Polo</b></p>
        <ng-select
          [items]="polos"
          bindLabel="descricaoPolo"
          bindValue="codigoPolo"
          placeholder="Selecione um Polo"
          name="codigoPolo"
          formControlName="codigoPolo"
          (change)="OnChangePolo()"
          [ngClass]="{ 'is-invalid': submitted && form.codigoPolo.errors }"
        >
        </ng-select>

        <div *ngIf="submitted && form.codigoPolo.errors" class="alert">
          <div *ngIf="form.codigoPolo.errors.required">
            * Polo é obrigatório
          </div>
        </div>
      </div>

      <div class="grupo-filter">
        <p><b>Diretoria</b></p>
        <ng-select
          [items]="diretorias"
          bindLabel="nomeDiretoria"
          bindValue="codigoDiretoria"
          placeholder="Selecione uma Diretoria"
          formControlName="codigoDiretoria"
          name="codigoDiretoria"
          (change)="trocaDiretoria()"
          [ngClass]="{ 'is-invalid': submitted && form.codigoDiretoria.errors }"
        >
        </ng-select>

        <div *ngIf="submitted && form.codigoDiretoria.errors" class="alert">
          <div *ngIf="form.codigoDiretoria.errors.required">
            * Diretoria é obrigatório
          </div>
        </div>
      </div>

      <div class="grupo-filter">
        <p><b>Escola</b></p>
        <ng-select
          [items]="escolas"
          bindLabel="nomeEscola"
          bindValue="codigoEscola"
          placeholder="Selecione uma Escola"
          formControlName="codigoEscola"
          name="codigoEscola"
          (change)="onChangeEscola()"
          [ngClass]="{ 'is-invalid': submitted && form.codigoEscola.errors }"
        >
        </ng-select>

        <div *ngIf="submitted && form.codigoEscola.errors" class="alert">
          <div *ngIf="form.codigoEscola.errors.required">
            * Escola é obrigatório
          </div>
        </div>
      </div>

      <button type="submit" class="btn-search-filtro" id="btn-search-chamado">
        <img
          src="../../../../assets/img/icons/icon-search.svg"
          alt="Pesquisar Chamados"
          title="Pesquisar Chamados"
        />
      </button>
    </div>
  </section>
  <!-- END FILTRO ESCOLA -->

  <!-- TITULO -->
  <section class="section-saudacao header-page-voltar">
    <div class="container">
      <h2 class="titulo-page">Gerenciamento de envio de e-mail</h2>

      <div class="periodo">
        <p><b>Pesquisar por período:</b></p>
        <div class="periodo-input">
          <input type="date" name="dataInicio" formControlName="dataInicio" />
          &nbsp;-&nbsp;
          <input type="date" name="dataFim" formControlName="dataFim" />
        </div>
      </div>
    </div>
  </section>
  <!-- END TITULO -->

  <section class="section-tab">
    <div class="container">
      <div class="legenda-tab">
        <p>Selecione a tab para verificar cada (SLA)</p>
      </div>
      <div class="tabs">

        <label class="tab" [class.active]="this.form.tabAtiva.value == '1'" (click)="onClickChangeTab('1')">Chamados</label>
        <label class="tab" [class.active]="this.form.tabAtiva.value == '2'"  (click)="onClickChangeTab('2')">Atendimentos</label>
        <label class="tab" [class.active]="this.form.tabAtiva.value == '3'"  (click)="onClickChangeTab('3')">Agendamentos</label>

        <div class="tab-content-wrapper">
          @if(this.form.tabAtiva.value =='1'){
          <!-- @if(this.form.tabAtiva.value == '1'){ -->
          <div id="tab-content-1" class="tab-content">
            <h3 class="sub-titulo-dash">Listagem de E-mail</h3>
            <app-tabulator-table
              [tipoRelatorio]="2"
              [orientacaoPortrait]="false"
              [columnNames]="colunasGrid"
              [tableData]="emails"
              id="data-table-1"
            ></app-tabulator-table>
          </div>
          }

          @if(this.form.tabAtiva.value == '2'){
          <div id="tab-content-2" class="tab-content">
            <h3 class="sub-titulo-dash">Listagem de E-mail</h3>
            <app-tabulator-table
              [tipoRelatorio]="2"
              [orientacaoPortrait]="false"
              [columnNames]="colunasGrid"
              [tableData]="emails"
              id="data-table-2"
            ></app-tabulator-table>
          </div>
          }

          @if(this.form.tabAtiva.value == '3'){
            <div id="tab-content-3" class="tab-content">
              <h3 class="sub-titulo-dash">Listagem de E-mail</h3>
              <app-tabulator-table
                [tipoRelatorio]="2"
                [orientacaoPortrait]="false"
                [columnNames]="colunasGrid"
                [tableData]="emails"
                id="data-table-3"
              ></app-tabulator-table>
            </div>

          }


        </div>
      </div>
    </div>
  </section>
</form>

<app-modal *ngIf="showModalInfoEmail">
  <div class="conteudo-modal-info-email">
    <div class="header-modal">
      <h1 class="title-ocorrencia">Destinatário</h1>
      <a class="btn-fechar-modal" (click)="closeModalInfoEmail()">fechar</a>
    </div>
    <hr />
    <div class="descricao-modal-agendamento">
      <p>
        <i class="fa-solid fa-envelope"></i><strong>E-mail: &nbsp;</strong>
        {{ selectedEmail.emailDestinatario }}
      </p>
      <p>
        <i class="fa-solid fa-envelope"></i><strong>Cargo: &nbsp;</strong>
        {{ selectedEmail.nomePerfil }}
      </p>
      <p>
        <i class="fa-solid fa-graduation-cap"></i
        ><strong>Polo: &nbsp;</strong> POLO {{ selectedEmail.codigoPolo }}
      </p>
      <p>
        <i class="fa-solid fa-graduation-cap"></i
        ><strong>Diretoria: &nbsp;</strong> {{ selectedEmail.nomeDiretoria }}
      </p>
      <p>
        <i class="fa-solid fa-graduation-cap"></i
        ><strong>Escola: &nbsp;</strong> {{ selectedEmail.nomeEscola }}
      </p>
    </div>
    <hr />
    <div class="descricao-modal-agendamento">
      <p>
        <i class="fa-solid fa-envelope"></i
        ><strong> Titulo do e-mail (SLA): &nbsp;</strong> Atrasado agendamento
        {{ selectedEmail.horaAtraso }}hrs
      </p>
      <p>
        <i class="fa-solid fa-envelope"></i
        ><strong> Data de envio: &nbsp;</strong>
        {{ formataDataPTBR(selectedEmail.dataInclusao) }}
      </p>
      <p class="info-modal-chamados">
        <span class="label-chamado">
          <i class="fa-solid fa-envelope"></i><strong> Chamados: &nbsp;</strong>
        </span>
        <span class="chamados-lote">
          {{ selectedEmail.codigosChamados }}
        </span>
      </p>
    </div>
    <div class="container-btns">
      <input
        type="button"
        (click)="closeModalInfoEmail()"
        class="btn_outline"
        value="OK"
      />
    </div>
  </div>
</app-modal>
