import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { ConvivaApi } from "src/app/conviva-api";
import { DefaultResponse } from "../models/DefaultResponse";
import { FiltroPadrao } from "../models/filtro-padrao/filtroPadrao";



@Injectable({
  providedIn: 'root'
})

export class EmailService {
  private api = ConvivaApi;

  constructor(
    private http: HttpClient) { }


  public getEmailsChamadosAtrasados(filters: FiltroPadrao): Observable<DefaultResponse> {
    return this.http.post<DefaultResponse>(`${this.api.Email.getEmailsChamadosAtrasados}`, filters)
      .pipe(map((response: DefaultResponse) => response));
  }

  public getEmailsAgendamentosAtrasados(filters: FiltroPadrao): Observable<DefaultResponse> {
    return this.http.post<DefaultResponse>(`${this.api.Email.getEmailsAgendamentosAtrasados}`, filters)
      .pipe(map((response: DefaultResponse) => response));
  }

  public getEmailsAtendimentosAtrasados(filters:FiltroPadrao):Observable<DefaultResponse>{
    return this.http.post<DefaultResponse>(`${this.api.Email.getEmailsAtendimentosAtrasados}`, filters)
                    .pipe(map((response: DefaultResponse) => response));
}
}
