import { Component, HostListener } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { OAuthService } from "angular-oauth2-oidc";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom } from "rxjs";
import { authConfig } from "src/app/shared/auth/models/auth.config";
import { AuthService } from "src/app/shared/auth/services/auth.service";
import { ListarChamados } from "src/app/shared/models/chamados/listar-chamados";
import { ListarEncaminhados } from "src/app/shared/models/chamados/listar-encaminhados";
import { DefaultResponse } from "src/app/shared/models/DefaultResponse";
import { AtribuicaoLoginSed, UserLoginSed } from "src/app/shared/models/sed/UserLogin";
import { AgendamentoService } from "src/app/shared/services/agendamento.service";
import { ConvivaErrorHandlingService } from 'src/app/shared/services/convivaErrorHandlingService';
import { NotificacoesService } from "src/app/shared/services/notificacoes.service";
import { PerfilService } from "src/app/shared/services/perfil.service";
import { environment } from "src/environments/environment";
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})

export class HeaderComponent {
  private activeSubMenus: { [key: string]: boolean } = {};

  @HostListener('window:click', ['$event'])
  click(event: MouseEvent) {
    var divClicada = event.target as HTMLElement;
    // const menu = document.getElementsByClassName("sidebar-menu").item(0) as HTMLElement;
    const notificacoes = document.getElementsByClassName("sidebar-notificacao").item(0) as HTMLElement;
    const bodyoverlay = document.getElementsByTagName("body").item(0) as HTMLElement;
    if(
      // !divClicada.classList.contains('sidebar-menu') &&
    !divClicada.classList.contains('sidebar-notificacao') &&
    // divClicada.id != "btn-abrir-menu" &&
    divClicada.id != "sino-notificacao"){
      // menu.className = "sidebar-menu";
      if(this.userLogado?.funcionalidades.includes(16)){
        // notificacoes.className = "sidebar-notificacao";
      }
      bodyoverlay.className = "";
      this.expandir = !this.expandir;
    }
  }


  public expandir: boolean = false;
  public versao: string = "";
  public userLogado: UserLoginSed | null;
  public atribuicoesLogin: AtribuicaoLoginSed;
  public showModalAgendamento: boolean = false;
  public showModalAgendamentoPsicologo: boolean = false;
  public quantidadeAgendamentosAbertos: string;
  public showModalPerfil:boolean ;


  constructor(
    private oauthService: OAuthService,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private spinnerService: NgxSpinnerService,
    private perfilService: PerfilService,
    private ConvivaErrors: ConvivaErrorHandlingService,
    private notificacoesService: NotificacoesService,
    private agendamentoService:AgendamentoService
  )
    {
    this.configure();
    this.userLogado = this.authService.getUser();

    if(this.userLogado != null && !this.userLogado.flPsicologo){
      this.atribuicoesLogin =  this.userLogado?.DadosUsuario[0];
    }
  }

  public submitted = false;
  public lstNotificacoes: ListarChamados[] = [];

  private configure() {
    this.oauthService.configure(authConfig);
  }


  public ExpandirMenu(): void {

    this.expandir = true;
    const menu = document.getElementsByClassName("sidebar-menu").item(0) as HTMLElement;
    const bodyoverlay = document.getElementsByTagName("body").item(0) as HTMLElement;

    if(this.expandir){
      menu.className = "sidebar-menu active";
      bodyoverlay.className = "overlay";
    }else{
      this.FecharMenu();
    }
  }

  public FecharMenu() : void {
    const menu = document.getElementsByClassName("sidebar-menu").item(0) as HTMLElement;
    menu.className = "sidebar-menu";

    const bodyoverlay = document.getElementsByTagName("body").item(0) as HTMLElement;
    bodyoverlay.className = " ";

    this.expandir = false;
  }


  public async logout() {

    this.FecharMenu();
    await this.oauthService.loadDiscoveryDocument();
    this.authService.cleanStorageGovBr();
    this.authService.cleanStorageConviva();
    if(this.oauthService.hasValidAccessToken()){
      this.oauthService.logOut();
    }
    else {
      this.router.navigate(['/logout']);
    }
  }

  public async ngOnInit() {
    this.IniciaStreamingNotificacoes();
    this.GetVersao();

    if(this.userLogado?.DadosUsuario[0].CodigoPerfil == 1632)
      this.buscarAgendamentosCoordenador();

    this.router.events.subscribe((event:any) => {
      if(event instanceof NavigationStart &&
        this.userLogado?.DadosUsuario[0].CodigoPerfil == 1632 )
        {

        this.buscarAgendamentosCoordenador();
      }
    });
    if(this.userLogado!.funcionalidades.length == 0)
      this.getFuncionalidades(this.userLogado?.codigoPerfil == undefined ? 0 : this.userLogado?.codigoPerfil);
  }

  public getFuncionalidades(codigoPerfil: number) {
    this.perfilService.getFuncionalidadesPerfil(codigoPerfil).subscribe({
      next: async (response: DefaultResponse) => {
        this.userLogado!.funcionalidades = response.data;
        this.spinnerService.hide();
      },
      error: (error) => {
        this.ConvivaErrors.handleError(error);
        this.spinnerService.hide();
      }
    });
  }

  async buscarAgendamentosCoordenador(){
    var objtEnvio = {
      codigoEscola: this.userLogado?.codigoEscola,
      codigoDiretoria:this.userLogado?.codigoDiretoria
    }
   var result =  await lastValueFrom(this.agendamentoService.getAgendamentosEmAberto(objtEnvio));
   if(!result.isSucess){
    this.toastr.error(result.message);
   }else{
    if(result.data > 0){
      this.showModalAgendamento = true;
      this.quantidadeAgendamentosAbertos = result.data;
      // this.toastr.warning(`Existem ${result.data} agendamentos em aberto.`);

    }
   }
  }

  private IniciaStreamingNotificacoes() {

    setTimeout(() => {
      var filtros = this.setParametrosEventoNotificacao();
      this.notificacoesService.emitEventoNotificacoes(filtros);

      setInterval(()=>{
        var filtros = this.setParametrosEventoNotificacao();
        this.notificacoesService.emitEventoNotificacoes(filtros);
      }, 60000); //A cada 1 minuto busca notificações
    }, 5000);//Espera o hub se conectar

  }

  private async GetVersao() {
    this.versao = environment.version;
  }

  public setParametrosEventoNotificacao():ListarEncaminhados
  {
    const chamado = new ListarEncaminhados();
    if(this.userLogado != null && this.userLogado.codigoDiretoria != null)
      chamado.codigoDiretoria = this.userLogado.codigoDiretoria;
    if(this.userLogado != null && this.userLogado.codigoEscola != null)
      chamado.codigoEscola = this.userLogado.codigoEscola;

    if(this.userLogado?.flPsicologo)
    chamado.perfil = this.userLogado.perfis[0].codigoPerfil;
    else
    chamado.perfil = this.userLogado!.DadosUsuario[0].CodigoPerfil;

    return chamado;
  }




  public SetParametrosChamados(): ListarChamados {
    const chamado = new ListarChamados();
    if(this.userLogado != null && this.userLogado.codigoDiretoria != null)
      chamado.codigoDiretoria = this.userLogado.codigoDiretoria;
    if(this.userLogado != null && this.userLogado.codigoEscola != null)
      chamado.codigoEscola = this.userLogado.codigoEscola;
    chamado.getFoto = false;
    return chamado;
  }
  FecharModalPopUp(){
    this.showModalAgendamento = false;
  }

  OpenModalChangePerfil(){
    this.showModalPerfil = true;
  }

  toggleSubMenu(menu: string) {
    this.activeSubMenus[menu] = !this.activeSubMenus[menu];
  }

  isSubMenuActive(menu: string): boolean {
    return this.activeSubMenus[menu];
  }

  FecharModalAgendamento(){
    this.showModalAgendamentoPsicologo = false;
    this.FecharMenu();
  }

  AbrirModalAgendamento(){
    this.showModalAgendamentoPsicologo = true;
  }
}
