import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { DataTablesModule } from 'angular-datatables';
import { OAuthModule } from 'angular-oauth2-oidc';
import { BaseComponent } from 'components/base/base.component';
import { BreadcrumbComponent } from 'components/breadcrumb/breadcrumb.component';
import { ButtonBackComponent } from 'components/btn-back/btn-back.component';
import { CalendarioComponent } from 'components/calendario/calendario.component';
import { FiltroPadraoComponent } from 'components/filtro-padrao/filtro-padrao.component';
import { InputRangeComponent } from 'components/input-range/input-range.component';
import { ModalAlterarPerfil } from 'components/modal-alterar-perfil/modal-alterar-perfil.component';
import { ModalCalendarioComponent } from 'components/modal-calendario/modal-calendario.component';
import { ModalNotificacoesAlertComponent } from 'components/modal-notificacoes-alert/modal-notificacoes-alert.component';
import { SinoComponent } from 'components/sino/sino.component';
import { StarRatingConvivaComponent } from 'components/star-rating/star-rating.component';
import { StepBarComponent } from 'components/step-bar/step-bar.component';
import { TabulatorTableComponent } from 'components/tabulator/tabulator-table.component';
import { TresPontosComponent } from 'components/tres-pontos/tres-pontos.component';
import { VisibilityManagerComponent } from 'components/visibility-manager/visibility-manager.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { SwiperModule } from 'swiper/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './pages/404/404.component';
import { AgendamentoPsicologosComponent } from './pages/agendamento-psicologos/agendamento-psicologos.component';
import { AtendimentoPsicologosComponent } from './pages/atendimento-psicologos/atendimento.psicologo.component';
import { BuscaComponent } from './pages/busca/busca.component';
import { AdicionarChamadoComponent } from './pages/chamado/adicionar/chamado-add.component';
import { ChamadosListComponent } from './pages/chamado/chamados-list/chamados-list.component';
import { DetalheComponent } from './pages/chamado/detalhe/detalhe.component';
import { EditarChamadoComponent } from './pages/chamado/editar/chamado-edit.component';
import { ChamadoComponent } from './pages/chamado/listar/chamado.component';
import { DashboardNaoOcorrenciaComponent } from './pages/dashboard-nao-ocorrencia/dashboard-nao-ocorrencia.component';
import { DashboardReincidenciaComponent } from './pages/dashboard-reincidencia/dashboard-reincidencia.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AprocacaoVisitaFiscalComponent } from './pages/fiscal/visitas/aprovacao/aprovacao-visita-fiscal.component';
import { AvailacaoVisitaFiscalComponent } from './pages/fiscal/visitas/avaliacao/availacao-visita-fiscal.component';
import { DashboardVisitasComponent } from './pages/fiscal/visitas/dashboard/dashboard-visitas.component';
import { VisualizarAtividadePsicologoFiscalComponent } from './pages/fiscal/visitas/visualizar/visualizar-atividade.component';
import { FuncionalidadeManageComponent } from './pages/funcionalidade-manage/funcionalidade-manage.component';
import { PageGerenciamentoEmailComponent } from './pages/gerenciamento-email/gerenciamento-email.component';
import { HomeComponent } from './pages/home/home.component';
import { GerenciarLoginComponent } from './pages/login/gerenciar-login/gerenciar-login.component';
import { LoginComponent } from './pages/login/login.component';
import { LogoutComponent } from './pages/login/logout/logout.component';
import { HeaderComponent } from './pages/main/header/header.component';
import { HeaderGovBrComponent } from './pages/main/header-gov-br/header-gov-br.component';
import { MainComponent } from './pages/main/main.component';
import { ModalComponent } from './pages/modal/modal.component';
import { AddNaoOcorrenciaComponent } from './pages/nao-ocorrencia/adicionar/nao-ocorrencia-add.component';
import { NotificacoesComponent } from './pages/notificacoes/notificacoes.component';
import { PerfilAlunoComponent } from './pages/perfil-aluno/perfil-aluno.component';
import { PerfilManageComponent } from './pages/perfil-manage/perfil-manage.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { PageVisitaProfessorAcolhimentoComponent } from './pages/professor-poc/visitas/acolhimento/professor-visita-acolhemento.component';
import { PageAprocacaoPocVisitasComponent } from './pages/professor-poc/visitas/aprovacao/aprovacao-visitas.component';
import { PageDashboardPocVisitasComponent } from './pages/professor-poc/visitas/dashboard/dashboard-visitas.component';
import { PageVisitaProfessorPrevencaoComponent } from './pages/professor-poc/visitas/prevencao/professor-visita-prevencao.component';
import { PageFiltroVisitasProfessorComponent } from './pages/professor-poc/visitas/professor-filtro-visitas.component';
import { VizualizarAtividadePocComponent } from './pages/professor-poc/visitas/vizualizar/vizualizar-atividade-poc';
import { AdicionarPsicologoComponent } from './pages/psicologo/adicionar/psicologo-add.component';
import { EditarPsicologoComponent } from './pages/psicologo/editar/psicologo-edit.component';
import { ManutencaoPsicologoComponent } from './pages/psicologo/manutencao/psicologo-manutencao.component';
import { VisitaEditarComponent } from './pages/psicologo/visita-editar/visita-editar.component';
import { VisitaPsicologoComponent } from './pages/psicologo/visita/psicologo-visita.component';
import { PageVisitaPsicologoacolhimentoComponent } from './pages/psicologo/visitas/acolhimento/psicologo-visita.acolhimento';
import { AgendamentoPrevencaoComponent } from './pages/psicologo/visitas/agendamento-prevencao/agendamento-prevencao.component';
import { PageAprocacaoVisitasComponent } from './pages/psicologo/visitas/aprovacao/aprovacao-visitas.component';
import { PageDashboardVisitasComponent } from './pages/psicologo/visitas/dashboard/dashboard-visitas.component';
import { PageDeslocamentoPsicologoComponent } from './pages/psicologo/visitas/deslocamento/psicologo-deslocamento.component';
import { PageVisitaPsicologoOutrosComponent } from './pages/psicologo/visitas/outros/psicologo-visita-outros.component';
import { PageVisitaPsicologoPrevencaoComponent } from './pages/psicologo/visitas/prevencao/psicologo-visitas-prevencao.component';
import { PageFiltroVisitasPsicologoComponent } from './pages/psicologo/visitas/psicologo-filtro-visitas.component';
import { VisualizarAtividadeComponent } from './pages/psicologo/visitas/visualizar/visualizar-atividade.component';
import { ConsolidadoComponent } from './pages/relatorio/consolidado/consolidado.component';
import { AuthService } from './shared/auth/services/auth.service';
import { RequestInterceptor } from './shared/auth/services/interceptor.service';
import { AuthGuardService } from './shared/guards/auth-guard.service';
import { NonAuthGuardService } from './shared/guards/non-auth-guard.service';
import { AlunoService } from './shared/services/alunos.service';
import { ArquivoService } from './shared/services/arquivo.service';
import { CategoriaService } from './shared/services/categoria.service';
import { ChamadoService } from './shared/services/chamado.service';
import { ConcluirService } from './shared/services/concluir.service';
import { ConvivaErrorHandlingService } from './shared/services/convivaErrorHandlingService';
import { EmailService } from './shared/services/email.service';
import { EscolaService } from './shared/services/escola.service';
import { FiltroService } from './shared/services/filtro.service';
import { FuncionarioService } from './shared/services/funcionarios.service';
import { NaoOcorrenciaService } from './shared/services/nao-ocorrencia.service';
import { PerfilService } from './shared/services/perfil.service';
import { PsicologoService } from './shared/services/psicologo.service';
import { UtilsService } from './shared/services/utils.service';
import { GenericValidator } from './shared/validations/genericValidators';

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    HeaderComponent,
    HeaderGovBrComponent,
    HomeComponent,
    BuscaComponent,
    ChamadoComponent,
    AdicionarChamadoComponent,
    DetalheComponent,
    ModalComponent,
    PerfilComponent,
    NotificacoesComponent,
    PerfilAlunoComponent,
    GerenciarLoginComponent,
    LogoutComponent,
    PageNotFoundComponent,
    EditarChamadoComponent,
    PerfilManageComponent,
    FuncionalidadeManageComponent,
    DashboardComponent,
    AgendamentoPsicologosComponent,
    AdicionarPsicologoComponent,
    EditarPsicologoComponent,
    ManutencaoPsicologoComponent,
    SinoComponent,
    ChamadosListComponent,
    AtendimentoPsicologosComponent,
    DashboardReincidenciaComponent,
    DashboardNaoOcorrenciaComponent,
    AddNaoOcorrenciaComponent,
    ConsolidadoComponent,
    CalendarioComponent,
    ModalNotificacoesAlertComponent,
    ModalCalendarioComponent,
    TresPontosComponent,
    VisitaPsicologoComponent,
    PageDashboardVisitasComponent,
    PageDashboardPocVisitasComponent,
    PageFiltroVisitasProfessorComponent,
    PageVisitaProfessorPrevencaoComponent,
    PageVisitaProfessorAcolhimentoComponent,
    PageFiltroVisitasPsicologoComponent,
    PageDeslocamentoPsicologoComponent,
    PageVisitaPsicologoacolhimentoComponent,
    PageVisitaPsicologoPrevencaoComponent,
    PageAprocacaoVisitasComponent,
    PageAprocacaoPocVisitasComponent,
    PageGerenciamentoEmailComponent,
    VisitaEditarComponent,
    ModalAlterarPerfil,
    InputRangeComponent,
    StepBarComponent,
    BaseComponent,
    VisualizarAtividadeComponent,
    VizualizarAtividadePocComponent,
    DashboardVisitasComponent,
    AprocacaoVisitaFiscalComponent,
    VisualizarAtividadePsicologoFiscalComponent,
    ButtonBackComponent,
    FiltroPadraoComponent,
    PageVisitaPsicologoOutrosComponent,
    AvailacaoVisitaFiscalComponent,
    TabulatorTableComponent,
    StarRatingConvivaComponent,
    VisibilityManagerComponent,
    BreadcrumbComponent,
    AgendamentoPrevencaoComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgSelectModule,
    SwiperModule,
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
    ToastrModule.forRoot({timeOut: 6000}),
    NgbModule,
    NgxMaskDirective, NgxMaskPipe,
    OAuthModule.forRoot(),
    DataTablesModule,
    NgbPaginationModule
  ],
  providers: [
    EscolaService,
    CategoriaService,
    ArquivoService,
    ConvivaErrorHandlingService,
    AlunoService,
    PerfilService,
    PsicologoService,
    provideNgxMask(),
    GenericValidator,
    ChamadoService,
    FuncionarioService,
    AuthService,
    AuthGuardService,
    NonAuthGuardService,
    NaoOcorrenciaService,
    ConcluirService,
    UtilsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    DatePipe,
    FiltroService,
    EmailService

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
