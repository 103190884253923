//Principais
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgSelectConfig } from "@ng-select/ng-select";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { lastValueFrom } from "rxjs";

//Models
import { DefaultResponse } from "src/app/shared/models/DefaultResponse";
import { ListarChamados } from "src/app/shared/models/chamados/listar-chamados";
import { Diretoria } from "src/app/shared/models/diretoria/Diretoria";
import { Escola } from "src/app/shared/models/escola/Escola";
import { ArquivoAzure } from 'src/app/shared/models/arquivo/ArquivoAzure';
import { ChamadoDeletar } from "src/app/shared/models/chamados/chamadoDeletar";

//Services
import { ConvivaErrorHandlingService } from "src/app/shared/services/convivaErrorHandlingService";
import { EscolaService } from "src/app/shared/services/escola.service";
import { ChamadoService } from "src/app/shared/services/chamado.service";
import { HistoricoChamadoService } from "src/app/shared/services/historicoChamado.service";
import { EncaminhamentoService } from "src/app/shared/services/encaminhamento.service";
import { HistoricoChamado } from "src/app/shared/models/chamados/historico";
import { ArquivoService } from 'src/app/shared/services/arquivo.service';
import { AtribuicaoLoginSed, UserLoginSed } from "src/app/shared/models/sed/UserLogin";
import { AuthService } from "src/app/shared/auth/services/auth.service";
import { AgendamentoService } from "src/app/shared/services/agendamento.service";
import { AtendimentoService } from "src/app/shared/services/atendimento.service";
import { ConcluirService } from "src/app/shared/services/concluir.service";
import { PsicologoService } from "src/app/shared/services/psicologo.service";
import { CadAtendimento } from "src/app/shared/models/psicologo/CadAtendimento";
import { FiltroPadrao } from "src/app/shared/models/filtro-padrao/filtroPadrao";
import { FiltroService } from "src/app/shared/services/filtro.service";
import { BaseComponent } from "components/base/base.component";

@Component({
  templateUrl: './chamado.component.html',
  styleUrls: ['./chamado.component.scss']
})

export class ChamadoComponent extends BaseComponent implements OnInit {

  public page = 4;

  constructor(private config: NgSelectConfig,
              
              private chamadoService: ChamadoService,
              private historicoChamadoService: HistoricoChamadoService,
              private encaminhamento: EncaminhamentoService,
              private agendamento: AgendamentoService,
              private atendimento: AtendimentoService,
              private concluirService: ConcluirService,
            
              private arquivoService: ArquivoService,
              
              private ConvivaErrors: ConvivaErrorHandlingService,
           
              private filtroService: FiltroService,
              private route: ActivatedRoute) {
                super();

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.parametroIdChamado = params.get('ic');
      this.parametroIdEnvolvido = params.get('ie');
    });

    this.config.notFoundText = 'Não encontrado';

    this.userLogado = this.authService.getUser();
    if(this.userLogado != null && !this.userLogado.flPsicologo){
      this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];
    }
  }
  
  public gravidades = [ 
    { id: 1, name: 'Amarelo' }, 
    { id: 2, name: 'Vermelho' }
  ];

  public status = [
    { id: 0, name: 'Não Analisado' },
    { id: 1, name: 'Encaminhado para Psicólogo' },
    { id: 2, name: 'Encaminhado à Rede Protetiva' },
    { id:3, name:'Encaminhado para Saúde' },
    { id:4, name:'Psicólogo Agendado' },
    { id:5 , name:'Atendimento Realizado'},
    { id:6 , name:'Concluído'}
  ];

  public slas = [
    { id: 'Pendente análise do chamado', name: 'Pendente análise do chamado' },
    { id: 'Atrasado Agendamento do Psicólogo', name: 'Atrasado Agendamento do Psicólogo' },
    { id:'Atrasado Atendimento da Rede Protetiva', name:'Atrasado Atendimento da Rede Protetiva'},
    { id:'Atrasado Atendimento da Saúde', name:'Atrasado Atendimento da Saúde'},
    { id:'Aguardando Atendimento do Psicólogo', name:'Aguardando Atendimento do Psicólogo'},
    { id:'Aguardando Encerramento', name:'Aguardando Encerramento'},
    { id:'Encerrado', name:'Encerrado'},
  ];

  @ViewChild('archivoInput', { static: false }) archivoInput: ElementRef;


  public lstChamados: ListarChamados[] = [];
  public lstAllChamados: ListarChamados[] = [];
  public itemChamado: ListarChamados;
  public lstHistoricos: HistoricoChamado[] = [];
  public envolvidoAEliminar: HistoricoChamado;
  public files: ArquivoAzure[] = [];
  public chamadoHistorico: ListarChamados;
  public parametroIdChamado: any;
  public parametroIdEnvolvido: any;
  public dsHistoricoChamado:string;
  public tituloModalEncaminhamento:string;
  public tituloModalEliminarEncaminhamento:string;
  public statusCriada:any;
  public statusEncaminhada:any;
  public statusEncaminhadaPolicia:any;
  public statusEncaminhadaSaude:any;
  public statusAgendada:any;
  public statusAtendida: any;
  public statusFechada:any;
  public showDataAtendimento:boolean;
  public qtdCaracteresHistorico:number;
  //Adiciona informacao historico psicólogo
  public qtdeCaracteresInfoAdicional:number;
  public showModalAddInfoHistorico:boolean;
  public historico:HistoricoChamado | undefined;
  public formAddInfoHistorico!: FormGroup;

  public formCadastrar!: FormGroup;
  public formCadastrarHistorico!: FormGroup;
  public formModalEncaminhamento!: FormGroup;
  public formAtendimento!: FormGroup;
  public submitted = false;
  public submittedModal = false;
  public submittedModalEliminarEncaminhamento = false;
  public showPesquisa = false;
  public showMenu = true;
  public showHistorico = false;
  public showStatus = false;
  public showConteudoChamado = false;
  public showModalExcluirChamado = false;
  public showModalEncaminhamento = false;
  public showModalEliminarEncaminhamento = false;
  public showModalAgendamentoPsicologo =  false;
  public showModalAtendimentoPsicologo =  false;
  public showModalConcluirChamado =  false;
  public descricaoExclusao = '';
  public descricaoEliminarEncaminhamento = '';
  public descricaoAgendamento = '';
  public descricaoAtendimento = "";
  public descricaoConcluir = "";
  public chamadoARemover = 0;
  public chamadoAAgendar = 0;
  public chamadoAAtender = 0;
  public chamadoAConcluir = 0;
  public envolvidoSelecionado:number;
  public get CoordenadorPsicologo(){return this.userLogado?.DadosUsuario == undefined ? 0 :this.userLogado?.DadosUsuario.filter(s => s.CodigoPerfil == 1632).length}
  public get form() { return this.formCadastrar.controls; }

  public async ngOnInit() {

    //#region Forms

    this.formCadastrar = new FormGroup({
      codigoDiretoria: new FormControl(null, [Validators.required]),
      codigoEscola: new FormControl(null, [Validators.required]),
      codigoTurno: new FormControl(null),
      codigoTurma: new FormControl(null),
      codigoGravidade: new FormControl(null),
      codigoStatus: new FormControl(null),
      codigoSLA: new FormControl(null),
    });

    this.formCadastrarHistorico = new FormGroup({
      dsHistoricoChamado: new FormControl(null,[Validators.maxLength(500),Validators.minLength(10)]),
    });

    this.formAtendimento = new FormGroup({
      dataAtendimento: new FormControl(null,[Validators.required])
    });

    this.formAddInfoHistorico = new FormGroup({
      newInfoHistoric: new FormControl(null,[Validators.maxLength(500),Validators.minLength(10)])
    });

    //#endregion


   //teste
    if(this.userLogado?.flPsicologo)
        await this.getDiretoriasPerfilPsicologo();
    else {
      if(this.atribuicoesLogin.NumeroComportamento == 1)
        await this.getDiretorias();
      
      if(this.atribuicoesLogin.NumeroComportamento == 2)
        await this.getDiretoriasPerfilDiretoria();

      if(this.atribuicoesLogin.NumeroComportamento == 3 || this.atribuicoesLogin.NumeroComportamento == 4)
        await this.getDiretoriasPerfilEscola();
    }
    if(this.parametroIdChamado != null)
      this.FiltrarChamadoIdHis(this.parametroIdChamado);
  }

  public Voltar() {
    this.showHistorico = false;
    this.showConteudoChamado = false;
    this.showStatus = false;
    this.showMenu = true;    

    const menu = document.getElementsByClassName("sidebar-chamado").item(0) as HTMLElement;
    if(menu != null)
      menu.className = "time-line mx-w-900";
  }

  public scrollTo(el: HTMLElement) {
    el.scrollIntoView();
  }

  public OnVisualizarChamado(id:number) {
    this.router.navigate(['/detalhe/', id]);
  }

  public FiltraChamadosAtuais() {
    if(this.form.codigoStatus?.value && this.form.codigoSLA?.value) {
      this.toastr.warning("Não é possível filtrar por SLA e Status simultaneamente");
      this.formCadastrar.reset({
        codigoDiretoria: this.form.codigoDiretoria?.value,
        codigoEscola: this.form.codigoEscola?.value,
        codigoGravidade: this.form.codigoGravidade?.value,
        codigoTurno: this.form.codigoTurno?.value,
        codigoTurma: this.form.codigoTurma?.value,
        codigoStatus: null,
        codigoSLA: null
      });

      const codigoGravidade = this.form.codigoGravidade?.value;
      this.lstChamados =  this.lstAllChamados.filter(item => (codigoGravidade == undefined || item.codigoGravidade == codigoGravidade));
      return;
    }

    const codigoGravidade = this.form.codigoGravidade?.value;
    const codigoStatus = this.form.codigoStatus?.value;
    const sla = this.form.codigoSLA.value;
    this.lstChamados =  this.lstAllChamados
    .filter(item =>
      (codigoGravidade == undefined || item.codigoGravidade == codigoGravidade) &&
      (codigoStatus == undefined || item.aluno?.statusEnvolvido == codigoStatus) &&
      (sla  == undefined || item.aluno?.dsStatusEnvolvido == sla)
      );
  }

  public FiltrarChamadoId(event: any){
    debugger;
    if(event.target?.value){
      this.lstChamados =  this.lstAllChamados.filter(item => item.codigoChamado.toString().includes(event.target.value));
    }
    else
      this.lstChamados = this.lstAllChamados;
  }

  public FiltrarChamadoIdHis(idChamado: number){
    debugger;
    if(idChamado){
      this.lstChamados =  this.lstAllChamados.filter(item => item.codigoChamado == idChamado);
    }
    else
      this.lstChamados = this.lstAllChamados;
  }

  //#region Diretorias e Escolas

  public async getDiretoriasPerfilDiretoria() {
    this.diretorias = [];
    this.escolas = [];

    this.userLogado?.DadosUsuario?.map((item: AtribuicaoLoginSed) => {
      let diretoria = new Diretoria();
      diretoria.codigoDiretoria = item.CodigoDiretoria;
      diretoria.nomeDiretoria = item.NomeDiretoria;
      this.diretorias.push(diretoria);
    });

    if(this.diretorias.length == 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();
      await this.getEscolas(true);
    }else {
      this.ativaDiretoria();
      await this.getEscolas(true);
    }
  }

  public async getDiretoriasPerfilEscola() {
    this.diretorias = [];
    this.escolas = [];

    this.userLogado?.DadosUsuario?.map((item: AtribuicaoLoginSed) => {
      let diretoria = new Diretoria();
      diretoria.codigoDiretoria = item.CodigoDiretoria;
      diretoria.nomeDiretoria = item.NomeDiretoria;
      this.diretorias.push(diretoria);

      let escola = new Escola();
      escola.codigoEscola = item.CodigoEscola;
      escola.nomeEscola = item.NomeEscola;
      escola.codigoDiretoria = item.CodigoDiretoria;
      this.escolas.push(escola);
    });

    if(this.diretorias.length == 1 && this.escolas.length == 1) {
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();

      this.form.codigoEscola.setValue(this.escolas[0].codigoEscola);
      this.form.codigoEscola.disable();

      await this.getTurnos();
    }

    if(this.diretorias.length == 1 && this.escolas.length > 1){
      this.form.codigoDiretoria.setValue(this.diretorias[0].codigoDiretoria);
      this.form.codigoDiretoria.disable();
    }

    this.ativaDiretoria();
    await this.ativaEscola();
  }

  public ativaDiretoria(){
    if(this.userLogado?.codigoDiretoria)
      this.formCadastrar.get('codigoDiretoria')?.setValue(this.userLogado?.codigoDiretoria);

    if(this.userLogado)
      this.authService.setUser(this.userLogado);
  }

  public async ativaEscola(){
    if(this.userLogado?.codigoEscola)
      this.formCadastrar.get('codigoEscola')?.setValue(this.userLogado?.codigoEscola);

    if(this.userLogado?.codigoDiretoria && this.userLogado?.codigoEscola) {
      await this.getTurnos();
      await this.OnPesquisar();
    }

    if(this.userLogado)
      this.authService.setUser(this.userLogado);
  }

  public async getDiretorias() {
    this.spinnerService.show();

    const result = await lastValueFrom(this.escolaService.getDiretoriaPorTipoDiretoria())
                        .catch((err) => {
                          return this.ConvivaErrors.handleResultError(err);
                        });

    if(!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }

    this.diretorias = result.data;

    if(this.userLogado?.codigoDiretoria) {
      this.ativaDiretoria();
      await this.getEscolas(true);
    }

    this.spinnerService.hide();
  }

  public async getEscolas(diretoriaLogada:boolean = false) {
    const codigoDE = this.userLogado?.codigoDiretoria != undefined && diretoriaLogada ? this.userLogado?.codigoDiretoria : this.formCadastrar.get('codigoDiretoria')?.value;
    
    if(this.userLogado?.flPsicologo) {
      this.zerarEscola();
      this.spinnerService.show();

      const result = await lastValueFrom(this.psicologoService.getDiretoriaEscolasPorPolo(this.userLogado!.codigoPolo))
                          .catch((err) => {
                            return this.ConvivaErrors.handleResultError(err);
                          });

      if(!result.isSucess) {
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }

      this.escolas = result.data.escolas;
      this.diretorias = result.data.diretorias;

      await this.ativaEscola();
      this.spinnerService.hide();
    }
    else if(this.atribuicoesLogin.NumeroComportamento == 1 || this.atribuicoesLogin.NumeroComportamento == 2) {
      this.zerarEscola();

      if (codigoDE) {
        this.spinnerService.show();

        const result = await lastValueFrom(this.escolaService.getEscolasPorCodigoDiretoria(codigoDE))
                            .catch((err) => {
                              return this.ConvivaErrors.handleResultError(err);
                            });

        if(!result.isSucess) {
          this.toastr.error(result.message, result.title);
          this.spinnerService.hide();
          return;
        }

        this.escolas = result.data;
        await this.ativaEscola();
        this.spinnerService.hide();
      }
    }
    
    if(this.userLogado) {
      this.userLogado.codigoDiretoria = codigoDE == null ? 0 : codigoDE;
      this.authService.setUser(this.userLogado);
    }
  }

  public zerarEscola() {
    this.escolas = [];
    this.formCadastrar.get('codigoEscola')?.patchValue(null);
    this.zerarTurnos();
  }

  public async getDiretoriasPerfilPsicologo(){
    this.spinnerService.show();
    var response = await lastValueFrom(this.psicologoService.getDiretoriaPorPolo(this.userLogado!.codigoPolo))
                        .catch((err) => {
                          return this.ConvivaErrors.handleResultError(err);
                        });
      
    if(!response.isSucess){
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }

    this.diretorias = response.data;
    if(this.userLogado?.codigoDiretoria){
      this.form.codigoDiretoria.setValue(this.userLogado.codigoDiretoria);
      await this.getEscolas();
    }
    
    this.form.codigoDiretoria.enable();
    this.spinnerService.hide();
  }

  //#endregion

  //#region Turnos

  public async getTurnos() {
    const codigoEE = this.formCadastrar.get('codigoEscola');
    this.zerarTurnos();

    if (codigoEE?.value) {

      this.spinnerService.show();
      const result = await lastValueFrom(this.escolaService.getTurnosPorCodigoCie(codigoEE?.value))
                          .catch((err) => {
                            return this.ConvivaErrors.handleResultError(err);
                          });

      if(!result.isSucess) {
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }

      this.turnos = result.data;

      if (this.turnos == null)
        this.toastr.warning('Não foi encontrados turnos para essa escola !');

      this.spinnerService.hide();
    }
    
    if(this.userLogado) {
      this.userLogado.codigoEscola = codigoEE?.value == null ? 0 : codigoEE?.value;
      this.authService.setUser(this.userLogado);
    }
  }

  public zerarTurnos() {
    this.turnos = [];
    this.formCadastrar.get('codigoTurno')?.patchValue(null);
  }

  //#endregion
  
  //#region Turmas

  public async getTurmas() {
    this.spinnerService.show();

    const codigoEs = this.formCadastrar.get('codigoEscola');
    const codigoTurno = this.formCadastrar.get('codigoTurno');
  
    if(codigoEs?.value) {
      const result = await lastValueFrom(this.escolaService.getTurmasPorCodigoCie(codigoEs?.value, codigoTurno?.value))
                          .catch((err) => {
                            return this.ConvivaErrors.handleResultError(err);
                          });

      if(!result.isSucess) {
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }

      this.turmas = result.data;
      this.spinnerService.hide();
    }
  }

  public zerarTurma() {
    this.turmas = [];
    this.formCadastrar.get('codigoTurma')?.patchValue(null);
  }

  //#endregion

  //#region Pesquisar

  public async OnPesquisar() {
    this.Voltar();
    this.submitted = true;

    if (this.formCadastrar.invalid) {
      this.showPesquisa = false;
      return;
    }

    this.showPesquisa = true;
    this.spinnerService.show();

    const parametros = this.SetParametrosChamados();
    const result = await lastValueFrom(this.chamadoService.getChamadosEnvolvidos(parametros))
                        .catch((err) => {
                          return this.ConvivaErrors.handleResultError(err);
                        });

    if(!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }

    this.lstChamados = result.data;
    this.lstAllChamados = result.data;

    if(sessionStorage.getItem('dataHistorico') != null){
    this.itemChamado = {} as ListarChamados;
      await this.setParametrosNotificacoes();
    }

    if(this.CoordenadorPsicologo)
      this.lstChamados = this.lstChamados.filter(s =>s.aluno?.statusEnvolvido === 1);

    this.formCadastrar.reset({
      codigoDiretoria: this.form.codigoDiretoria?.value,
      codigoEscola: this.form.codigoEscola?.value,
      codigoGravidade: null,
      codigoTurno: this.form.codigoTurno?.value,
      codigoTurma: this.form.codigoTurma?.value,
      codigoStatus: null,
      codigoSLA: null
    });

    this.lstChamados = this.lstAllChamados
    sessionStorage.removeItem('dataHistorico');
    this.spinnerService.hide();
  }

  public async setParametrosNotificacoes() {
    if(this.parametroIdChamado) {
      const chamadoParametro  = this.lstChamados.filter(x => x.codigoChamado == this.parametroIdChamado && x.codigoEnvolvido == this.parametroIdEnvolvido)
                                                .find(y => y.codigoChamado == this.parametroIdChamado);
      
      if(chamadoParametro != undefined)
        await this.HistoricoChamado(chamadoParametro);
    }
  }

  //#endregion

  //#region Histórico

  public async OnFileChange(event: any) {
    if (event.target.files.length > 0) {
      this.spinnerService.show();
      const formData = new FormData();
      const files = event.target.files;
      
      for (const file of files) {
        formData.append(file.name, file);
      }

      const result = await lastValueFrom(this.arquivoService.setArquivoBase64(formData))
                          .catch((err) => {
                            return this.ConvivaErrors.handleResultError(err);
                          });

      if(!result.isSucess) {
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }

      this.files = result.data;
      this.spinnerService.hide();
    }
  }

  public limparInputArquivo() {
    this.archivoInput.nativeElement.value = null;
    this.files = [];
  }

  public async onSalvarHistorico() {
    this.spinnerService.show();

    if(this.formCadastrarHistorico.invalid) {
      this.toastr.error("Quantidade de caracteres invalida.");
      this.spinnerService.hide();
      return;
    }

    const objetoHistorico: HistoricoChamado = {
      codigoChamado: this.chamadoHistorico.codigoChamado,
      codigoEnvolvido: this.chamadoHistorico.codigoEnvolvido,
      descricaoHistorico: this.formCadastrarHistorico.get('dsHistoricoChamado')?.value,
      statusChamado: 0,
      nomeArquivo: this.files.length == 0 ? "" : this.files[0].nome,
      caminhoArquivo: this.files.length == 0 ? "" : this.files[0].caminhoAzure,
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil,
      conteudo: this.files.length == 0 ? "" : this.files[0].conteudo//,
    };

    if(objetoHistorico.nomeArquivo == "" && objetoHistorico.descricaoHistorico == undefined) {
        this.spinnerService.hide();
        this.toastr.error('É obrigatório a descrição ou foto');
        return;
    }
  
    const result = await lastValueFrom(this.historicoChamadoService.setCadastrarHistoricoChamado(objetoHistorico))
                        .catch((err) => {
                          return this.ConvivaErrors.handleResultError(err);
                        });

    if(!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }

    await this.HistoricoChamado(this.itemChamado);
    this.formCadastrarHistorico.get('dsHistoricoChamado')?.patchValue(null);
    this.limparInputArquivo();
    this.toastr.success('Historico do chamado cadastrado com sucesso.');
    this.spinnerService.hide();
  }

  public SetParametrosChamados(): ListarChamados {
    const dataHistorico = Number(sessionStorage.getItem('dataHistorico'));
    const chamado = new ListarChamados();
    chamado.codigoDiretoria = this.form?.codigoDiretoria.value;
    chamado.codigoEscola = this.form?.codigoEscola.value;
    chamado.codigoTurno = this.form?.codigoTurno.value;
    chamado.codigoTurma = this.form?.codigoTurma.value;
    chamado.codigoGravidade = this.form?.codigoGravidade.value;
    chamado.ordenacaoCrescente = true;
    if(dataHistorico != 0)
      chamado.anoAtual = dataHistorico;
    else{
      chamado.anoAtual = this.filtros.anoAtual;
    }
    return chamado;
  }

  public async HistoricoChamado(chamadoEnvolvido: ListarChamados,el?:HTMLElement) {
    this.spinnerService.show();
    this.statusAgendada = null;
    this.statusAtendida = null;
    this.statusCriada = null;
    this.statusEncaminhada = null;
    this.statusEncaminhadaPolicia = null;
    this.statusEncaminhadaSaude = null;
    this.statusFechada = null;

    const menu = document.getElementsByClassName("time-line")
    if(menu.length > 0) {
      const teste = menu.item(0) as HTMLElement;
      teste.className = "sidebar-chamado";
    }

    this.itemChamado = chamadoEnvolvido;
    const result = await lastValueFrom(await this.chamadoService.getHistoricoEnvolvido(chamadoEnvolvido))
                        .catch((err) => {
                          return this.ConvivaErrors.handleResultError(err);
                        });

    if(!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }

    this.lstHistoricos = result.data;
    this.chamadoHistorico = chamadoEnvolvido;
    this.setStatusHistorico();
    this.showHistorico = true;
    this.showMenu = false;
    this.showStatus = false;
    this.showConteudoChamado = true;
    this.spinnerService.hide();
    this.envolvidoSelecionado = chamadoEnvolvido.codigoEnvolvido;
    
    if(el)
      this.scrollTo(el);
  }

  public setStatusHistorico() {
    let criados = this.lstHistoricos.filter(x => x.statusChamado == 0);
    if(criados.length > 0)
      this.statusCriada = criados[0].data;
    else
      this.statusCriada = null;

    let encaminhados = this.lstHistoricos.filter(x => x.statusChamado == 1);
    if(encaminhados.length > 0)
      this.statusEncaminhada = encaminhados[0].data;
    else
      this.statusEncaminhada = null;

    let encaminhadosPolicia = this.lstHistoricos.filter(x => x.statusChamado == 2);
    if(encaminhadosPolicia.length > 0)
      this.statusEncaminhadaPolicia = encaminhadosPolicia[0].data;
    else
      this.statusEncaminhadaPolicia = null;

    let encaminhadosSaude = this.lstHistoricos.filter(x => x.statusChamado == 3);
    if(encaminhadosSaude.length > 0)
      this.statusEncaminhadaSaude = encaminhadosSaude[0].data;
    else
      this.statusEncaminhadaSaude = null;

    let agendados = this.lstHistoricos.filter(x => x.statusChamado == 4);
    if(agendados.length > 0)
      this.statusAgendada = agendados[0].data;
    else
      this.statusAgendada = null;

    let atendida = this.lstHistoricos.filter(x => x.statusChamado == 5);
    if(atendida.length > 0)
      this.statusAtendida = atendida[0].data;
    else
      this.statusAtendida = null;

    let fechados = this.lstHistoricos.filter(x => x.statusChamado == 6);
    if(fechados.length > 0)
      this.statusFechada = fechados[0].data;
    else
      this.statusFechada = null;
  }

  public OnChangeCountLetters() {
    const descricao = this.formCadastrarHistorico.get('dsHistoricoChamado')!.value;
    if(descricao)
      this.qtdCaracteresHistorico = descricao.length;
  }

  public OnChangeCountLettersInfoAdicional() {
    const descricao = this.formAddInfoHistorico.get('newInfoHistoric')!.value;
    if(descricao)
      this.qtdeCaracteresInfoAdicional = descricao.length;
  }

  public openModalAddInfo(cdHistorico:number | undefined) {
    this.showModalAddInfoHistorico = true;
    if(cdHistorico && cdHistorico > 0)
      this.historico = this.lstHistoricos.find(s => s.codigoHistorico == cdHistorico);
  }

  public closeModalAddInfo() {
    this.formAddInfoHistorico.get('newInfoHistoric')?.setValue(null);
    this.qtdeCaracteresInfoAdicional = 0;
    this.showModalAddInfoHistorico = false;
  }

  public async saveNewInfoOnHistoric() {
    this.spinnerService.show();
    const novaInfo = this.formAddInfoHistorico.get('newInfoHistoric')!.value;

    if(this.formAddInfoHistorico.invalid) {
      this.toastr.error('O campo está vazio ou não cumpre o mínimo de caracteres.');
      this.spinnerService.hide();
      return;
    }

    const body = {
      cdHistorico:this.historico?.codigoHistorico,
      novaInformacao: novaInfo
    };

    const result = await lastValueFrom(this.historicoChamadoService.SetInformacaoAdicional(body))
                        .catch((err) => {
                          return this.ConvivaErrors.handleResultError(err);
                        });

    if(!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }

    this.toastr.success(result.data.mensagem);
    this.closeModalAddInfo();
    await this.HistoricoChamado(this.chamadoHistorico);
    this.spinnerService.hide();
  }

  //#endregion

  //#region Excluir Chamado

  public async ExluirChamado(codigoChamado: number) {
    this.spinnerService.show();

    const chamadoDeletar: ChamadoDeletar = {
      cdChamado: codigoChamado,
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil
    };

    const result = await lastValueFrom(this.chamadoService.setDeletarChamado(chamadoDeletar))
                        .catch((err) => {
                          return this.ConvivaErrors.handleResultError(err);
                        });

    if(!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }

    this.toastr.success('Chamado removido com sucesso!');
    await this.OnPesquisar();
    this.FecharModalExclusao();
    this.spinnerService.hide();
  }

  public FecharModalExclusao() {
    this.showModalExcluirChamado = false;
    this.descricaoExclusao = "";
    this.chamadoARemover = 0;
  }

  public OnExcluirChamado(codigoChamdo: number) {
    this.showModalExcluirChamado = true;
    this.descricaoExclusao = `Deseja remover o chamado ?`;
    this.chamadoARemover = codigoChamdo;
  }

  //#endregion

  //#region Encaminhamento

  public AbrirModalEncaminhamento(codigoChamado:number, codigoEnvolvido:number) {
    this.submittedModal = false;
    this.formModalEncaminhamento = new FormGroup({});
    this.showModalEncaminhamento = true;
    this.tituloModalEncaminhamento = 'Encaminhamentos';
  }

  public OnEliminarEncaminhamento(codigoChamadoSelected:number, codigoEnvolvidoSelected:number) {
    this.showModalEliminarEncaminhamento = true;
    this.descricaoEliminarEncaminhamento = `Deseja Eliminar o Encaminhamento ?`;
    const objetoHistorico: HistoricoChamado = {
      codigoChamado: codigoChamadoSelected,
      codigoEnvolvido: codigoEnvolvidoSelected,
      descricaoHistorico: this.descricaoEliminarEncaminhamento,
      statusChamado: 0,
      nomeArquivo: "",
      caminhoArquivo: "",
      conteudo: ""
    };
    this.envolvidoAEliminar = objetoHistorico;
  }

  public async EliminarEncaminhamento() {
    const objetoHistorico: HistoricoChamado = {
      codigoChamado: this.envolvidoAEliminar.codigoChamado,
      codigoEnvolvido: this.envolvidoAEliminar.codigoEnvolvido,
      descricaoHistorico: 'Cancelamento de encaminhamento.',
      statusChamado: 0,
    };

    this.FecharModalEliminarEncaminhamento();
    this.spinnerService.show();
    const result = await lastValueFrom(this.encaminhamento.setDeletarEncaminhamentoPsicologo(objetoHistorico))
                        .catch((err) => {
                          return this.ConvivaErrors.handleResultError(err);
                        });

    if(!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.toastr.success('Encaminhamento Cancelado com Sucesso.');
    this.Voltar();
    await this.OnPesquisar();
    this.spinnerService.hide();
  }

  public StatusChamado() {
    this.showStatus = !this.showStatus;
    this.showConteudoChamado = !this.showConteudoChamado;
  }

  public async EncaminhamentoPsicologo() {
    const objetoHistorico: HistoricoChamado = {
      codigoChamado: this.chamadoHistorico.codigoChamado,
      codigoEnvolvido: this.chamadoHistorico.codigoEnvolvido,
      descricaoHistorico: 'Aluno encaminhado ao Psicólogo.',
      statusChamado: 2, //Encaminhado ao Osicologo
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil
    };

    this.FecharModalEncaminhamento();
    this.spinnerService.show();
    const result = await lastValueFrom(this.encaminhamento.setCadastrarEncaminhamento(objetoHistorico))
                        .catch((err) => {
                          return this.ConvivaErrors.handleResultError(err);
                        });
 
    if(!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }

    this.toastr.success('Aluno Encaminhado ao Psicólogo com Sucesso.');
    this.Voltar();
    await this.OnPesquisar();
    this.spinnerService.hide();
  }

  public async EncaminhamentoPolicia() {
    const objetoHistorico: HistoricoChamado = {
      codigoChamado: this.chamadoHistorico.codigoChamado,
      codigoEnvolvido: this.chamadoHistorico.codigoEnvolvido,
      descricaoHistorico: 'Aluno encaminhado a Rede protetiva.',
      statusChamado: 2,
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil
    };
  
    this.FecharModalEncaminhamento();
    this.spinnerService.show();
    const result = await lastValueFrom(this.encaminhamento.setCadastrarEncaminhamentoPolicia(objetoHistorico))
                        .catch((err) => {
                          return this.ConvivaErrors.handleResultError(err);
                        });

    if(!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }

    this.toastr.success('Aluno Encaminhado a Rede protetiva com Sucesso.');
    this.Voltar();
    await this.OnPesquisar();
    this.spinnerService.hide();
  }

  public async EncaminhamentoSaude() {
    const objetoHistorico: HistoricoChamado = {
      codigoChamado: this.chamadoHistorico.codigoChamado,
      codigoEnvolvido: this.chamadoHistorico.codigoEnvolvido,
      descricaoHistorico: 'Aluno encaminhado a Saúde.',
      statusChamado: 3,
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil
    };

    this.FecharModalEncaminhamento();
    this.spinnerService.show();
    const result = await lastValueFrom(this.encaminhamento.setCadastrarEncaminhamentoSaude(objetoHistorico))
                        .catch((err) => {
                          return this.ConvivaErrors.handleResultError(err);
                        });

    if(!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }

    this.toastr.success('Aluno Encaminhado a Saúde com Sucesso.');
    this.Voltar();
    await this.OnPesquisar();
    this.spinnerService.hide();
  }

  public FecharModalEncaminhamento() {
    this.showModalEncaminhamento = false;
  }

  public FecharModalEliminarEncaminhamento() {
    this.showModalEliminarEncaminhamento = false;
  }
  //#endregion

  //#region Agendamento/Atendimento/Concluir

  public FecharModalAgendamento() {
    this.showModalAgendamentoPsicologo = false;
  }

  public FecharModalAtendimento() {
    this.showModalAtendimentoPsicologo = false;
    this.showDataAtendimento = false;
    this.formAtendimento.get('dataAtendimento')?.setValue(null);
  }

  public FecharModalConcluir() {
    this.showModalConcluirChamado = false;
  }

  public AbrirModalAgendarPsicologo(codigoChamdo: number) {
    this.showModalAgendamentoPsicologo = true;
    this.descricaoAgendamento = `Deseja agendar o Psicólogo ?`;
    this.chamadoAAgendar = codigoChamdo;
  }

  public AbrirModalAtenderPsicologo(codigoChamdo: number) {
    this.showModalAtendimentoPsicologo = true;
    this.descricaoAtendimento = `Deseja marcar como atendido pelo Psicólogo ?`;
    this.chamadoAAtender = codigoChamdo;
  }

  public AbrirModalConcluirChamado(codigoChamdo: number) {
    this.showModalConcluirChamado = true;
    this.descricaoConcluir = `Deseja concluir o chamado deste Envolvido ?`;
    this.chamadoAConcluir = codigoChamdo;
  }

  public async AgendarChamado() {
    const objetoHistorico: HistoricoChamado = {
      codigoChamado: this.chamadoHistorico.codigoChamado,
      codigoEnvolvido: this.chamadoHistorico.codigoEnvolvido,
      descricaoHistorico: 'Psicólogo Agendado',
      statusChamado: 4, //PsicologoAgendado
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil
    };

    this.FecharModalAgendamento();
    this.spinnerService.show();
    const result = await lastValueFrom(this.agendamento.setCadastrarAgendamento(objetoHistorico))
                        .catch((err) => {
                          return this.ConvivaErrors.handleResultError(err);
                        });

    if(!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }

    this.toastr.success('Psicólogo Agendado com Sucesso.');
    await this.OnPesquisar();
    this.spinnerService.hide();
  }

  public ShowInputDataAtendimento(){
    this.showDataAtendimento = true;

  }

  public async AtenderChamado() {

    if(this.formAtendimento.invalid){
      this.toastr.error("Data do Atendimento é obrigatória.")
      return;
    }

    const objtoAtendimento: CadAtendimento = {
      codigoChamado: this.chamadoHistorico.codigoChamado,
      codigoEnvolvido: this.chamadoHistorico.codigoEnvolvido,
      dataAtendimento:  this.formAtendimento.get('dataAtendimento')!.value,
      atendente: this.userLogado?.codigoPerfil,
      codigoPerfil:  this.userLogado!.codigoPerfil,
      nomePerfil: this.userLogado!.nomePerfil
    };

    this.FecharModalAtendimento();
    this.spinnerService.show();
    const result = await lastValueFrom(this.atendimento.setCadastrarAtendimento(objtoAtendimento))
                        .catch((err) => {
                          return this.ConvivaErrors.handleResultError(err);
                        });

    if(!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }

    this.toastr.success('Atendimento do Psicólogo feito com Sucesso.');
    await this.OnPesquisar();
    this.spinnerService.hide();
  }

  public async ConcluirChamado() {
    const objetoHistorico: HistoricoChamado = {
      codigoChamado: this.chamadoHistorico.codigoChamado,
      codigoEnvolvido: this.chamadoHistorico.codigoEnvolvido,
      descricaoHistorico: 'Chamado deste envolvido concluído.',
      statusChamado: 6,
      nomeArquivo: "",
      caminhoArquivo: "",
      conteudo: "",
      codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
      nomePerfil: this.atribuicoesLogin.NomePerfil
    };

    this.FecharModalConcluir();
    this.spinnerService.show();

    const result = await lastValueFrom(this.concluirService.setCadastrarConcluir(objetoHistorico))
                        .catch((err) => {
                          return this.ConvivaErrors.handleResultError(err);
                        });

    if(!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }

    this.toastr.success('Chamado deste envolvido concluído com sucesso.');
    await this.OnPesquisar();
    this.spinnerService.hide();
  }

   async listeningFiltroEmitter(eventFilter: FiltroPadrao) {
  
      this.setFilters(eventFilter);
      await this.OnPesquisar();
  
      this.filtroService.atualizaFiltro(eventFilter);
  
    }
  
    setFilters(event: FiltroPadrao) {
      this.filtros = event;
  
    }

  //#endregion
}
