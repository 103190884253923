 <!-- FILTRO ESCOLA -->

 <form [formGroup]="formFilter" (submit)="onSubmit()">
  <section  [ngClass]="{
    'section-filtro':template === 1,
    'section-filtro-menor':template === 2
  }">
    <div class="container">
      <div class="grupo-filter">
        <p><b>Diretoria</b></p>
        <ng-select
          [items]="diretorias"
          bindLabel="nomeDiretoria"
          bindValue="codigoDiretoria"
          placeholder="Selecione uma Diretoria"
          formControlName="codigoDiretoria"
          name="codigoDiretoria"
          [ngClass]="{ 'is-invalid': submitted && form.codigoDiretoria.errors }"
          (change)="getEscolas(form.codigoDiretoria.value)"
        >
        </ng-select>
        <div *ngIf="submitted && form.codigoDiretoria.errors" class="alert">
          <div *ngIf="form.codigoDiretoria.errors.required">
            * Diretoria é obrigatório
          </div>
        </div>
      </div>

      <div class="grupo-filter">
        <p><b>Escola</b></p>
        <ng-select
          [items]="escolas"
          bindLabel="nomeEscola"
          bindValue="codigoEscola"
          placeholder="Selecione uma Escola"
          formControlName="codigoEscola"
          name="codigoEscola"
          (change)="getTurnos()"
          [ngClass]="{ 'is-invalid': submitted && form.codigoEscola.errors }"

        >
        </ng-select>
        <div *ngIf="submitted && form.codigoEscola.errors" class="alert">
          <div *ngIf="form.codigoEscola.errors.required">
            * Escola é obrigatório
          </div>
        </div>
      </div>

      <div class="grupo-filter">
        <p><b>Turno</b></p>
        <ng-select
          [items]="turnos"
          bindLabel="nomeTurno"
          bindValue="codigoTurno"
          placeholder="Selecione um Turno"
          formControlName="codigoTurno"
          name="codigoTurno"
          [ngClass]="{ 'is-invalid': submitted && form.codigoTurno.errors }"
          (change)="getTurmas(form.codigoEscola.value,form.codigoTurno.value)"
        >
        </ng-select>
        <div *ngIf="submitted && form.codigoTurno.errors" class="alert">
          <div *ngIf="form.codigoTurno.errors.required">
            * Turno é obrigatório
          </div>
        </div>
      </div>

      <div class="grupo-filter">
        <p><b>Turma</b></p>
        <ng-select
          [items]="turmas"
          bindLabel="descricaoTurma"
          bindValue="codigoTurma"
          placeholder="Selecione uma Turma"
          formControlName="codigoTurma"
          name="codigoTurma"
          [ngClass]="{ 'is-invalid': submitted && form.codigoTurma.errors }"
        >
        </ng-select>
        <div *ngIf="submitted && form.codigoTurma.errors" class="alert">
          <div *ngIf="form.codigoTurma.errors.required"></div>
        </div>
      </div>
      <div class="grupo-filter-ano">
        <p><b>Ano</b></p>
        <ng-select
          [items]="anos"
          placeholder="Selecione o Ano"
          formControlName="anoAtual"
          name="anoAtual"
          [ngClass]="{ 'is-invalid': submitted && form.anoAtual.errors }"
          >
          <!-- bindLabel="descricaoTurma"
          bindValue="codigoTurma" -->
        </ng-select>
        <div *ngIf="submitted && form.anoAtual.errors" class="alert">
          <div *ngIf="form.anoAtual.errors.required">Ano é um campo obrigatório</div>
        </div>
      </div>

      <button type="submit" class="btn-search-filtro" id="btn-search-chamado">
        <img
          src="../../../assets/img/icons/icon-search.svg"
          alt="Pesquisar"
          title="Pesquisar"
        />
      </button>
    </div>
  </section>
</form>

