export enum Perfil{
  Psicólogo = 1634,
  CoordenadorPsicologo = 1632,
  FiscalPsicologo = 1633,
  GestorConviva = 1477,
  GestorCGRH = 1656,
  POC = 1647,
  PEC = 112,
  DiretorEscolar = 272,
  ViceDiretor = 114,
  Dirigente = 269,
  SupervisorEducacional = 275
}
