import { Component, Renderer2 } from '@angular/core';

@Component({
  selector: 'header-govbr',
  templateUrl: './header-gov-br.component.html',
  styleUrls: ['./header-gov-br.component.scss']
})
export class HeaderGovBrComponent {
  private isContrastEnabled = false;
  private fontSize = 100; // Tamanho inicial da fonte em %

  constructor(
    private renderer: Renderer2
  ) {}

  toggleContrast(): void {
    const body = document.body; // Seleciona o elemento body

    if (this.isContrastEnabled) {
      this.renderer.removeAttribute(body, 'id'); // Remove o ID do body
    } else {
      this.renderer.setAttribute(body, 'id', 'contrast'); // Adiciona o ID contrast ao body
    }

    this.isContrastEnabled = !this.isContrastEnabled; // Atualiza o estado
  }

  increaseFontSize(): void {
    if (this.fontSize < 180) { // Limite máximo de 180%
      this.fontSize += 10;
      this.applyFontSizeToBody();
    }
  }

  decreaseFontSize(): void {
    if (this.fontSize > 100) { // Limite mínimo de 100%
      this.fontSize -= 10;
      this.applyFontSizeToBody();
    }
  }

  private applyFontSizeToBody(): void {
    const body = document.body;
    this.renderer.setStyle(body, 'font-size', `${this.fontSize}%`);
  }
}
