import { Location, formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from "angular-datatables";
import { ADTSettings } from "angular-datatables/src/models/settings";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subject, lastValueFrom } from "rxjs";
import { AuthService } from 'src/app/shared/auth/services/auth.service';
import { Envolvido } from 'src/app/shared/models/chamados/envolvido';
import { TurmasPOC } from 'src/app/shared/models/professor/TurmasAprovacaoPOC';
import { AprovacaoPsicologoFiscal } from 'src/app/shared/models/psicologo/AprovacaoPsicologoFiscal';
import { PsicologoFiscalAtividade } from 'src/app/shared/models/psicologo/ValidarAtividadePsicologoFiscal';
import { AtribuicaoLoginSed, UserLoginSed } from 'src/app/shared/models/sed/UserLogin';
import { ConcluirService } from 'src/app/shared/services/concluir.service';
import { ConvivaErrorHandlingService } from 'src/app/shared/services/convivaErrorHandlingService';
import { DetalheService } from 'src/app/shared/services/detalhe.service';
import { EnvolvidoService } from 'src/app/shared/services/envolvido.service';
import { PsicologoService } from 'src/app/shared/services/psicologo.service';

@Component({
  templateUrl: './aprovacao-visita-fiscal.component.html',
  styleUrls: ['./aprovacao-visita-fiscal.component.scss']
})

export class AprocacaoVisitaFiscalComponent implements OnInit {
  public userLogado: UserLoginSed | null;
  public atribuicoesLogin: AtribuicaoLoginSed;
  isChecked = false;
  public dtOptions: any = {};
  public dtTrigger: Subject<ADTSettings> = new Subject<ADTSettings>();
  @ViewChild(DataTableDirective, {static: false})
  public dtElement: DataTableDirective;
  public hideAfterLoadTable: boolean;
  public hideAfterLoadTableAlunos: boolean;
  public isDtInitialized:boolean = false;
  public parametroId: any;
  public chamadoEncerrar: number | null = null;
  public checkbox1: boolean = false;
  public checkbox2: boolean = false;
  public checkbox3: boolean = false;
  public tpAcaoAtividade: string = "";
  public tipoAprovacao: boolean = true;

  constructor(private spinnerService: NgxSpinnerService,
    private detalheService: DetalheService,
    private ConvivaErrors: ConvivaErrorHandlingService,
    private toastr: ToastrService,
    @Inject(LOCALE_ID) private locale: string,
    private envolvidoService: EnvolvidoService,
    private concluirService: ConcluirService,
    private psicologoService: PsicologoService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
   ) {
    this.userLogado = this.authService.getUser();

    if(this.userLogado != null){
      this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];

      this.route.paramMap.subscribe((params) => {
        this.parametroId = Number(params.get('id')) ?? 0;
      });


    }
  }
  //Forms da tela
  public isButtonVisible: boolean = true;
  public submitted: boolean;

  //Modal
  public showModalConfirmacao:boolean;

  //Listas da tela
  public atividade: PsicologoFiscalAtividade = new PsicologoFiscalAtividade();
  public lstEnvolvidos: Envolvido[] = [];
  public lstTurmas: TurmasPOC[] = [];

  // FORM CADASTRAR
  public formCadastrar!: FormGroup;

  public get form() {
    return this.formCadastrar.controls;
  }

  //BTN VOLTAR
  goBack(): void {
    this.location.back();
  }

  public async ngOnInit() {

    this.dtOptions = {
      deferRender: true,
      destroy:true,
      processing: true,
      paging: true,
      dom: 'Bfrtip',
      order: [
        [0, 'asc'],
        [6, 'asc']
      ],
      language: {
        url: 'https://cdn.datatables.net/plug-ins/1.11.5/i18n/pt-BR.json'
      },
      lengthMenu: [
        [ 10, 25, 50, -1 ],
        [ '10 linhas', '25 linhas', '50 linhas', 'Tudo' ]
      ],
      buttons: [
        'pageLength',
        {
          extend:'print',
          exportOptions:{
            columns:[0,1,2,3,4,5,6]
          }
        },
        {
          extend:'excel',
          exportOptions:{
            columns:[0,1,2,3,4,5,6]
          }
        },
        {
          extend:'pdf',
          exportOptions:{
            columns:[0,1,2,3,4,5,6]
          }
        }
      ],
    };
    this.formCadastrar = new FormGroup({
      dsAprovar: new FormControl('')
    });
    await this.PopularDados();
  }


  public async PopularDados() {
    this.spinnerService.show();
    const result = await lastValueFrom(await this.detalheService.getAtividadeDetalhePsicologoFiscalPorId(this.parametroId))
    .catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });
    if(!result.isSucess){
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.atividade = result.data

    if(this.atividade.tP_ACAO_ATIVIDADE == 1 || this.atividade.tP_ACAO_ATIVIDADE == 3){
      this.tpAcaoAtividade = " Prevenção";
    }else
      this.tpAcaoAtividade = " Acolhimento";

    if(this.atividade.cD_ENVOLVIDO != null){
      var envolvido = await lastValueFrom(
        this.envolvidoService.GetEnvolvidosPorAtividadePsicologo(this.atividade.cD_ATIVIDADE_PSICOLOGO));
        this.lstEnvolvidos = envolvido.data;
    }
    if(this.atividade.tP_ATIVIDADE_REALIZADA == 2){
      var turmas = await lastValueFrom(this.psicologoService.getTurmasAprovacaoPsicologo(this.atividade.cD_ATIVIDADE_PSICOLOGO));
      this.lstTurmas = turmas.data;
    }

       setTimeout(() => {
         this.spinnerService.hide();
       }, 3000);
}
  public getDate(data?: Date) {
    if (data == undefined) return;
  return formatDate(data, 'dd/MM/yyyy', this.locale);
}
  public getTempoDeslocamento(saida?: string, chegada?: string): string {
  if (!saida || !chegada) return '';

  const [horaSaida, minutoSaida] = saida.split(':').map(Number);
  const [horaChegada, minutoChegada] = chegada.split(':').map(Number);

  const dataSaida = new Date();
  dataSaida.setHours(horaSaida, minutoSaida);

  const dataChegada = new Date();
  dataChegada.setHours(horaChegada, minutoChegada);

  const diferencaMs = dataChegada.getTime() - dataSaida.getTime();
  const diferencaMin = Math.floor(diferencaMs / 60000);
  const horas = Math.floor(diferencaMin / 60);
  const minutos = diferencaMin % 60;

  return `${this.pad(horas)}:${this.pad(minutos)}`;
}

pad(num: number): string {
  return num.toString().padStart(2, '0');
  }

  public abrirEmNovaAba(caminho:string): void {
    if (this.atividade && caminho) {
      window.open(caminho, '_blank');
    }
  }
  public getArquivoNomeCompleto(): string {
    if (!this.atividade || !this.atividade.nM_ARQUIVO) {
      return '';
    }
    return this.atividade.nM_ARQUIVO.slice(37);
  }
  onChange1(){
    this.checkbox2 = false;
    this.checkbox3 = false;
    this.tipoAprovacao = true;
  }
  onChange2(){
    this.checkbox1 = false;
    this.checkbox3 = false;
    this.tipoAprovacao = true;
  }
  onChange3(){
    this.checkbox1 = false;
    this.checkbox2 = false;
    this.tipoAprovacao = false;
  }

  public AbreFechaModalConfirmacao(codigoChamado?: number){
    if (codigoChamado !== undefined) {
      this.chamadoEncerrar = this.atividade.cD_CHAMADO!;
    }
    this.showModalConfirmacao = !this.showModalConfirmacao;
  }

  public async AprovarAtividade(){
    this.spinnerService.show();
    this.submitted = true;
    const situacaoAprovacaoDiretoria = this.getCheckboxNumber(this.checkbox1, this.checkbox2, this.checkbox3);
    const objetoAprovacao: AprovacaoPsicologoFiscal = {
      cD_USUARIO_APROVACAO_FISCAL: this.atribuicoesLogin.CodigoUsuario,
      cD_PERFIL_APROVACAO_FISCAL: this.atribuicoesLogin.CodigoPerfil,
      nM_PERFIL_APROVACAO_FISCAL: this.atribuicoesLogin.NomePerfil,
      oB_APROVACAO_FISCAL: this.form.dsAprovar.value,
      sT_APROVACAO_FISCAL: situacaoAprovacaoDiretoria,
      cD_ATIVIDADE_PSICOLOGO: this.atividade.cD_ATIVIDADE_PSICOLOGO
    };
    if (this.atividade.sT_APROVACAO_FISCAL == 1 ||  this.atividade.sT_APROVACAO_FISCAL == 2 ) {
      this.toastr.error(`A atividade código ${this.atividade.cD_ATIVIDADE_PSICOLOGO} já foi aprovada`);
      this.spinnerService.hide();
      return;
    }

    if(!this.checkbox1 && !this.checkbox2 && !this.checkbox3){
      this.toastr.error('Por favor, preencha alguma das opções avaliando a atividade do Psicologo');
      this.spinnerService.hide();
      return;
    }

    if(this.checkbox2 || this.checkbox3){
      if(this.form.dsAprovar.value == ""){
      this.toastr.error('Por favor, responda a avaliação sobre a atividade do Psicologo');
      this.spinnerService.hide();
      return;
      }
    }

      // if(this.checkbox1 && !this.checkbox2 && !this.checkbox3 && this.atividade.cD_CHAMADO != null){
      //   await this.ConcluirChamadoAtividade();
      // }
    var response = await lastValueFrom(this.psicologoService.aprovacaoAtividadePsicologoFiscal(objetoAprovacao))
                          .catch((err) => {
                            return this.ConvivaErrors.handleResultError(err);
                          });
        if(!response.isSucess){
           this.toastr.error(response.message);
           this.spinnerService.hide();
           return;
          }
    this.toastr.success("Atividade aprovada com sucesso!");
    this.spinnerService.hide();
    this.router.navigate(['/dashboard-visitas-psicologo-fiscal/']);
  }

  // public async ConcluirChamado(){

  //     this.spinnerService.show();

  //       const objetoHistorico: HistoricoChamado = {
  //         codigoChamado: this.atividade.cD_CHAMADO!,
  //         codigoEnvolvido: 0 ,
  //         descricaoHistorico: 'Chamado deste envolvido foi encerrado.',
  //         statusChamado: 6,
  //         nomeArquivo: "",
  //         caminhoArquivo: "",
  //         conteudo: "",
  //         codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
  //         nomePerfil: this.atribuicoesLogin.NomePerfil
  //     };

  //     this.AbreFechaModalConfirmacao();

  //     this.spinnerService.show();

  //     const result = await lastValueFrom(await this.concluirService.setCadastrarConcluirChamadoPorAtividade(objetoHistorico))
  //                      .catch((err) => {
  //                        return this.ConvivaErrors.handleResultError(err);
  //                       });

  //      if(!result.isSucess){
  //       this.toastr.error(result.message, result.title);
  //       this.spinnerService.hide();
  //       return;
  //       }
  //       this.isButtonVisible = true;
  //       this.toastr.success('Chamado deste envolvido encerrado com sucesso.');
  //       this.spinnerService.hide();

  //     }

// public async ConcluirChamadoAtividade(){

//         const objetoHistorico: HistoricoChamado = {
//           codigoChamado: this.atividade.cD_CHAMADO!,
//           codigoEnvolvido: 0 ,
//           descricaoHistorico: 'Chamado deste envolvido foi encerrado.',
//           statusChamado: 6,
//           nomeArquivo: "",
//           caminhoArquivo: "",
//           conteudo: "",
//           codigoPerfil: this.atribuicoesLogin.CodigoPerfil,
//           nomePerfil: this.atribuicoesLogin.NomePerfil
//       };

//       const result = await lastValueFrom(await this.concluirService.setCadastrarConcluirChamadoPorAtividade(objetoHistorico))
//                        .catch((err) => {
//                          return this.ConvivaErrors.handleResultError(err);
//                         });

//        if(!result.isSucess){
//         this.toastr.error(result.message, result.title);
//         this.spinnerService.hide();
//         return;
//         }
//         this.isButtonVisible = true;
//         this.toastr.success('Chamado deste envolvido encerrado com sucesso.');
// }
  private getCheckboxNumber(value: boolean, value2: boolean, value3 : boolean): number {
        if (value) {
          return 1;
      } else if (value2) {
          return 2;
      } else if (value3) {
          return 3;
      } else {
          return 0;
      }
      }
      getShortenedName(name: string): string {
        if (!name || name.length <= 19) {
          return name;
        }

        const firstPart = name.slice(0, 15);
        const lastPart = name.slice(-4);
        return `${firstPart}${lastPart}`;
      }

}
