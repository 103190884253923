<form [formGroup]="formAvaliacao" (submit)="Visualizar()">
  <breadcrumb
    [paginas]="[{route:'/avaliacao-visita-fiscal',name:'Home'},{route:'/avaliacao-visita-fiscal',name:'Painel de Avaliação do Fiscal'}]"
  />
    <section class="section-filtro">
        <div class="container">
            <div class="grupo-filter">
                <p><b>Polo</b></p>
                <ng-select
                    [items]="polos"
                    bindLabel="descricaoPolo"
                    bindValue="codigoPolo"
                    placeholder="Selecione um Polo"
                    name="codigoPolo"
                    formControlName="codigoPolo"
                    (change)="OnChangePolo()"
                    [ngClass]="{'is-invalid': submitted && form.codigoPolo.errors}"
                >
                </ng-select>

                <div *ngIf="submitted && form.codigoPolo.errors" class="alert">
                    <div *ngIf="form.codigoPolo.errors.required">
                        * Polo é obrigatório
                    </div>
                </div>
            </div>

            <div class="grupo-filter">
              <label for="select-gravidade">Psicólogo: </label>
              <ng-select
                [items]="lstPsicologos"
                bindLabel="nomePsicologo"
                bindValue="codigoPsicologo"
                placeholder="Selecione um Psicólogo"
                name="nomePsicologo"
                formControlName="codigoPsicologo"
                (change)="onChangePsicologo()"
                [ngClass]="{'is-invalid': submitted && form.codigoPsicologo.errors}"
              >
              </ng-select>
              <div *ngIf="submitted && form.codigoPsicologo.errors && !this.PodePesquisarSemFiltroEscola" class="alert">
                <div *ngIf="form.codigoPsicologo.errors.required">
                  * Psicólogo obrigatório
                </div>
              </div>
            </div>

            <button type="submit" class="btn-search-filtro" id="btn-search-chamado">
              <img
                src="../../../../assets/img/icons/icon-search.svg"
                alt="Pesquisar Visitas"
                title="Pesquisar Visitas"
              />
            </button>
        </div>
    </section>

    <section class="conteudo-principal">
      <div class="container">
        <div class="conteudo-perfil" *ngIf="this.submitted && this.showPesquisa">
          <h3 class="sub-titulo-dash">
            Listagem de Visitas
          </h3>
          <app-tabulator-table
          [tableData]="lstAvaliacaoFiscal"
          [columnNames]="colunasGrid"
          [tableTitle]="titleExport"
          [tipoRelatorio]="1"
          [orientacaoPortrait]="false"
          ></app-tabulator-table>

        </div>
     </div>
    </section>

</form>
