//Principais
import { Component, Inject, LOCALE_ID, ViewChild } from '@angular/core';
import { DataTableDirective } from "angular-datatables";
import { ADTSettings } from "angular-datatables/src/models/settings";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subject, lastValueFrom } from "rxjs";
//Métodos
import { Diretoria } from 'src/app/shared/models/diretoria/Diretoria';
import { Escola } from 'src/app/shared/models/escola/Escola';
import { Polo } from "src/app/shared/models/polo/Polo";
import { AtribuicaoLoginSed, UserLoginSed } from "src/app/shared/models/sed/UserLogin";
//Serviços
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from "src/app/shared/auth/services/auth.service";
import { ConvivaErrorHandlingService } from "src/app/shared/services/convivaErrorHandlingService";
import { EscolaService } from "src/app/shared/services/escola.service";
import { PsicologoService } from "src/app/shared/services/psicologo.service";

import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { Atividade } from 'src/app/shared/models/psicologo/AtividadePsicologo';
import { DashboardAtividadePOC } from 'src/app/shared/models/psicologo/listar-atividades-POCs';

@Component({
  templateUrl: './dashboard-visitas.component.html',
  styleUrls: ['./dashboard-visitas.component.scss']
})

export class PageDashboardPocVisitasComponent {

  //Variaveis de Login
  public userLogado: UserLoginSed | null;
  public atribuicoesLogin: AtribuicaoLoginSed;

  // Variaveis do Grid DataTable
  public dtOptions: any = {};
  public dtTrigger: Subject<ADTSettings> = new Subject<ADTSettings>();
  @ViewChild(DataTableDirective, { static: false })
  public dtElement: DataTableDirective;
  public isDtInitialized: boolean = false;
  public exibirEscola: boolean = false;

  //Modal
  public showModalConfirmacao: boolean;
  public showModalComentario: boolean;
  public comentarioModal: string;
  public tipoPec: boolean;

  constructor(private spinnerService: NgxSpinnerService,
    private authService: AuthService,
    private psicologoService: PsicologoService,
    private ConvivaErrors: ConvivaErrorHandlingService,
    private toastr: ToastrService,
    private escolaService: EscolaService,
    @Inject(LOCALE_ID) private locale: string,
    private router: Router

  ) {
    this.userLogado = this.authService.getUser();

    if (this.userLogado != null && !this.userLogado.flPsicologo) {
      this.atribuicoesLogin = this.userLogado?.DadosUsuario[0];
    }
  }

  //Forms
  public formDashboard!: FormGroup;
  public get form() { return this.formDashboard.controls; }
  public submitted: boolean;

  //Listagens
  public polos: Polo[] = [];
  public diretorias: Diretoria[] = [];
  public escolas: Escola[] = [];
  public poloLogado: Polo;
  public lstAtividadePOC: DashboardAtividadePOC[] = [];
  public atividadeParaExcluir: number | null = null;


  public async ngOnInit(): Promise<void> {

    ($.fn.dataTable.ext.type.order as any)['date-dd-mm-yyyy-pre'] = (data: string) => {
      const parts = data.split('/');
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);
      return new Date(year, month, day).getTime();
    };

    this.dtOptions = {
      deferRender: true,
      destroy: false,
      processing: true,
      paging: true,
      dom: 'Bfrtip',
      order: [[3, 'asc']],
      columnDefs: [
        { targets: 3, type: 'date-dd-mm-yyyy' }, // Define a coluna como do tipo personalizado
      ],
      language: {
        url: 'https://cdn.datatables.net/plug-ins/1.11.5/i18n/pt-BR.json'
      },
      lengthMenu: [
        [10, 25, 50, -1],
        ['10 linhas', '25 linhas', '50 linhas', 'Tudo']
      ],
      buttons: [
        'pageLength',
        {
          extend: 'print',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 7]
          }
        },
        {
          extend: 'excel',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 7]
          }
        },
        {
          extend: 'pdf',
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 7]
          }
        }
      ],
      // columns: [
      //   { data: 'codigoAtividadePsicologo' },
      //   { data: 'ds_TP_ACAO_ATIVIDADE' },
      //   { data: 'codigoChamado' },
      //   {
      //     data: 'dataAtividadePsicologo',
      //     render: function (data: any, type: any, row: any) {
      //       if (type === 'display' || type === 'filter') {
      //         return moment(data, 'DD/MM/YYYY').format('DD/MM/YYYY'); // Formato de exibição
      //       }
      //       return moment(data, 'DD/MM/YYYY').toDate(); // Formato interno para ordenação
      //     }
      //   },
      //   { data: 'timeAtividadePsicologo' },
      //   { data: 'sT_APROVACAO_PEC' },
      //   { data: 'estrelasAvaliacaoPEC' },
      //   { data: 'professorPOC' },
      //   { data: 'acoes' }
      // ],
    };
    //Formulario de Pesquisa
    this.formDashboard = new FormGroup({
      codigoPolo: new FormControl(null, [Validators.required]),
      codigoDiretoria: new FormControl(null, [Validators.required]),
      codigoEscola: new FormControl(null),
      dataInicio: new FormControl(null),
      dataFim: new FormControl(null)
    });

    if (this.userLogado?.codigoPerfil === 1477) {
      this.formDashboard.get('codigoPolo')?.clearValidators();
      this.formDashboard.get('codigoDiretoria')?.clearValidators();
    } else {
      this.formDashboard.get('codigoPolo')?.setValidators([Validators.required]);
      this.formDashboard.get('codigoDiretoria')?.setValidators([Validators.required]);
    }

     this.formDashboard.get('codigoPolo')?.updateValueAndValidity();
     this.formDashboard.get('codigoDiretoria')?.updateValueAndValidity();

    if(!this.userLogado?.codigoPolo && this.userLogado?.codigoDiretoria && this.userLogado?.codigoDiretoria > 0){
      await this.getPoloPorDiretoria(this.userLogado?.codigoDiretoria);
      if( this.userLogado?.codigoPolo > 0){
        await this.getDiretoriasEscolasPorPolo(true);
      }else
          this.form.codigoDiretoria.disable();
    }

    if (this.userLogado?.flPsicologo) {
      await this.getPoloDiretoriaPsicologo();
    }
    else {
      if (this.atribuicoesLogin.NumeroComportamento == 1) {
        await this.getPolosAdmin();
      }
      if (this.atribuicoesLogin.NumeroComportamento == 2) {
        await this.getPolosPerfilDiretor();
      }
      if (this.atribuicoesLogin.NumeroComportamento == 3 || this.atribuicoesLogin.NumeroComportamento == 4) {
        await this.getPolosPerfilProfessor();
      }
    }
    await this.getDiretoriasEscolasPorPolo(true);
    await this.PesquisarVisitas(true);

    var currentPage = this.userLogado?.currentPage == undefined ? 0 : this.userLogado?.currentPage;

    // Esperar que la DataTable se haya inicializado completamente
    if (this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        this.goToPage(currentPage);
      }).catch((error) => {
        console.error("Error al inicializar la tabla: ", error);
      });
    }

  }

  public OnChangePolo() {

    const codigoPolo = this.formDashboard.get('codigoPolo')?.value;
    if (this.userLogado !== null) {
      this.userLogado.codigoPolo = codigoPolo == null ? 0 : codigoPolo;
      this.authService.setUser(this.userLogado);
    }

    if (codigoPolo != null) {
      this.getDiretoriasPorPolo();
    } else {
      this.zerarDiretoria();
    }
    this.zerarEscola();
  }

  public async getPoloPorDiretoria(codigoDiretoria: number) {
    this.spinnerService.show();
    var response = await lastValueFrom(this.psicologoService.getPolos(codigoDiretoria))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.poloLogado = response.data[0];
    if (this.userLogado) {
      this.userLogado.codigoPolo = response.data[0].codigoPolo == null ? 0 : response.data[0].codigoPolo;
      this.authService.setUser(this.userLogado);
    }
    this.spinnerService.hide();
  }

  public async getDiretoriasPorPolo(diretoriaLogada: boolean = false) {
    if (this.userLogado?.codigoPolo) {
      this.zerarDiretoria();
      this.spinnerService.show();
      var response = await lastValueFrom(this.psicologoService.getDiretoriaPorPolo(this.userLogado!.codigoPolo))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });
      if (!response.isSucess) {
        // this.showPesquisa = false;
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      this.diretorias = response.data;
      this.form.codigoDiretoria.enable();
      this.ativaDiretoria();
      this.getEscolasPorDiretoria();
      this.spinnerService.hide();
    }

    if (this.userLogado !== null) {
      this.userLogado.codigoDiretoria = this.userLogado.codigoDiretoria == null ? 0 : this.userLogado.codigoDiretoria;
      this.authService.setUser(this.userLogado);
    }
  }
  public zerarDiretoria() {
    this.diretorias = [];
    this.formDashboard.get('codigoDiretoria')?.patchValue(null);
  }
  public ativaDiretoria() {
    const diretoria = this.diretorias.filter(s => s.codigoDiretoria == this.userLogado?.codigoDiretoria);

    if (this.userLogado?.codigoDiretoria && this.userLogado?.codigoDiretoria > 0 && diretoria.length > 0) {
      this.formDashboard.get('codigoDiretoria')?.setValue(this.userLogado?.codigoDiretoria);

    }

    const polo = this.polos.filter(s => s.codigoPolo == this.userLogado?.codigoPolo);
    if (this.userLogado?.codigoPolo && this.userLogado?.codigoPolo > 0 && polo.length > 0) {
      this.formDashboard.get('codigoPolo')?.setValue(this.userLogado?.codigoPolo);
    }

  }
  public async getEscolasPorDiretoria(inicio = false) {
    if (this.userLogado !== null && !inicio) {
      this.userLogado.codigoEscola = this.form.codigoDiretoria.value == null ? 0 : this.userLogado.codigoEscola;
      this.authService.setUser(this.userLogado);
    }
    if (this.userLogado?.codigoDiretoria && this.userLogado.codigoDiretoria > 0) {
      this.form.codigoDiretoria.setValue(this.userLogado?.codigoDiretoria);
    }
    if (this.form.codigoDiretoria.value) {

      this.spinnerService.show();
      this.zerarEscola();
      var response = await lastValueFrom(this.escolaService.getEscolasPorCodigoDiretoria(this.form.codigoDiretoria.value))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });
      if (!response.isSucess) {
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      this.escolas = response.data;
      this.ativaEscola();
      this.spinnerService.hide();
    }

  }
  public zerarEscola() {
    this.escolas = [];
    this.formDashboard.get('codigoEscola')?.patchValue(null);
  }
  public ativaEscola() {
    if (this.escolas != undefined && this.userLogado?.codigoEscola != 0) {
      const escola = this.escolas.find(s => s.codigoEscola == this.userLogado?.codigoEscola)
      if (escola != undefined) {
        this.formDashboard.get('codigoEscola')?.setValue(this.userLogado?.codigoEscola);
      }

    }
  }
  public async trocaDiretoria() {
    const codigoDiretoria = this.formDashboard.get('codigoDiretoria')?.value;
    if (this.userLogado !== null && codigoDiretoria != null) {
      this.userLogado.codigoDiretoria = codigoDiretoria == null ? 0 : codigoDiretoria;
      this.authService.setUser(this.userLogado);
    }
    if (codigoDiretoria != null)
      await this.getEscolasPorDiretoria();
    else
      this.escolas = [];
    this.form.codigoEscola.setValue(null);
  }

  onChangeEscola() {
    const codigoEscola = this.formDashboard.get('codigoEscola')?.value;
    if (this.userLogado !== null && codigoEscola != null) {
      this.userLogado.codigoEscola = codigoEscola == null ? 0 : codigoEscola;
      this.authService.setUser(this.userLogado);
    }
  }
  public async getPolos() {
    this.spinnerService.show();

    var response = await lastValueFrom(this.psicologoService.getPolos())
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });

    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }

    this.polos = response.data;
    this.spinnerService.hide();
  }
  public async getDiretoriasEscolasPorPolo(inicio = false) {
    this.spinnerService.show();

    if (this.userLogado?.codigoPolo && this.userLogado?.codigoPolo > 0) {

      var response = await lastValueFrom(this.psicologoService.getDiretoriaEscolasPorPolo(this.userLogado!.codigoPolo))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });

      if (!response.isSucess) {
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      this.diretorias = response.data.diretorias;
      await this.getEscolasPorDiretoria(inicio);
      this.form.codigoDiretoria.enable();
      this.ativaDiretoria();
    }

    this.spinnerService.hide();
  }
  public async PesquisarVisitas(flagInit: boolean = false) {

    this.spinnerService.show();


    if (this.userLogado !== null && !flagInit) {
      this.userLogado.codigoEscola = this.form.codigoEscola.value == null ? 0 : this.form.codigoEscola.value;
      this.userLogado.codigoDiretoria = this.form.codigoDiretoria.value == null ? 0 : this.form.codigoDiretoria.value;
      this.userLogado.codigoPolo = this.form.codigoPolo.value == null ? 0 : this.form.codigoPolo.value;
      this.authService.setUser(this.userLogado);

    }

    this.submitted = true;
    if (this.formDashboard.invalid) {
      this.spinnerService.hide();
      return;
    }

    const parametros = this.SetParametrosAtividade();
    var response = await lastValueFrom(this.psicologoService.getDashboardAtividadePOCs(parametros)).catch((err) => {
      this.spinnerService.hide();
      return this.ConvivaErrors.handleResultError(err);
    });
    if (!response.isSucess) {
      this.toastr.error(response.message);
    }
    this.spinnerService.hide();
    this.lstAtividadePOC = response.data;


    this.lstAtividadePOC = response.data;

    if (this.lstAtividadePOC.length == 0) {
      this.toastr.warning("Não existem atividades para essa pesquisa");
      if (this.dtElement.dtInstance != null) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next(this.dtOptions);


        });
      }
    }
    else {

      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next(this.dtOptions);

        });
      } else {
        this.isDtInitialized = true;

        if (this.dtElement.dtInstance != null) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          });
        }
        this.dtTrigger.next(this.dtOptions);
      }
    }

    var currentPage = this.userLogado?.currentPage == undefined ? 0 : this.userLogado?.currentPage;
    if (this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        this.goToPage(currentPage);
      }).catch((error) => {
        console.error("Error al inicializar la tabla: ", error);
      });
    }

  }
  public SetParametrosAtividade(): Atividade {
    const atividade = new Atividade();
    atividade.codigoPolo = this.form?.codigoPolo.value;
    atividade.codigoDiretoria = this.form?.codigoDiretoria.value;
    atividade.codigoEscola = this.form?.codigoEscola.value;
    atividade.dataInicio = this.form?.dataInicio.value;
    atividade.dataFim = this.form?.dataFim.value;
    return atividade;
  }
  public getDate(data?: Date) {
    if (data == undefined) return;
    return formatDate(data, 'dd/MM/yyyy', this.locale);
  }

  public AbreFechaModalConfirmacao(codigoAtividade?: number) {
    if (codigoAtividade !== undefined) {
      this.atividadeParaExcluir = codigoAtividade;
    }
    this.showModalConfirmacao = !this.showModalConfirmacao;
  }

  public async ExcluirAtividadePsicologo() {
    this.getActivePage();
    if (this.atividadeParaExcluir !== null) {
      this.spinnerService.show();

      var response = await lastValueFrom(this.psicologoService.setExcluirAtividadePOC(this.atividadeParaExcluir))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });
      if (!response.isSucess) {
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }

      this.atividadeParaExcluir = null;
      this.toastr.success("Atividade excluida com sucesso!");
      this.atividadeParaExcluir = null;
      this.PesquisarVisitas();
      this.AbreFechaModalConfirmacao();
    }
  }
  public AbrirAprovacao(item: any) {
    this.getActivePage();
    this.router.navigate(['/aprovacao-visitas-professor-poc/', item.codigoAtividadePsicologo]);
  }

  public AbrirVisualizar(item: any) {
    this.getActivePage();
    this.router.navigate(['/visualizar-atividade-professor-poc/', item.codigoAtividadePsicologo]);
  }

  public async getPoloDiretoriaPsicologo() {

    var response = await lastValueFrom(this.psicologoService.getPoloPsicologo(this.userLogado!.cpf)).catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });

    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.polos = [response.data];
    this.form.codigoPolo.setValue(this.polos[0].codigoPolo);
    this.form.codigoPolo.disable();

    await this.getDiretoriasPorPolo();
    // await this.getPocsPorPolo();
  }

  public async getPolosAdmin() {

    this.spinnerService.show();
    var response = await lastValueFrom(this.psicologoService.getPolos())

    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }

    this.polos = response.data;
    this.spinnerService.hide();

    if (this.userLogado?.codigoPolo != 0) {
      this.ativaPolo();
    }
    this.spinnerService.hide();
    // await this.getPocsPorPolo();
  }

  public async getPolosPerfilDiretor() {
    this.spinnerService.show();
    var response = await lastValueFrom(this.psicologoService.getPolos(this.userLogado?.DadosUsuario[0].CodigoDiretoria)).catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });

    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.polos = response.data;
    this.form.codigoPolo.setValue(this.polos[0].codigoPolo);
    this.form.codigoPolo.disable();

    if (this.userLogado?.DadosUsuario[0].CodigoDiretoria != 0) {
      var todasDiretoriasDoUsuario: Diretoria[] = [];
      this.userLogado?.DadosUsuario.forEach(item => {
        var diretoria = new Diretoria();
        diretoria.codigoDiretoria = item.CodigoDiretoria;
        diretoria.nomeDiretoria = item.NomeDiretoria;
        todasDiretoriasDoUsuario.push(diretoria);
      });
      this.diretorias = todasDiretoriasDoUsuario;

      if (this.diretorias.length == 1) {
        this.form.codigoDiretoria.setValue(this.userLogado?.DadosUsuario[0].CodigoDiretoria);
      } else {
        this.form.codigoDiretoria.enable();
      }
      this.form.codigoEscola.enable();
      await this.getEscolasPorDiretoria();
      // await this.getPocsPorPolo();
    }

    this.spinnerService.hide();
  }

  public async getPolosPerfilProfessor() {
    this.spinnerService.show();
    var response = await lastValueFrom(this.psicologoService.getPolos(this.userLogado?.DadosUsuario[0].CodigoDiretoria)).catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });

    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.polos = response.data;
    this.form.codigoPolo.setValue(this.polos[0].codigoPolo);
    this.form.codigoPolo.disable();
    let diretoriasEx:Diretoria[] = [];
    let escolasEx:Escola[] = [];
    var dadosUsuario = this.userLogado?.DadosUsuario[0];
    if (dadosUsuario?.CodigoDiretoria != 0 && dadosUsuario?.CodigoEscola != 0) {
      this.userLogado?.DadosUsuario.forEach(item => {
        var diretoria = new Diretoria();
        diretoria.codigoDiretoria = item.CodigoDiretoria;
        diretoria.nomeDiretoria = item.NomeDiretoria;
        diretoriasEx.push(diretoria);

        var escola = new Escola();
        escola.codigoEscola = item.CodigoEscola;
        escola.nomeEscola = item.NomeEscola;
        escolasEx.push(escola);
      });

      this.diretorias = diretoriasEx;
      this.escolas = escolasEx;

      this.form.codigoDiretoria.setValue(this.userLogado?.codigoDiretoria);
      if (this.diretorias.length === 1 ) {
        this.form.codigoDiretoria.disable();
      }

      this.form.codigoEscola.setValue(this.userLogado?.codigoEscola);
      if (this.escolas.length === 1) {
        this.form.codigoEscola.disable();
      }
    }
    this.spinnerService.hide();
    // await this.getPocsPorPolo();
  }

  public ativaPolo() {
    if (this.userLogado?.codigoPolo != undefined)
      this.formDashboard.get('codigoPolo')?.setValue(this.userLogado?.codigoPolo);

    if (this.userLogado !== null && this.userLogado)
      this.authService.setUser(this.userLogado);
  }

  public getAprovacaoStatus(status: number | null): string {
    if (status === 1) {
      return 'Aprovado';
    } else if (status === 2) {
      return 'Aprovado com melhoria';
    } else if (status === 3) {
      return 'Não Aprovado';
    } else if (status === null){
      return 'Não Analisado';
    }
    return '';
  }

  public displayComentario(comentario: string, tipoPEC: boolean){
    if (comentario === null) {
      this.toastr.error('Sem comentário');
    }
    this.showModalComentario = true;
    this.tipoPec = tipoPEC;
    this.comentarioModal = comentario;
  }
  public FecharModalComentario() {
    this.showModalComentario = false;
  }

  // Novo método para obter a página ativa
  public getActivePage(): void {
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        const activePage = dtInstance.page();
        if (this.userLogado) {
          this.userLogado.currentPage = activePage;
          this.authService.setUser(this.userLogado);
        }
        // console.log('Página ativa:', activePage);
      });
    }
  }

  // Novo método para mover-se para uma página específica
  goToPage(page: number) {
    // Si la tabla no está inicializada, espera 100 ms y vuelve a intentar.
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Cambio de página después de un pequeño retraso
        setTimeout(() => {
          // console.log("Cambiando a la página:", page);
          dtInstance.page(page).draw('page');
        }, 500); // Aquí el retraso es de 500ms
      }).catch((error) => {
        console.error("Error al inicializar la tabla: ", error);
      });
    } else {
      // console.log("Esperando que la DataTable se inicialice...");
      setTimeout(() => this.goToPage(page), 500); // Intentar de nuevo después de 500ms
    }
  }

}
