<style>
  .btn_terciario{
  background: rgba(15, 123, 166, 0.06);
  color: var(----color-blue, #0F7BA6);
  padding: 0.9rem 3.5rem;
  border-radius: 2rem;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2rem;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  align-items: center;
  &:hover{
    color: var(--color-white);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), var(--color-blue);
  }
}
</style>
<header-govbr></header-govbr>
<!-- CABEÇALHO -->
<header>
  <section class="container">
    <!-- HEADER MENU E LOGO CONVIVA -->
    <div class="item-menu">
      <a
        href="javascript:void(0)"
        id="btn-abrir-menu"
        class="icon-nav"
        (click)="ExpandirMenu()"
        title="Menu"
      >
      </a>

      <a href="javascript:void(0)" routerLink="/" class="logo-brasao">
        <img src="../../../../assets/img/logo-coviva-sp-brasao.svg" alt="Conviva" title="Conviva" />
      </a>
    </div>

    <!-- PERFIL E NOTIFICAÇÃO -->
    <div class="conteudo-perfil">
      <modal-alterar-perfil
        *ngIf="this.userLogado!.perfis.length > 1"
      ></modal-alterar-perfil>

      <div
        class="icon-add-chamado"
        *ngIf="this.userLogado?.funcionalidades?.includes(2)"
      >
        <a
          href="javascript:void(0)"
          routerLink="/cadastrar-chamados"
          class="add-chamado"
          title="Adicionar Chamado"
        >
          Adicionar Chamado
        </a>
      </div>

      <sino-component
        *ngIf="this.userLogado?.funcionalidades?.includes(16)"
      ></sino-component>

      <a href="javascript:void(0)" routerLink="/perfil" class="perfil">
        <img
          src="../../../../assets/img/elemento/avatarPerfilAluno.jpg"
          alt="Perfil"
          title="Perfil"
        />
      </a>

      <a href="javascript:void(0)"
        class="btn-logout"
        title="Sair"
        alt="Sair"
        (click)="logout()"
      >
        Sair
      </a>
    </div>
  </section>
</header>

<!-- MENU LATERAL -->
<aside class="sidebar-menu">
  <nav>
    <div class="perfil-menu">
      <a href="javascript:void(0)" routerLink="/perfil" class="perfil">
        <img
          src="../../../../assets/img/elemento/avatarPerfilAluno.jpg"
          alt="Perfil"
          title="Perfil"
        />
      </a>
      <a class="sidebar-menu-fechar" title="Fechar" (click)="FecharMenu()"> </a>
    </div>
    <div class="identidade-menu">
      <p>
        <b>Olá,</b>
        {{
          this.userLogado?.flPsicologo
            ? "Psicólogo"
            : this.atribuicoesLogin.NomePerfil
        }}<br />
        <b>{{
          this.userLogado?.flPsicologo
            ? this.userLogado?.nome
            : this.atribuicoesLogin.Nome
        }}</b>
      </p>
    </div>

    <ul class="link-list">
      <!-- class="active"  -->
      <li>
        <a
        *ngIf="this.userLogado?.codigoPerfil != 1656"
          href="javascript:void(0)"
          routerLink="/"
          alt="Home"
          title="Home"
          (click)="FecharMenu()"
        >
          Home
        </a>
      </li>

      <li *ngIf="this.userLogado?.codigoPerfil != 1656"><!-- Ocorrência -->
        <input type="checkbox" checked class="nav-dropdown" id="nav-dropdown-ocorrencia">
        <label for="nav-dropdown-ocorrencia">
          Ocorrência
        </label>
        <div class="opcoes-dropdown-itens">
          <a
            *ngIf="this.userLogado?.funcionalidades?.includes(2)"
            href="javascript:void(0)"
            routerLink="/cadastrar-chamados"
            title="Adicionar Chamado"
            routerLinkActive="active"
            (click)="FecharMenu()"
            >
            Adicionar Chamado
          </a>
          <a
            *ngIf="this.userLogado?.funcionalidades?.includes(2)"
            href="javascript:void(0)"
            routerLink="/adicionar-nao-ocorrencia"
            title="Adicionar Chamado"
            routerLinkActive="active"
            (click)="FecharMenu()"
          >
            Adicionar Não Ocorrência
          </a>
          <a
            href="javascript:void(0)"
            routerLink="/buscar-alunos"
            title="Buscar Estudantes com Ocorrências"
            routerLinkActive="active"
            (click)="FecharMenu()"
          >
            Buscar Estudantes com Ocorrências
          </a>

          <a
            href="javascript:void(0)"
            routerLink="/listar-chamados"
            title="Estudantes com Ocorrências Registradas"
            routerLinkActive="active"
            (click)="FecharMenu()"
          >
            Estudantes com Ocorrências Registradas
          </a>
          <a
            href="javascript:void(0)"
            routerLink="/chamados-list"
            title="Tela de Chamados"
            routerLinkActive="active"
            (click)="FecharMenu()"
          >
            Tela de Chamados
          </a>
        </div>
      </li><!-- End Ocorrência -->

      <li><!-- Painéis -->
        <input type="checkbox" checked class="nav-dropdown" id="nav-dropdown-paineis">
        <label for="nav-dropdown-paineis">
          Painéis
        </label>
        <div class="opcoes-dropdown-itens">

          <!--
          <a
            *ngIf="this.userLogado?.codigoPerfil != 1656"
            href="javascript:void(0)"
            routerLink="/dashboard-visitas-psicologo-fiscal"
            title="Painel Aprovação Fiscal"
            routerLinkActive="active"
            (click)="FecharMenu()"
          >
            Painel Aprovação Fiscal
          </a> -->
          <a
          href="javascript:void(0)"
          routerLink="/avaliacao-visita-fiscal"
          title="Painel Avaliação Fiscal"
          routerLinkActive="active"
          (click)="FecharMenu()"
          *ngIf="this.userLogado?.funcionalidades?.includes(23)"
          >
          Painel de Avaliação do Fiscal
          </a>
          <a
            href="javascript:void(0)"
            routerLink="/dashboard-visitas-poc"
            title="Painel de Atividade do POC"
            routerLinkActive="active"
            (click)="FecharMenu()"
            *ngIf="this.userLogado?.funcionalidades?.includes(22) "
            >
            Painel de Atividade do POC
          </a>
          <a
            href="javascript:void(0)"
            routerLink="/dashboard-visitas-psicologo"
            title="Painel de Atividade do Psicólogo"
            routerLinkActive="active"
            (click)="FecharMenu()"
            *ngIf="this.userLogado?.funcionalidades?.includes(24) "
            >
            Painel de Atividade do Psicólogo
          </a>
          <a
          *ngIf="this.userLogado?.codigoPerfil != 1656"
            href="javascript:void(0)"
            routerLink="/dashboard"
            title="Painel Geral"
            routerLinkActive="active"
            (click)="FecharMenu()"
          >
            Painel Geral
          </a>
          <a
          *ngIf="this.userLogado?.codigoPerfil != 1656"
            href="javascript:void(0)"
            routerLink="/dashboard-nao-ocorrencia"
            title="Painel de Não Ocorrência"
            routerLinkActive="active"
            (click)="FecharMenu()"
          >
            Painel de Não Ocorrência
          </a>
          <a
            href="javascript:void(0)"
            routerLink="/dashboard-reincidencia"
            title="Painel de Reincidência"
            routerLinkActive="active"
            (click)="FecharMenu()"
            *ngIf="this.userLogado?.funcionalidades?.includes(2)"
            >
            Painel de Reincidência
          </a>
        </div>
      </li><!-- End Painéis -->

      <li *ngIf="this.userLogado?.funcionalidades?.includes(19)"><!-- POC -->
        <input type="checkbox" checked class="nav-dropdown" id="nav-dropdown-poc">
        <label for="nav-dropdown-poc">
          POC
        </label>
        <div class="opcoes-dropdown-itens">
          <a
            href="javascript:void(0)"
            routerLink="/filtro-visitas-professor"
            title="Visita POC"
            routerLinkActive="active"
            (click)="FecharMenu()"
            *ngIf="this.userLogado?.funcionalidades?.includes(19)"
          >
            Registro da Atividade do POC
          </a>

        </div>
      </li><!-- End POC -->

      <li *ngIf="this.userLogado?.codigoPerfil != 1656"><!-- Psicólogos -->
        <input type="checkbox" checked class="nav-dropdown" id="nav-dropdown-psicologos">
        <label for="nav-dropdown-psicologos">
          Psicólogos
        </label>
        <div class="opcoes-dropdown-itens">
          <a
            href="javascript:void(0)"
            title="Agendamento Psicólogos"
            routerLinkActive="active"
            (click)="AbrirModalAgendamento()"
            *ngIf="this.userLogado?.funcionalidades?.includes(1)"
          >
            Agendamento Psicólogos
          </a>
          <a
            href="javascript:void(0)"
            routerLink="/atendimento-psicologo"
            title="Atendimento Psicologos"
            routerLinkActive="active"
            (click)="FecharMenu()"
            *ngIf="this.userLogado?.funcionalidades?.includes(1)"
          >
            Atendimento Psicólogos
          </a>
          <!-- <a
            href="javascript:void(0)"
            routerLink="/editar-visita-psicologo"
            title="Editar Visita Psicólogos"
            routerLinkActive="active"
            (click)="FecharMenu()"
            *ngIf="this.userLogado?.funcionalidades?.includes(19)"
          >
            Editar Visita Psicólogos
          </a>
          <a
            href="javascript:void(0)"
            routerLink="/manutencao-psicologo"
            title="Manutenção Psicólogo"
            routerLinkActive="active"
            (click)="FecharMenu()"
            *ngIf="
            this.userLogado?.funcionalidades?.includes(12) ||
            this.userLogado?.funcionalidades?.includes(13) ||
            this.userLogado?.funcionalidades?.includes(14) ||
            this.userLogado?.funcionalidades?.includes(15)
            "
          >
            Manutenção Psicólogo
          </a>-->
          <a
            href="javascript:void(0)"
            routerLink="/filtro-visitas-psicologo"
            title="Visita Psicólogos"
            routerLinkActive="active"
            (click)="FecharMenu()"
            *ngIf="this.userLogado?.funcionalidades?.includes(20)"
          >
            Registro da Atividade do Psicólogo
          </a>

      <!-- <li *ngIf="this.userLogado?.funcionalidades?.includes(19)">
        <a href="javascript:void(0)"
           routerLink="/editar-visita-psicologo"
           title="Editar Visita Psicólogos"
           routerLinkActive="active"
           (click)="FecharMenu()">
          Editar Visita Psicólogos
        </a>
      </li> -->
      </div>
      <li *ngIf="this.userLogado?.funcionalidades?.includes(10)">
        <a
          href="javascript:void(0)"
          routerLink="/perfil-manage"
          title="Adicionar Função ao Perfil"
          routerLinkActive="active"
          (click)="FecharMenu()"
        >
          Adicionar Função ao Perfil
        </a>
      </li>
      <!-- <li>
        <a href="javascript:void(0)" routerLink="/perfil" title="Perfil" routerLinkActive="active" (click)="FecharMenu()">
          Perfil
        </a>
      </li> -->
      <!-- @if(userLogado?.codigoPerfil === 1477){
        <li>
          <a
            href="javascript:void(0)"
            routerLink="/gerenciamento-email"
            title="Gerenciamento de E-mails"
            routerLinkActive="active"
            (click)="FecharMenu()"
          >
            Gerenciamento de E-mails
          </a>
        </li>
      } -->
      <li
        *ngIf="
          this.userLogado?.funcionalidades?.includes(17)
        "
      >
        <a
          href="javascript:void(0)"
          routerLink="/consolidado"
          title="Perfil"
          routerLinkActive="active"
          (click)="FecharMenu()"
        >
          Relatorios
        </a>
      </li>

      <li>
        <a href="javascript:void(0)" title="Sair" (click)="logout()"> Sair </a>
      </li>

      <li>
        <p
          style="
            margin-top: 20px;
            font-size: 12px !important;
            text-align: center;
          "
        >
          <b style="font-size: 12px !important">Versão:</b> {{ this.versao }}
        </p>
      </li>

    </ul>
  </nav>
</aside>

<app-modal *ngIf="showModalAgendamento">
  <div class="container-conviva form-registro modal-envolvidos">
    <div>
      <h1 class="titulo-page">Agendamentos em Aberto</h1>
    </div>
    <div class="descricao-modal-excluir">
      <span
        >Existem {{ this.quantidadeAgendamentosAbertos }} agendamentos em
        aberto.</span
      >
    </div>
    <div class="container-btns">
      <input
        type="button"
        (click)="FecharModalPopUp()"
        class="btn_outline"
        value="Fechar"
      />
    </div>
  </div>
</app-modal>

<app-modal *ngIf="showModalAgendamentoPsicologo">
  <div class="container-conviva form-registro modal-envolvidos modal-visita-psicologo">
    <div class="header-modal-filtro">
      <h1 class="titulo-page">Deseja agendar:</h1>
      <a class="btn-fechar-modal" (click)="FecharModalAgendamento()">fechar</a>
    </div>

    <div class="time-line conteudo-registro modal-conteudo-busca-aluno">
      <div class="container-btns">
        <input type="button"  [routerLink]="['/agendamento-visita-prevencao']" class="btn_outline" value="Prevenção" (click)="FecharModalAgendamento()"/>
        <input type="button" class="btn_primary" [routerLink]="['/agendamento-psicologos']" value="Acolhimento" (click)="FecharModalAgendamento()" />
        <input type="button" (click)="FecharModalAgendamento()" class="btn_terciario" value="Fechar" />
      </div>
    </div>
  </div>
</app-modal>
