import { Component, inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom } from "rxjs";
import { AuthService } from "src/app/shared/auth/services/auth.service";
import { TipoPessoa } from "src/app/shared/enums/envolvidos/TipoPessoa";
import { TurmaAluno } from "src/app/shared/models/aluno/TurmaAluno";
import { Envolvido } from "src/app/shared/models/chamados/envolvido";
import { DefaultResponse } from "src/app/shared/models/DefaultResponse";
import { Diretoria } from "src/app/shared/models/diretoria/Diretoria";
import { Aluno } from "src/app/shared/models/envolvidos/aluno";
import { Funcionario } from "src/app/shared/models/envolvidos/funcionario";
import { Escola } from "src/app/shared/models/escola/Escola";
import { Polo } from "src/app/shared/models/polo/Polo";
import { AgendamentoAtividadePrevencao } from "src/app/shared/models/psicologo/AgendamentoAtividadePrevencao";
import { Psicologo } from "src/app/shared/models/psicologo/Psicologo";
import { UserLoginSed } from "src/app/shared/models/sed/UserLogin";
import { Turma } from "src/app/shared/models/turma/Turma";
import { AlunoService } from "src/app/shared/services/alunos.service";
import { ConvivaErrorHandlingService } from "src/app/shared/services/convivaErrorHandlingService";
import { EscolaService } from "src/app/shared/services/escola.service";
import { FuncionarioService } from "src/app/shared/services/funcionarios.service";
import { PsicologoService } from "src/app/shared/services/psicologo.service";
import { GenericValidator } from "src/app/shared/validations/genericValidators";


@Component({
  templateUrl: './agendamento-prevencao.component.html',
  styleUrls: ['./agendamento-prevencao.component.scss']
})
export class AgendamentoPrevencaoComponent implements OnInit {

  //Injeções
  psicologoService = inject(PsicologoService);
  ConvivaErrors = inject(ConvivaErrorHandlingService);
  toastr = inject(ToastrService);
  spinnerService = inject(NgxSpinnerService);
  authService = inject(AuthService);
  escolaService = inject(EscolaService);
  router = inject(Router);
  funcionarioService = inject(FuncionarioService);
  alunoService = inject(AlunoService);
  genericValidate = inject(GenericValidator);
  userLogado: UserLoginSed | null;

  tabulatorColumns = [
    { title: 'Código Turma', field: 'codigoTurma', hozAlign: "center", headerHozAlign: "center" },
    { title: 'Numero Classe', field: 'numeroClasse', hozAlign: "center", headerHozAlign: "center" },
    { title: 'Tipo de Ensino', field: 'nomeTipoEnsino', hozAlign: "center", headerHozAlign: "center" },
    { title: 'Numero de Serie', field: 'numeroSerie', hozAlign: "center", headerHozAlign: "center" },
    { title: 'Descrição Turma', field: 'descricaoTurma', width: 200, hozAlign: "center", headerHozAlign: "center" },
    {
      title: 'Ações', field: 'visualizar', hozAlign: "center", formatter: function (cell: any, formatterParams: any, onRendered: any) {
        return `     <label>
                        <input
                          type="checkbox"
                          class="checkbox"
                        /><i></i>
                      </label>`;
      }, headerHozAlign: "center",
      cellClick: (e: any, cell: any) => {
        const turma = cell.getRow().getData();
        const target = e.target as HTMLElement;
        if (target && target.matches('input.checkbox')) {
          this.onCheckTurma(turma.codigoTurma);
        }
      }
    }]


  constructor() {
    this.userLogado = this.authService.getUser();
  }

  async ngOnInit(): Promise<void> {


    this.formAgendamento = new FormGroup({
      codigoPolo: new FormControl(null, [Validators.required]),
      codigoDiretoria: new FormControl(null, [Validators.required]),
      nomeDiretoria: new FormControl(null),
      codigoEscola: new FormControl(null, [Validators.required]),
      nomeEscola: new FormControl(null),
      codigoPsicologo: new FormControl(null, [Validators.required]),
      dataVisita: new FormControl(null, [Validators.required]),
      codigoAtividade: new FormControl(null, [Validators.required]),
      codigoAcaoPreventiva: new FormControl(null),
      tipoGrupo: new FormControl(null, [Validators.required]),
    });


    if (this.userLogado?.flPsicologo) {
      await this.getPoloDiretoriaPsicologo();
    }
    else {
      if (this.userLogado?.NumeroComportamento == '1') {
        await this.getPolosAdmin();
      }
      if (this.userLogado?.NumeroComportamento == '2') {
        await this.getPolosPerfilDiretor();
      }
      if (this.userLogado?.NumeroComportamento == '3' ||
        this.userLogado?.NumeroComportamento == '4') {
        await this.getPolosPerfilProfessor();
      }
    }

    if (this.userLogado?.codigoPolo != 0 && this.userLogado?.codigoPolo != undefined) {
      await this.getDiretoriasPorPolo();
      await this.getEscolasPorDiretoria();
    }
    await this.getPsicologosPolo();

    this.form.codigoAtividade.setValue(2);
    this.form.codigoAtividade.disable();
    await this.mostrarEnvolvidos();
  }

  validaBuscaRA: boolean;
  atividadeRealizadaSelecionado?: string;
  polos: Polo[] = [];
  diretorias: Diretoria[] = [];
  escolas: Escola[] = [];
  escolasModalAlunos: Escola[] = [];
  lstPsicologos: Psicologo[] = [];

  alunosSelecionados: Aluno[] = [];
  lstTurmas: Turma[] = [];
  lstTurmasSelecionadas: Turma[] = [];

  lstTurmaAluno: TurmaAluno[] = [];
  lstFuncionariosSelecionados: Funcionario[] = [];
  mostrarDivTurmas = false;
  mostrarDivEquipeEscolar = false;
  mostrarDivOutros = false;
  submitted: Boolean;
  showModal: boolean = false;
  submittedModal: boolean = false;
  showModalAluno: boolean = false;
  showModalEquipeEscolar = false;
  showModalOutros = false;
  showModalExcluirEnvolvido = false;
  mostrarAlunos = false;
  mostrarGrupos = false;
  mostrarProffisionais = false;
  showDropAuxiliar = false;
  showDropFuncionarios = false;
  showGridTurma = false;
  aluno = <Aluno>{};
  duracaoVisita = 15;
  lstOutros: string[] = [];
  lstAtividadeRealizada = [{ id: 1, name: "Conversa/atividade individual com estudante" },
  { id: 2, name: "Conversa/atividade em grupo" },
    // { id: 3, name: "Conversa/atividade com profissionais" }
  ];
  public lstGrupos = [
    { id: 1, name: "Turmas participantes da atividade" },
    { id: 2, name: "Equipe escolar" },
    { id: 3, name: "Outros" }];
  public envolvidoExclusao?: Envolvido;
  public descricaoExclusao?: string;
  public lstFuncionarios: Funcionario[] = [];
  public tipoPessoaAdd = [{
    descricao: 'Outros',
    id: 1
  }, {
    descricao: 'Profissional',
    id: 2
  }];
  public alunos: Aluno[] = [];

  changeTipoPessoaModal() {
    this.showDropFuncionarios = false;
    this.lstFuncionarios = [];
  }



  formAgendamento!: FormGroup;
  formModalEquipeEscolar!: FormGroup;
  formModal!: FormGroup;
  formModalOutros!: FormGroup;
  formModalAluno!: FormGroup;
  public get formOutros() { return this.formModalOutros.controls; }
  public get form() { return this.formAgendamento.controls; }
  public get formModalControls() { return this.formModal.controls; }
  public get formEquipeEscolar() { return this.formModalEquipeEscolar.controls; }
  public get modalFormAluno() { return this.formModalAluno.controls; }




  public async getPoloDiretoriaPsicologo() {

    var response = await lastValueFrom(this.psicologoService.getPoloPsicologo(this.userLogado!.cpf)).catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });

    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.polos = [response.data];
    this.form.codigoPolo.setValue(this.polos[0].codigoPolo);
    this.form.codigoPolo.disable();

    await this.getDiretoriasPorPolo();
    await this.getPsicologosPolo();
  }

  public async getDiretoriasPorPolo(diretoriaLogada: boolean = false) {
    if (this.userLogado?.codigoPolo) {
      this.zerarDiretoria();
      this.spinnerService.show();
      var response = await lastValueFrom(this.psicologoService.getDiretoriaPorPolo(this.userLogado!.codigoPolo))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });
      if (!response.isSucess) {
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      await this.getEscolasPorDiretoria();
      await this.getPsicologosPolo();
      this.diretorias = response.data;
      this.form.codigoDiretoria.enable();
      this.ativaDiretoria();
      this.spinnerService.hide();
    }

    if (this.userLogado !== null) {
      this.userLogado.codigoDiretoria = this.userLogado.codigoDiretoria == null ? 0 : this.userLogado.codigoDiretoria;
      this.authService.setUser(this.userLogado);
    }
  }

  public zerarDiretoria() {
    this.diretorias = [];
    this.formAgendamento.get('codigoDiretoria')?.patchValue(null);
  }

  public async getEscolasPorDiretoria() {
    if (this.userLogado !== null) {
      this.userLogado.codigoEscola = this.userLogado.codigoEscola == null ? 0 : this.userLogado.codigoEscola;
      this.authService.setUser(this.userLogado);
    }

    if (this.userLogado?.codigoDiretoria) {
      this.spinnerService.show();
      this.zerarEscola();
      var response = await lastValueFrom(this.escolaService.getEscolasPorCodigoDiretoria(this.userLogado!.codigoDiretoria))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });
      if (!response.isSucess) {
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      this.escolas = response.data;
      this.ativaEscola();
      this.spinnerService.hide();
    }
  }

  public zerarEscola() {
    this.escolas = [];
    this.formAgendamento.get('codigoEscola')?.patchValue(null);
  }

  public async getPsicologosPolo() {
    if (this.userLogado?.codigoPerfil == 1634) { //Codigo Perfil Psicologo
      this.spinnerService.show();
      var response = await lastValueFrom(
        this.psicologoService.getPsicologoPorCPF(this.userLogado.cpf))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });
      if (!response.isSucess) {
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      this.spinnerService.hide();
      this.lstPsicologos = [];
      var psicologo: Psicologo = new Psicologo;
      psicologo.nomePsicologo = response.data.nome;
      psicologo.codigoPsicologo = response.data.codigoPsicologo;
      this.lstPsicologos.push(psicologo);
    }
    else {
      this.spinnerService.show();
      var response = await lastValueFrom(this.psicologoService.getPsicologosPorPolo(this.form.codigoPolo.value))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });
      if (!response.isSucess) {
        this.toastr.error(response.message);
        this.spinnerService.hide();
        return;
      }
      this.spinnerService.hide();
      this.lstPsicologos = response.data;
    }
  }

  public ativaEscola() {
    if (this.escolas != undefined && this.userLogado?.codigoEscola != 0) {
      const escola = this.escolas.find(s => s.codigoEscola == this.userLogado?.codigoEscola)
      if (this.userLogado?.codigoEscola != undefined && escola != undefined) {
        this.form.codigoEscola.setValue(this.userLogado?.codigoEscola);
      }
    }
  }

  public ativaDiretoria() {
    if (this.diretorias != undefined && this.userLogado?.codigoDiretoria != 0) {
      const diretoria = this.diretorias.find(s => s.codigoDiretoria == this.userLogado?.codigoDiretoria)
      if (this.userLogado?.codigoDiretoria != undefined && diretoria != undefined) {
        this.form.codigoDiretoria.setValue(this.userLogado?.codigoDiretoria);
      }
    }

    if (this.userLogado !== null)
      this.authService.setUser(this.userLogado);
  }

  public async getPolosAdmin() {
    this.spinnerService.show();
    var response = await lastValueFrom(this.psicologoService.getPolos()).catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });

    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.polos = response.data;
    this.spinnerService.hide();
    if (this.userLogado?.codigoPolo != 0) {
      this.ativaPolo();
    }
    this.spinnerService.hide();
    await this.getPsicologosPolo();
  }

  public ativaPolo() {
    if (this.userLogado?.codigoPolo != undefined)
      this.form.codigoPolo.setValue(this.userLogado?.codigoPolo);

    if (this.userLogado !== null)
      this.authService.setUser(this.userLogado);
  }

  public async cambioPolo() {
    const codigoPO = this.form.codigoPolo.value;
    if (this.userLogado !== null) {
      this.userLogado.codigoPolo = codigoPO == null ? 0 : codigoPO;
      this.authService.setUser(this.userLogado);
    }
    this.form.codigoDiretoria.enable();
    this.form.codigoEscola.enable();
    this.zerarEscola();
    await this.getDiretoriasPorPolo();
  }

  public async getPolosPerfilDiretor() {
    this.spinnerService.show();
    var response = await lastValueFrom(this.psicologoService.getPolos(this.userLogado?.DadosUsuario[0].CodigoDiretoria)).catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });

    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.polos = response.data;
    this.form.codigoPolo.setValue(this.polos[0].codigoPolo);
    this.form.codigoPolo.disable();

    if (this.userLogado?.DadosUsuario[0].CodigoDiretoria != 0) {
      var todasDiretoriasDoUsuario: Diretoria[] = [];
      this.userLogado?.DadosUsuario.forEach(item => {
        var diretoria = new Diretoria();
        diretoria.codigoDiretoria = item.CodigoDiretoria;
        diretoria.nomeDiretoria = item.NomeDiretoria;
        todasDiretoriasDoUsuario.push(diretoria);
      });
      this.diretorias = todasDiretoriasDoUsuario;

      if (this.diretorias.length == 1) {
        this.form.codigoDiretoria.setValue(this.userLogado?.DadosUsuario[0].CodigoDiretoria);
      } else {
        this.form.codigoDiretoria.enable();
      }

      if (this.userLogado?.codigoDiretoria && this.userLogado.codigoDiretoria != 0) {
        this.form.codigoDiretoria.setValue(this.userLogado?.codigoDiretoria);
      }

      this.form.codigoEscola.enable();
      await this.getEscolasPorDiretoria();
      await this.getPsicologosPolo();
    }

    this.spinnerService.hide();
  }

  public async getPolosPerfilProfessor() {
    this.spinnerService.show();
    var response = await lastValueFrom(this.psicologoService.getPolos(this.userLogado?.DadosUsuario[0].CodigoDiretoria)).catch((err) => {
      return this.ConvivaErrors.handleResultError(err);
    });

    if (!response.isSucess) {
      this.toastr.error(response.message);
      this.spinnerService.hide();
      return;
    }
    this.polos = response.data;
    this.form.codigoPolo.setValue(this.polos[0].codigoPolo);
    this.form.codigoPolo.disable();

    var dadosUsuario = this.userLogado?.DadosUsuario[0];
    if (dadosUsuario?.CodigoDiretoria != 0 && dadosUsuario?.CodigoEscola != 0) {
      this.userLogado?.DadosUsuario.forEach(item => {
        var diretoria = new Diretoria();
        diretoria.codigoDiretoria = item.CodigoDiretoria;
        diretoria.nomeDiretoria = item.NomeDiretoria;
        this.diretorias = [diretoria];

        var escola = new Escola();
        escola.codigoEscola = item.CodigoEscola;
        escola.nomeEscola = item.NomeEscola;
        this.escolas = [escola];
      });

      this.form.codigoDiretoria.setValue(this.userLogado?.DadosUsuario[0].CodigoDiretoria);
      this.form.codigoEscola.setValue(this.userLogado?.DadosUsuario[0].CodigoEscola);
    }
    this.spinnerService.hide();
    await this.getPsicologosPolo();
  }

  Voltar() {
    this.router.navigate(['/filtro-visitas-psicologo']);
  }




  public changeInputRange() {
    const inputRange = document.getElementById('input-range') as HTMLFormElement;
    this.duracaoVisita = inputRange.value;
  }

  public async mostrarEnvolvidos() {
    this.OnChangeAtividade();
    var tipoEnvolvido = this.form.codigoAtividade.value;
    this.OnLimparEnvolvidos();
    this.form.tipoGrupo.patchValue(null);
    if (tipoEnvolvido == 1) {
      //await this.getEnvolvidosPorChamado();
      this.mostrarAlunos = true;
      this.mostrarGrupos = false;
      this.mostrarProffisionais = false;
    } else if (tipoEnvolvido == 2) {
      this.mostrarAlunos = false;
      this.mostrarGrupos = true;
      this.mostrarProffisionais = false;
    } else if (tipoEnvolvido == 3) {
      this.mostrarAlunos = false;
      this.mostrarGrupos = false;
      this.mostrarProffisionais = true;
    } else {
      this.mostrarAlunos = false;
      this.mostrarGrupos = false;
      this.mostrarProffisionais = false;
    }
  }
  AbreModalAluno() {
    this.showModalAluno = true;
  }

  public OnLimparEnvolvidos() {
    this.spinnerService.show();
    this.lstOutros = [];
    this.lstFuncionarios = [];
    this.lstFuncionariosSelecionados = [];
    this.lstTurmas = [];
    this.lstTurmasSelecionadas = [];
    // this.alunosSelecionados = [];
    // this.envolvidos = [];
    this.mostrarDivEquipeEscolar = false;
    this.mostrarDivTurmas = false;
    this.mostrarDivOutros = false;
    this.spinnerService.hide();
  }

  public OnChangeAtividade() {
    var atividade = this.form.codigoAtividade.value;
    this.atividadeRealizadaSelecionado = this.lstAtividadeRealizada.find((fun) => fun.id === atividade)?.name;
  }



  public async trocaDiretoria() {
    const codigoDiretoria = this.form.codigoDiretoria.value;
    if (this.userLogado !== null && codigoDiretoria != null) {
      this.userLogado.codigoDiretoria = codigoDiretoria == null ? 0 : codigoDiretoria;
      this.authService.setUser(this.userLogado);
    }
    if (codigoDiretoria != null)
      await this.getEscolasPorDiretoria();
    else
      this.escolas = [];
    this.form.codigoEscola.setValue(null);
  }

  removeFuncionario(codigoFuncionario: number) {
    this.lstFuncionariosSelecionados = this.lstFuncionariosSelecionados.filter(x => x.codigoFuncionario != codigoFuncionario);
  }

  removeOutro(outro: string) {
    this.lstOutros = this.lstOutros.filter(x => x != outro);
  }

  AbreModalProfissionais() {
    this.showModal = true;
  }

  public async onChangeTipoGrupo() {
    var tipoGrupo = this.form.tipoGrupo.value;
    this.OnLimparEnvolvidos();
    // this.submittedModal = true;
    if (tipoGrupo == 1) {
      this.mostrarDivTurmas = true;
      this.caregarTurmas();
      this.mostrarDivEquipeEscolar = false;
      this.mostrarDivOutros = false;
    } else if (tipoGrupo == 2) {
      this.mostrarDivTurmas = false;
      this.mostrarDivEquipeEscolar = true;
      this.mostrarDivOutros = false;
    } else if (tipoGrupo == 3) {
      this.mostrarDivTurmas = false;
      this.mostrarDivEquipeEscolar = false;
      this.mostrarDivOutros = true;
    } else {
      this.mostrarDivTurmas = false;
      this.mostrarDivEquipeEscolar = false;
      this.mostrarDivOutros = false;
    }
  }

  OnClickAcao(e: any, cell: any) {
    // this.router.navigate(['/avaliacao-visita-fiscal', cell._cell.row.data.codigoAtividadePsicologo]);
    // (change)="onCheckTurma(item.codigoTurma, item.descricaoTurma)"

  }

  public async caregarTurmas() {

    this.submitted = true;
    const codigoEs = this.form.codigoEscola.value;

    if (codigoEs != null) {
      this.spinnerService.show();
      const result = await lastValueFrom(this.escolaService.getTurmasPorCodigoCie(codigoEs, 0))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });
      if (!result.isSucess) {
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }
      this.lstTurmas = result.data;
      this.spinnerService.hide();

      this.showGridTurma = true;
      if (this.lstTurmas.length == 0) {
        this.toastr.warning("Não existem Turmas para essa pesquisa");

      }


    }
  }
  public OnExcluirEquipeEscolar(equipeEscolar: Funcionario) {
    this.lstFuncionariosSelecionados = this.lstFuncionariosSelecionados.filter(
      (envolvido) => envolvido.cpf !== equipeEscolar.cpf
    );
  }

  public OnExcluirOutro(outro: string) {
    this.lstOutros = this.lstOutros.filter(x=> x !== outro  );
  }

  public AbrirModalOutros() {
    this.submittedModal = false;
    this.formModalOutros = new FormGroup({
      dsOutros: new FormControl(null, [Validators.required]),
    });
    this.showModalOutros = true;
  }

  public AbrirModalEquipeEscolar() {
    this.submittedModal = false;
    this.formModalEquipeEscolar = new FormGroup({
      cpfFuncionario: new FormControl(null, [
        Validators.required,
        this.genericValidate.ValidaCpf,
      ]),
      cpfFuncionarioModalDrop: new FormControl(null, [Validators.required]),
    });
    this.showModalEquipeEscolar = true;
  }

  OnChangeModalData(data: Date) {
    this.form.dataVisita.setValue(data);
  }

  public async onCheckTurma(codigoTurma: number) {
    const turma = this.lstTurmasSelecionadas.find((turma) => turma.codigoTurma === codigoTurma);
    if (turma) {
      this.lstTurmasSelecionadas = this.lstTurmasSelecionadas
      .filter(x => x.codigoTurma != codigoTurma);
    } else {
      this.lstTurmasSelecionadas.push(this.lstTurmas.find(x => x.codigoTurma == codigoTurma)!);
    }

  }

  public OnAdicionarEquipeEscolar() {
    if (this.formModalEquipeEscolar.invalid) {
      this.toastr.error("Campo de CPF obrigatório");
      return;
    }

    const funcionario  = this.lstFuncionarios.find(x => x.cpf == this.formEquipeEscolar.cpfFuncionarioModalDrop.value);
    if(!funcionario){
      this.toastr.error("Funcionário não encontrado");
      return;
    }

    this.lstFuncionariosSelecionados.push(funcionario);

    this.toastr.success(`Equipo Escolar inserido com sucesso`);
    this.FecharModalEquipeEscolar();

  }

  public FecharModalEquipeEscolar() {
    this.lstFuncionarios = [];
    this.showModalEquipeEscolar = false;
  }

  public FecharModalOutros() {
    this.showModalOutros = false;
  }

  public async GetFuncionariosPorCpf(tela: number) {
    const cpf = tela == 1 ? this.formModalEquipeEscolar.get('cpfFuncionario')?.value : this.formModalControls.cpfBusca.value;
    if (!this.validarCPF(cpf)) {
      this.toastr.warning('CPF invalido.');
      this.spinnerService.hide();
      return;
    }
    this.showDropAuxiliar = false;
    this.showDropFuncionarios = false;
    this.spinnerService.show();
    var getCPF = undefined;
    if (tela == 1) {
      getCPF = this.formModalEquipeEscolar.get('cpfFuncionario');
    }
    else {
      getCPF = this.formModalControls.cpfBusca;
    }
    const result = await lastValueFrom(this.funcionarioService.getFuncionariosPorCpf(getCPF?.value.replace(".", "").replace("-", "")))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
    if (!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    if (result.data != null) {
      this.lstFuncionarios = result.data;
    }
    if (tela == 2) { this.showDropFuncionarios = true; } else { this.showDropAuxiliar = true; }
    if (this.lstFuncionarios.length == 0) {
      this.toastr.warning('Nenhum funcionario encontrado!');
    } else {
      if (this.lstFuncionarios.length == 1) {
        if (tela == 1) {
          this.formModalEquipeEscolar.get('cpfFuncionarioModalDrop')?.patchValue(this.lstFuncionarios[0].cpf);
        } else {
          this.formModalControls.cpfProfissionalSelecionado.patchValue(this.lstFuncionarios[0].cpf);
        }

      }
    }

    this.spinnerService.hide();
  }

  validarCPF(cpf: string): boolean {
    cpf = cpf.replace(/[^\d]/g, '');
    if (cpf.length !== 11) {
      return false;
    }
    if (/^(\d)\1+$/.test(cpf)) {
      return false;
    }
    let sum = 0;
    let remainder;
    for (let i = 1; i <= 9; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }
    if (remainder !== parseInt(cpf.substring(9, 10))) {
      return false;
    }
    sum = 0;
    for (let i = 1; i <= 10; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }
    return remainder === parseInt(cpf.substring(10, 11));
  }

  public OnAdicionarOutros() {
    if(this.formModalOutros.invalid){
      this.toastr.error("Campo de descrição obrigatório");
      return;
    }

    this.lstOutros.push(this.formOutros.dsOutros.value);
    this.toastr.success(`Outros inserido com sucesso`);
    this.FecharModalOutros();
  }



  public FecharModalExclusao() {
    this.showModalExcluirEnvolvido = false;
    this.descricaoExclusao = "";
    this.envolvidoExclusao = undefined;
  }

  // public ExluirEnvolvido(IdTemporario?: number) {
  //   const indiceParaRemover = this.envolvidos.findIndex(
  //     (envolvido) => envolvido.idTemporario === IdTemporario
  //   );
  //   this.envolvidos.splice(indiceParaRemover, 1);
  //   this.toastr.success(
  //     'Envolvido removido com sucesso!'
  //   );
  //   this.FecharModalExclusao();
  // }

  OnAdicionarProfissionaisEOutros() {
    const tipoPessoa = this.formModalControls.tipoPessoa.value;
    const dsOutros = this.formModalControls.dsOutros.value;
    const cpfSelecionado = this.formModalControls.cpfProfissionalSelecionado.value;
    if (tipoPessoa == 1 && dsOutros) {
      this.lstOutros.push(dsOutros);
    } else if (tipoPessoa == 2 && cpfSelecionado) {
      var funcionario = this.lstFuncionarios.find(x => x.cpf == cpfSelecionado);
      if (funcionario) {
        this.lstFuncionariosSelecionados.push(funcionario);
      }
    } else {
      this.toastr.error('Preencha todos os campos.');
    }
    this.lstFuncionarios = [];
    this.FecharModal();
    this.formModalControls.tipoPessoa.setValue(null);
    this.formModalControls.cpfBusca.setValue(null);
    this.formModalControls.dsOutros.setValue(null);
  }

  FecharModal() {
    this.showModal = false;
    this.showDropFuncionarios = false;
    this.formModalControls.cpfBusca.setValue(null);
    this.formModalControls.dsOutros.setValue(null);
  }

  OnAdicionarEnvolvido() {
    if (this.formModalAluno.invalid) {
      this.toastr.warning("Selecione um aluno para salvar na atividade.");
      return;
    }

    var alunoSelecionado = this.alunos.find(x => x.ra == this.modalFormAluno.raAlunoModalDrop.value)!;
    this.alunosSelecionados.push(alunoSelecionado);
    this.showDropAuxiliar = false;
    this.showModalAluno = false;
    this.resetModalEnvolvidos();
  }

  FecharModalAluno() {
    this.showModalAluno = false;
    this.resetModalEnvolvidos();
  }

  resetModalEnvolvidos() {
    this.formModalAluno.reset();

  }
  public async getEscolasModalEnvolvidos() {
    this.spinnerService.show();
    this.zerarEscolaModal();

    const codigoDE = this.modalFormAluno.codigoDiretoriaModal.value;
    if (codigoDE) {
      const result = await lastValueFrom(this.escolaService.getEscolasPorCodigoDiretoria(codigoDE))
        .catch((err) => {
          return this.ConvivaErrors.handleResultError(err);
        });
      if (!result.isSucess) {
        this.toastr.error(result.message, result.title);
        this.spinnerService.hide();
        return;
      }

      this.escolasModalAlunos = result.data;
      this.modalFormAluno.codigoEscolaModal.enable();
      this.spinnerService.hide();
    } else {
      this.escolasModalAlunos = [];
      this.spinnerService.hide();
    }
  }

  zerarEscolaModal() {
    this.escolasModalAlunos = [];
  }
  public BuscarAlunoPorNomeRA() {
    const regexNumeros = /\d$/;
    const regexLetras = /^[a-zA-Z]+$/;
    const regexAmbos = /^[a-zA-Z0-9]+$/;

    this.modalFormAluno.raAlunoModalDrop.patchValue(null);
    this.modalFormAluno.raAlunoModalDrop.removeValidators([Validators.required]);
    this.validaBuscaRA = false;
    let nomeRa = this.modalFormAluno.raNomeAluno.value;
    let buscarPorRa = false;

    if (regexNumeros.test(nomeRa)) {
      this.modalFormAluno.codigoDiretoriaModal.clearAsyncValidators();
      this.modalFormAluno.codigoEscolaModal.clearAsyncValidators();
      buscarPorRa = true;
    }
    else if (regexLetras.test(nomeRa)) {
      this.validaBuscaRA = true;
      this.modalFormAluno.codigoDiretoriaModal.addValidators([Validators.required]);
      this.modalFormAluno.codigoEscolaModal.addValidators([Validators.required]);
    }
    else if (regexAmbos.test(nomeRa)) {
      this.validaBuscaRA = true;
      this.modalFormAluno.raNomeAluno.setValue(nomeRa.replace(/\d/g, ''));
    }
    else {
    }
    this.AtualizarValidacoesAlunos();

    if (this.modalFormAluno.invalid) {
      this.toastr.warning("Deverá ser selecionado o aluno ofendido/ofensor");
      return;
    }
    if (buscarPorRa) {
      this.getAlunosPorRa();
    } else {
      this.getAlunosPorNome();
    }
    this.modalFormAluno.raAlunoModalDrop.addValidators([Validators.required]);
    this.AtualizarValidacoesAlunos();
  }

  public AtualizarValidacoesAlunos() {
    this.modalFormAluno.codigoDiretoriaModal.updateValueAndValidity();
    this.modalFormAluno.codigoEscolaModal.updateValueAndValidity();
    this.modalFormAluno.raNomeAluno.updateValueAndValidity();
    this.modalFormAluno.raAlunoModalDrop.updateValueAndValidity();
  }

  public async getAlunosPorNome() {
    const nmAluno = this.modalFormAluno.raNomeAluno.value;
    const codigoDiretoria = this.modalFormAluno.codigoDiretoriaModal.value;
    const codigoEscola = this.modalFormAluno.codigoEscolaModal.value;
    this.spinnerService.show();
    this.showDropAuxiliar = false;
    const result = await lastValueFrom(this.alunoService.getAlunosPorNome(nmAluno, codigoDiretoria, codigoEscola))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
    if (!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.RetornoBuscaAlunos(result);
    this.spinnerService.hide();
  }

  private RetornoBuscaAlunos(response: DefaultResponse) {
    if (response.data != null) {
      this.alunos = response.data;
    }
    if (this.alunos.length == 0) {
      this.toastr.warning('Nenhum aluno encontrado!');
    }
    if (this.alunos.length == 1) {
      this.formModal.get('raAlunoModalDrop')?.patchValue(this.alunos[0].ra);
    }
    if (this.alunos.length > 1) {
      this.toastr.success('Alunos carregados com sucesso. Por favor selecione um.');
    }
    this.showDropAuxiliar = true;
  }

  public async getAlunosPorRa() {
    this.spinnerService.show();
    this.showDropAuxiliar = false;
    const raAluno = this.formModal.get('raNomeAluno');
    const result = await lastValueFrom(this.alunoService.getAlunosPorRa(raAluno?.value))
      .catch((err) => {
        return this.ConvivaErrors.handleResultError(err);
      });
    if (!result.isSucess) {
      this.toastr.error(result.message, result.title);
      this.spinnerService.hide();
      return;
    }
    this.RetornoBuscaAlunoPorRA(result);
    this.spinnerService.hide();
  }

  private RetornoBuscaAlunoPorRA(response: DefaultResponse) {
    if (response.data != null) {
      this.aluno = response.data;
      this.modalFormAluno.raAlunoModalDrop.patchValue(response.data.ra);
      this.alunos.push(this.aluno);
      this.showDropAuxiliar = true;
      this.toastr.success('Aluno carregado com sucesso.');
      return;
    }
    this.toastr.warning('Nenhum aluno encontrado!');
  }


  async onSubmit() {
    this.spinnerService.show();
    console.log(this.formAgendamento.getRawValue())
    if(this.formAgendamento.invalid){
      this.toastr.error("Preencha todos os campos obrigatórios.");
      this.spinnerService.hide();
      return;
    }

    const dir = this.diretorias.filter(r => r.codigoDiretoria == this.form.codigoDiretoria.value)[0];
    const esc = this.escolas.filter(r => r.codigoEscola == this.form.codigoEscola.value)[0];
    const codigoAtividade = this.form.codigoAtividade.value;




    const objAtendimentoPsicologo: AgendamentoAtividadePrevencao = {
      codigoPolo: this.form.codigoPolo.value,
      codigoDiretoria: this.form.codigoDiretoria.value,
      nomeDiretoria: dir.nomeDiretoria,
      codigoEscola: this.form.codigoEscola.value,
      nomeEscola: esc.nomeEscola,
      codigoPsicologo: this.form.codigoPsicologo.value,
      dataAgendamento: this.form.dataVisita.value,
      timeAtividadePsicologo: this.duracaoVisita.toString(),
      tipoAcaoAtividade: 1, //Prevenção Psicologo
      tipoAtividadeRealizada: codigoAtividade,
      codigoUsuario:this.userLogado!.codigoUsuario,
      codigoPerfil: this.userLogado!.codigoPerfil,
      nomePerfil: this.userLogado!.nomePerfil,
      listaEnvolvidos: this.mappingArrayEnvolvidos()
    };


    var responseCadastro = await lastValueFrom(this.psicologoService
      .setCadastrarAgendamentoAtividadePrevencao(objAtendimentoPsicologo))
      .catch((err) => {
        this.spinnerService.hide();
        return this.ConvivaErrors.handleResultError(err);
      });


    if (!responseCadastro.isSucess) {
      this.toastr.error(responseCadastro.message);
      this.spinnerService.hide();
      return;
    }

    this.toastr.success('Agendamento realizado com sucesso.');
    this.toastr.success('Limpando para o ínicio em 5 segundos.');

    setTimeout(() => {
      window.location.reload();
    }, 5000);
    this.spinnerService.hide();
  }

  private mappingArrayEnvolvidos(): Envolvido[] {
    var envolvidosNaAtividade: Envolvido[] = [];
    // var tipoAtividade: number = this.form.tipoGrupo.value;

    if (this.lstFuncionariosSelecionados.length > 0) {
      const funcionarios = this.lstFuncionariosSelecionados.map((x: Funcionario) => {
        var envolvidoAtv: Envolvido = new Envolvido();
        envolvidoAtv.cpf = x.cpf;
        envolvidoAtv.tipoPessoa = TipoPessoa.EquipeEscolar;
        envolvidoAtv.tipoEnvolvido = 0;
        return envolvidoAtv;
      });
      envolvidosNaAtividade = funcionarios;
    }

    if (this.lstOutros.length > 0) {
      const outros = this.lstOutros.map(x => {
        var envolvidoAtv: Envolvido = new Envolvido();
        envolvidoAtv.dsOutros = x;
        envolvidoAtv.tipoPessoa = TipoPessoa.Outros;
        envolvidoAtv.tipoEnvolvido = 0;
        return envolvidoAtv;
      });
      envolvidosNaAtividade = outros;
    }

    if (this.lstTurmasSelecionadas.length > 0) {
      const turmas = this.lstTurmasSelecionadas.map(turma => {
        var envolvidoAtv: Envolvido = new Envolvido();
        envolvidoAtv.codigoTurma = turma.codigoTurma;
        envolvidoAtv.descricaoTurma = turma.descricaoTurma;
        envolvidoAtv.tipoPessoa = TipoPessoa.Turma;
        envolvidoAtv.numeroClasse = turma.numeroClasse;
        envolvidoAtv.numeroSerie = turma.numeroSerie;
        envolvidoAtv.nomeTipoEnsino = turma.nomeTipoEnsino;
        return envolvidoAtv;
      });
      envolvidosNaAtividade = turmas;
    }


    return envolvidosNaAtividade;
  }

  //  stringToDate(dateString: string): Date {
  //   const [datePart, timePart] = dateString.split(' ');
  //   const [day, month, year] = datePart.split('/').map(Number);
  //   const [hours, minutes] = timePart.split(':').map(Number);

  //   return new Date(year, month - 1, day, hours, minutes);
  // }
}


